import { dateRanges, rangeEnums } from '../shared/utils/dateRanges';
import { sortOrderEnums } from '../shared/utils/sortOrder';
import { get, patch, post } from './httpRequest';

export const fetchVouchers = async ({
  first = 0,
  size = 50,
  sortOrder = sortOrderEnums.DESC,
  searchTerm = '',
  dateRange = rangeEnums.TODAY,
  from,
  to,
}) => {
  const customRange = from &&
    to && {
      label: rangeEnums.CUSTOM,
      from: () => from,
      to: () => to,
    };

  const range = dateRange === rangeEnums.CUSTOM ? customRange : dateRanges[dateRange];
  const params = range
    ? `from=${range.from()}&to=${range.to()}&order=${sortOrder}&first=${first}&size=${size}&terms=${searchTerm}`
    : '';
  const response = await get(`/vouchers?${params}`);
  return response.data;
};

export const fetchVoucher = async (voucherId) => {
  const response = await get(`/vouchers/${voucherId}`);
  return response.data;
};

export const updateVoucher = async (voucherId, { enabled, startDate, endDate }) => {
  const response = await patch(`/vouchers/${voucherId}`, {
    data: { enabled, startDate, endDate },
  });
  return response.data;
};

export const createVoucher = async (payload) => {
  await post(`/vouchers`, {
    data: payload,
  });
};
