import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { clone, pick } from 'lodash';
import * as Yup from 'yup';
import Fuse from 'fuse.js';

import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';

import { useHistory, useParams } from 'react-router';
import { MdDelete } from 'react-icons/md';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import useEndpoint from '../../hooks/useEndpoint';
import {
  clearModifiers,
  createModifier,
  deleteModifier,
  fetchModifiers,
  updateModifier,
} from '../../store/modifiers';
import ModifierForm from '../../components/ModifierForm/index';
import { getModifiersState } from '../../store/modifiers/selectors';

import Page from '../../components/Page';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import withVenue from '../../hoc/withVenue';
import CustomDialog from '../../components/CustomDialog';
import OrderableTable from '../../components/OrderableTable';
import TableButton from '../../components/TableButton';
import useRoles from '../../hooks/useRoles';
import PageHeader from '../../components/PageHeader';
import { getMenuItemsState } from '../../store/menuItems/selectors';
import { fetchMenuItems } from '../../store/menuItems';

const useStyles = makeStyles((theme) => ({
  heading: {
    display: 'flex',
  },
  buttonClass: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: '10px',
  },
  title: {
    flexGrow: 1,
  },
  deleteModifierWarning: {
    ...theme.customFonts.medium,
    color: theme.customPalette.greyDarker,
  },
  typeDelete: {
    ...theme.customFonts.label,
    color: theme.customPalette.greyDarker,
    marginTop: '16px',
  },
  listItemsAssociated: {
    display: 'flex',
    flexDirection: 'column',
  },
  itemsAssociatedLink: {
    ...theme.customFonts.small,
    color: theme.customPalette.trueBlue,
  },
  deleteCopy: {
    ...theme.customFonts.small,
    color: '#D2222D',
  },
  searchInput: {
    marginBottom: '10px',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  readonlyChip: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginTop: theme.spacing(1),
    },
  },
}));

const DeleteModifierValidationSchema = Yup.object().shape({
  delete: Yup.string()
    .required('You must type the word ‘delete’ to delete this modifier.')
    .test('is-delete', 'This doesn’t look right, please check the spelling', (value) => {
      const deleteRegex = /^delete$/i; // The i flag makes the regex case-insensitive
      return deleteRegex.test(value);
    }),
});

const Modifiers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { itemId: modifierParamId } = useParams() || {};
  const { loading, data, error } = useEndpoint(getModifiersState, fetchModifiers()) || {};
  const { data: menuItems } = useEndpoint(getMenuItemsState, fetchMenuItems()) || {};
  const [tableData, setTableData] = useState(data);
  const [searchError, setSearchError] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [modifierData, setModifierData] = useState(null);
  const [formAction, setFormAction] = useState('');
  const [modifierToBeDeleted, setModifierToBeDeleted] = useState(null);
  const [itemsAssociatedToModifer, setItemsAssociatedToModifer] = useState([]);
  const [isDeleteModifierDialogOpen, setIsDeleteModifierDialogOpen] = useState(false);
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { isRoleAtLeastManager } = useRoles();

  const handleCloseDeleteModifierDialog = (resetForm) => {
    setIsDeleteModifierDialogOpen(false);
    resetForm();
  };

  const handleOpenDeleteModifierDialog = (modifier) => {
    setModifierToBeDeleted(modifier);
    setIsDeleteModifierDialogOpen(true);
  };

  const handleOnSubmit = async (values) => {
    const submitValues = clone(values);
    submitValues.modifierItems = submitValues.modifierItems.map((item) => ({
      itemId: item.itemId,
      itemPrice: item.itemPrice,
      multiMax: item.multiMax,
    }));
    if (formAction === 'create') {
      try {
        await dispatch(createModifier(submitValues));
        showSuccessNotification('Modifier has been added successfully');
        setIsDialogOpen(false);
        dispatch(fetchModifiers());
      } catch (e) {
        showErrorNotification(getErrorMessage(e));
        setIsDialogOpen(false);
      }
    }

    if (formAction === 'update') {
      try {
        const { modifierId } = modifierData;
        await dispatch(updateModifier(modifierId, submitValues));
        if (modifierParamId) {
          history.goBack();
          dispatch(fetchModifiers());
          showSuccessNotification('Modifier has been updated successfully');
          return;
        }
        showSuccessNotification('Modifier has been updated successfully');
        setIsDialogOpen(false);
        dispatch(fetchModifiers());
      } catch (newError) {
        showErrorNotification(getErrorMessage(newError));
      }
    }
  };

  const handleEdit = useCallback(
    (modifier) => {
      // When calling this function, a check is first made to see if the modifier is an id, not an object
      if (modifierParamId && data) {
        const modToUpdate = data.find((obj) => obj.modifierId === modifierParamId);
        setFormAction('update');
        setModifierData(modToUpdate);
        setIsDialogOpen(true);
        return;
      }
      setFormAction('update');
      setModifierData(modifier);
      setIsDialogOpen(true);
    },
    [data, modifierParamId],
  );

  // BE does not support deleting modifiers. This will be done in future.

  const handleDelete = async (modId) => {
    try {
      await dispatch(deleteModifier(modId));
      setIsDeleteModifierDialogOpen(false);
      showSuccessNotification('Modifier has been deleted successfully');
    } catch (err) {
      await dispatch(fetchModifiers());
      showErrorNotification(getErrorMessage(err));
    }
  };

  const handleOpenDialog = () => {
    setModifierData(null);
    setFormAction('create');
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    if (modifierParamId) {
      history.goBack();
    }
  };

  const checkMenuItemsModifierIsAppliedTo = useCallback(
    (modifierId) => {
      if (data) {
        const modifiers = menuItems?.filter((item) => item.modifiers?.includes(modifierId));
        if (modifiers) {
          setItemsAssociatedToModifer(modifiers);
          return modifiers;
        }
      }
      return 0;
    },
    [data, menuItems],
  );

  const newData = useCallback(() => {
    const pickedData = [];
    if (tableData) {
      tableData.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item = {
          ...item,
          delete: 'delete',
          appliedToItems: menuItems?.filter((menuItem) =>
            menuItem.modifiers?.includes(item.modifierId) ? menuItem : 0,
          ),
          modifierItems: item.modifierItems?.map((modifierItem) => ({
            ...modifierItem,
            multiMax: modifierItem.multiMax || 1,
          })),
        };

        pickedData.push(
          pick(item, [
            'modifierName',
            'label',
            'modifierItems',
            'appliedToItems',
            'modifierId',
            'maxSelections',
            'minSelections',
            'delete',
            'readonly',
          ]),
        );
      });
    }
    return pickedData;
  }, [tableData, menuItems]);

  const valueFormatter = useCallback(
    ({ value, valueName, row }) => {
      switch (valueName) {
        case 'modifierName':
          return (
            <Box className={classes.labelContainer}>
              {row.readonly ? (
                <>
                  <Typography sx={{ mr: 1 }} variant="subtitle">
                    {value}
                  </Typography>
                  <Chip label="Read Only" size="small" className={classes.readonlyChip} />
                </>
              ) : (
                <TableButton
                  onClick={() => {
                    handleEdit(row);
                  }}
                >
                  {row.modifierName}
                </TableButton>
              )}
            </Box>
          );
        case 'modifierItems':
          return row.modifierItems.length;
        case 'appliedToItems':
          return row.appliedToItems?.length;
        case 'delete':
          return (
            // eslint-disable-next-line no-undef
            <IconButton
              edge="end"
              size="small"
              onClick={() => {
                handleOpenDeleteModifierDialog(row);
                checkMenuItemsModifierIsAppliedTo(row.modifierId);
              }}
              disabled={row?.readonly}
            >
              <MdDelete />
            </IconButton>
          );
        default:
          return value;
      }
    },
    [checkMenuItemsModifierIsAppliedTo, classes.labelContainer, classes.readonlyChip, handleEdit],
  );

  const dialogTitle = useMemo(() => {
    if (formAction === 'update') {
      return 'Update modifier';
    }
    return 'Create new modifier';
  }, [formAction]);

  // To check if param for modifier id is present in URL
  useEffect(() => {
    if (modifierParamId) {
      handleEdit(modifierParamId);
    }
  }, [handleEdit, modifierParamId]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const handleSearch = (e) => {
    if (e.target.value.length >= 3) {
      setSearchError('');
      const searchValue = e.target.value;
      const fuse = new Fuse(data, {
        keys: ['modifierName'],
        threshold: 0.2,
      });
      const searchResult = fuse.search(searchValue);
      const searchResultArr = searchResult.map((mod) => mod.item);
      setTableData(searchResultArr);
    } else if (e.target.value.length === 0) {
      setTableData(data);
      setSearchError('');
    } else {
      setTableData(data);
      setSearchError('Please enter at least 3 characters');
    }
  };

  return (
    <>
      <PageHeader fullWidth>
        <Box className={classes.buttonClass}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenDialog}
            disabled={!isRoleAtLeastManager()}
          >
            New Modifier
          </Button>
        </Box>
        <Box className={classes.heading}>
          <aside>
            <CustomDialog
              isDialogOpen={isDialogOpen}
              handleCloseDialog={handleCloseDialog}
              title={dialogTitle}
            >
              <ModifierForm
                formAction={formAction}
                modifierData={modifierData}
                onSubmit={handleOnSubmit}
                onCancel={handleCloseDialog}
              />
            </CustomDialog>
          </aside>
        </Box>
        <Typography paragraph>
          Modifiers are groups of choices that can be applied to a menu item such as mixers, or a
          choice of salad options
        </Typography>
      </PageHeader>
      <TextField
        className={classes.searchInput}
        label="Search Modifiers"
        variant="outlined"
        size="small"
        helperText={searchError}
        onChange={handleSearch}
      />
      <Page loading={loading} error={error} fullWidth>
        {data && (
          <>
            {data && (
              <Card>
                <>
                  <OrderableTable
                    tableData={[...newData()]}
                    titles={[
                      'INTERNAL NAME',
                      'EXTERNAL LABEL',
                      'OPTION COUNT',
                      'APPLIED TO ITEMS',
                      '',
                    ]}
                    excludeFields={['modifierId', 'minSelections', 'maxSelections', 'readonly']}
                    keys={['modifierId']}
                    disableColumnTitles={['']}
                    valueFormatter={valueFormatter}
                  />
                </>
              </Card>
            )}
          </>
        )}
        <Formik
          initialValues={{ delete: '' }}
          validationSchema={itemsAssociatedToModifer?.length > 0 && DeleteModifierValidationSchema}
          onSubmit={() => handleDelete(modifierToBeDeleted.modifierId)}
        >
          {({ values, setFieldValue, submitForm, resetForm, setFieldError }) => (
            <Form>
              <CustomDialog
                isDialogOpen={isDeleteModifierDialogOpen}
                handleCloseDialog={() => handleCloseDeleteModifierDialog(resetForm)}
                title="Delete this modifier?"
                actions={
                  <>
                    <Button
                      variant="outlined"
                      onClick={() => handleCloseDeleteModifierDialog(resetForm)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      style={{ background: '#D2222D', color: '#fff' }}
                      type="submit"
                      onClick={() => submitForm(values, resetForm)}
                    >
                      Delete modifier
                    </Button>
                  </>
                }
              >
                {itemsAssociatedToModifer?.length > 0 && (
                  <CardContent>
                    <Typography className={classes.deleteModifierWarning}>
                      Permanently deleting &apos;{modifierToBeDeleted?.modifierName}&apos; modifier
                      will remove it from the following items:
                    </Typography>
                    <Box className={classes.listItemsAssociated}>
                      {itemsAssociatedToModifer.map((item) => (
                        <Link
                          className={classes.itemsAssociatedLink}
                          key={item.itemId}
                          to={`/menu-items/${item.itemId}`}
                          target="_blank"
                        >
                          {item.itemName}
                        </Link>
                      ))}
                    </Box>
                    <Typography className={classes.typeDelete}>
                      Type <b>delete</b> to confirm
                    </Typography>
                    <Field
                      component={TextField}
                      size="small"
                      fullWidth
                      name="delete"
                      variant="outlined"
                      required
                      onChange={(e) => {
                        setFieldValue('delete', e.target.value, false);
                        setFieldError('delete', '', false);
                      }}
                    />
                    <ErrorMessage name="delete" component="div" className={classes.deleteCopy} />
                  </CardContent>
                )}
              </CustomDialog>
            </Form>
          )}
        </Formik>
      </Page>
    </>
  );
};

export default withVenue(Modifiers, '/items/modifiers', clearModifiers);
