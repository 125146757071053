import { dateRanges, rangeEnums } from '../shared/utils/dateRanges';
import { sortOrderEnums } from '../shared/utils/sortOrder';
import toUTC from '../shared/utils/toUTC';
import { get } from './httpRequest';

export const fetchTabs = async ({
  first = 0,
  size = 50,
  sortOrder = sortOrderEnums.DESC,
  searchTerm = '',
  dateRange = rangeEnums.TODAY,
  from,
  to,
  reportingType,
}) => {
  const customRange = from &&
    to && {
      label: rangeEnums.CUSTOM,
      from: () => from,
      to: () => to,
    };

  const range = dateRange === rangeEnums.CUSTOM ? customRange : dateRanges[dateRange];
  const params = range
    ? `from=${toUTC(range.from(), reportingType)}&to=${toUTC(
        range.to(),
        reportingType,
      )}&order=${sortOrder}&first=${first}&size=${size}&terms=${searchTerm}`
    : '';
  const response = await get(`/tabs?${params}`);
  return response.data;
};

export const fetchTab = async (tabId) => {
  const response = await get(`/tabs/${tabId}`);
  return response.data;
};
