import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import moment from 'moment-timezone';

import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';
import {
  Button,
  Card,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';
import { getTabState } from '../../store/tabs/selectors';
import { clearTab, fetchTab } from '../../store/tabs';
import TableSkeletonLoader from '../../components/TableSkeletonLoader';
import TableLink from '../../components/TableLink';
import BackArrow from '../../components/BackArrow';

const useStyles = makeStyles((theme) => ({
  heading: {
    margin: '15px 0',
  },
  card: {
    marginBottom: '20px',
  },
  button: {
    marginRight: theme.spacing(),
  },
  refundForm: {
    justifyContent: 'flex-end',
  },
}));

const Tab = ({ redirect }) => {
  const { tabId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, data: tab, error } = useSelector(getTabState);

  const { createdAt, status, tableName, tabName, total, tabOrders, staffName } = tab || {};

  useEffect(() => {
    dispatch(fetchTab(tabId));
  }, [tabId, dispatch]);

  const hasOrders = tabOrders?.length > 0;

  const sortedTabOrders =
    tabOrders && [...tabOrders].sort((a, b) => (a.orderDisplayId > b.orderDisplayId ? 1 : -1));

  const settled = status === 'SETTLED';

  return (
    <>
      <header>
        <BackArrow redirect={redirect} text="Tabs" />
        <Typography className={classes.heading} variant="h2" component="h1">
          Tab: {tabName} (Table {tableName})
        </Typography>
      </header>
      <Page loading={loading} error={error}>
        {tab && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TableContainer component={Card} className={classes.card}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Name:</TableCell>
                      <TableCell align="right">{tabName || 'N/A'}</TableCell>
                    </TableRow>

                    {staffName && (
                      <TableRow>
                        <TableCell>Staff name:</TableCell>
                        <TableCell align="right">{staffName}</TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell>Status:</TableCell>
                      <TableCell align="right">{status}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Total:</TableCell>
                      <TableCell align="right">£{total.toFixed(2).toLocaleString()}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TableContainer component={Card} className={classes.card}>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell>Date:</TableCell>
                      <TableCell align="right">
                        {moment(createdAt).local().format('DD MMM yyyy')}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Time:</TableCell>
                      <TableCell align="right">
                        {moment(createdAt).local().local().format('HH:mm')}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Table:</TableCell>
                      <TableCell align="right">{tableName}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h3">Date</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h3">Time</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h3">Order ID</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h3">Table #</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h3">Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h3">Status</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h3" align="right">
                    Total
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            {loading && <TableSkeletonLoader rows={10} cells={8} />}
            {!loading && hasOrders && (
              <>
                <TableBody>
                  {sortedTabOrders.map((order) => {
                    const {
                      tabOrderId,
                      acceptedAt,
                      orderDisplayId,
                      tableName: orderTableName,
                      name,
                      status: orderStatus,
                      total: orderTotal,
                    } = order;
                    return (
                      <TableRow key={tabOrderId}>
                        <TableCell>
                          <Typography variant="body2">
                            {moment(acceptedAt).local().format('DD/MM')}
                          </Typography>
                        </TableCell>
                        <TableCell>{moment(acceptedAt).local().format('HH:mm')}</TableCell>
                        <TableCell>
                          <TableLink
                            to={{
                              pathname: `/orders/${tabOrderId}`,
                              tabOrder: order,
                            }}
                          >
                            {orderDisplayId}
                          </TableLink>
                        </TableCell>
                        <TableCell>{orderTableName}</TableCell>
                        <TableCell>{name}</TableCell>
                        <TableCell>{orderStatus}</TableCell>
                        <TableCell align="right">£{parseFloat(orderTotal).toFixed(2)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
        {settled && (
          <>
            <br />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="small"
              component={Link}
              to={`/orders/${tabId}`}
            >
              See Settled Order
            </Button>
          </>
        )}
      </Page>
    </>
  );
};

export default withVenue(Tab, '/tabs', clearTab);
