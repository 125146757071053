/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react';
import * as Yup from 'yup';
import { Box, Button, CardContent, Typography, Link, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { TextField } from 'formik-mui';
import UniversalSave from '../../../components/UniversalSave';
import withVenue from '../../../hoc/withVenue';
import { clearVenue, updateVenue } from '../../../store/venues';
import { getErrorMessage } from '../../../shared/utils/errors';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import useRoles from '../../../hooks/useRoles';
import HyperWalletDropIn from '../../../components/HyperWalletDropIn';
import WarningCard from '../../../components/CustomWarningCard';
import BackArrow from '../../../components/BackArrow';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  warningCard: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '6px',
  },
  warningTitle: {
    ...theme.customFonts.smallBold,
    color: theme.customPalette.greyDarker,
  },
  warningText: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
  },
  warningLink: {
    ...theme.customFonts.small,
    color: theme.customPalette.trueBlue,
    textDecoration: 'underline',
  },
  card: {
    borderBottom: '#E0E0EB 1px solid',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& > :nth-child(3)': {
      marginBottom: '10px',
    },
  },
  cardTitle: {
    ...theme.customFonts.mediumBold,
    marginBottom: '16px',
  },
  field: {
    width: '100%',
    margin: 0,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.customPalette.grey,
      borderRadius: '8px',
    },
    '& .MuiSelect-icon': {
      color: theme.customPalette.greyDark,
    },
    borderRadius: '8px',
  },
  title: {
    ...theme.customFonts.largeBold,
  },
  caption: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
    marginBottom: '16px',
  },
  subtitle: {
    ...theme.customFonts.label,
    color: theme.customPalette.greyDarker,
  },
}));

const digitsOnly = (value) => !value || /^\d+$/.test(value);

const paymentDetailsSchema = Yup.object().shape({
  accountNumber: Yup.string()
    .min(8, 'The account number should have at least 8 digits')
    .max(8, 'The account number should have at most 8 digits')
    .test('Digits only', 'The field should have digits only', digitsOnly),
  sortCode: Yup.string()
    .min(6, 'The sort code should have at least 6 digits')
    .max(6, 'The sort code should have at most 6 digits')
    .required('The sort code is required')
    .test('Digits only', 'The field should have digits only', digitsOnly),
});

const PaymentDetails = ({ venue, redirect }) => {
  const classes = useStyles();
  const scrollToForm = useRef(null);
  const dispatch = useDispatch();
  const { isAdmin } = useRoles();
  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const {
    accountNumber = '',
    sortCode = '',
    bankDetails = '',
    hwBankAccountNumber = '',
    hwBankSortCode = '',
    hwStatus,
  } = venue || {};
  const initialValues = {
    accountNumber,
    sortCode,
  };

  const handleOnSubmit = async (values) => {
    try {
      await dispatch(updateVenue(values));
      showSuccessNotification('Successfully updated business details');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: { xs: 1, md: 0.75 } }}>
          <BackArrow redirect={redirect} text="Settings" />
          <Typography className={classes.title}>Payment details</Typography>
          <Typography className={classes.caption}>
            The following details will be used to generate VAT receipts
          </Typography>
          <Paper sx={{ borderRadius: 4 }}>
            {hwStatus === 'REQUIRED' && (
              <WarningCard borderRadius="16px 16px 0 0">
                <div className={classes.warningCard}>
                  <Typography className={classes.warningTitle}>
                    Please verify your payment details so we can pay you
                  </Typography>
                  <Typography className={classes.warningText}>Please complete this form</Typography>
                  <Link
                    className={classes.warningLink}
                    onClick={(event) => {
                      event.preventDefault();
                      scrollToForm.current.scrollIntoView({ behavior: 'smooth' });
                    }}
                  >
                    Payout verification form
                  </Link>
                </div>
              </WarningCard>
            )}
            {process.env.REACT_APP_USE_BANK_DETAILS === 'true' && (
              <CardContent className={classes.card}>
                <Formik
                  initialValues={initialValues}
                  enableReinitialize
                  validationSchema={paymentDetailsSchema}
                >
                  {({ values, resetForm, errors, dirty, isValid }) => (
                    <>
                      <UniversalSave
                        isValid={isValid}
                        dirty={dirty}
                        onSave={() => {
                          handleOnSubmit(values);
                        }}
                        onDiscard={resetForm}
                        errors={errors}
                      />
                      <Form>
                        <CardContent className={classes.container}>
                          <header className={classes.header}>
                            <Typography className={classes.cardTitle}>Bank account</Typography>
                            {isAdmin() && bankDetails === 'OK' && (
                              <Button
                                className={classes.extraButton}
                                variant="contained"
                                color="primary"
                                onClick={async () => {
                                  handleOnSubmit({
                                    ...values,
                                    sortCode: '',
                                    accountNumber: '',
                                  });
                                }}
                              >
                                Reset
                              </Button>
                            )}
                          </header>
                          {bankDetails ? (
                            <>
                              <Typography className={classes.subtitle}>Account number</Typography>
                              <Field
                                className={classes.field}
                                name="hwBankAccountNumber"
                                id="hwBankAccountNumber"
                                value={hwBankAccountNumber}
                                type="text"
                                component={TextField}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                disabled
                              />
                              <Typography className={classes.subtitle}>Sort code</Typography>
                              <Field
                                className={classes.field}
                                name="hwBankSortCode"
                                id="hwBankSortCode"
                                value={hwBankSortCode}
                                type="text"
                                component={TextField}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                disabled
                              />
                            </>
                          ) : (
                            <>
                              <Typography className={classes.subtitle}>Account number</Typography>
                              <Field
                                className={classes.field}
                                name="accountNumber"
                                id="accountNumber"
                                type="text"
                                component={TextField}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                              />
                              <Typography className={classes.subtitle}>Sort code</Typography>
                              <Field
                                className={classes.field}
                                name="sortCode"
                                id="sortCode"
                                type="text"
                                inputProps={{ pattern: '[0-9]*', inputMode: 'numeric' }}
                                component={TextField}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                              />
                            </>
                          )}
                        </CardContent>
                      </Form>
                    </>
                  )}
                </Formik>
              </CardContent>
            )}
            {isAdmin() && (
              <div ref={scrollToForm}>
                <HyperWalletDropIn venue={venue} />
              </div>
            )}
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default withVenue(PaymentDetails, '/settings/business', clearVenue);
