/* eslint-disable max-len */
import {
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  useTheme,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { MdSearch, MdHelp } from 'react-icons/md';
import { rangeEnums, selectRanges } from '../../shared/utils/dateRanges';
import { sortOrders } from '../../shared/utils/sortOrder';
import {
  DISPLAYUNITS,
  DISPLAY_REPORTING_TYPES,
  RANGEUNITS,
  REPORTING_TYPES,
  UNITS,
} from '../../shared/constants/units';
import StatusLozenge from '../StatusLozenge';
import isMidnightFrom from '../../shared/utils/isMidnightFrom';
import isMidnightTo from '../../shared/utils/isMidnightTo';
import toUTC from '../../shared/utils/toUTC';
import fromUTC from '../../shared/utils/fromUTC';

const reportingTypeHelperText = `Transactional (Local Time) filter sales based on the local time that the transaction occurred. This will be affected, for example, by daylight savings.

Operational (5am to 5am) considers the day to end at 5am rather than midnight. This is useful if you do sales after midnight, and want them to be considered as the prior day. For example, a drink sold at 03:01 on Saturday morning will be allocated to Friday in the reporting. Operational reporting uses local time, so is affected by daylight savings.

Transactional (UTC) shows the time of the transactions based on Coordinated Universal Time. This is the same as Greenwich Mean Time, and is not affected by daylight savings or local time.
Your payouts are paid out based on this time.
`;

const useStyles = makeStyles((theme) => ({
  picker: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  accountStatus: {
    marginLeft: theme.spacing(1),
  },
  tip: {
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

const ESGridElement = withStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
    maxWidth: '100%',
  },
}))(Grid);

const ESGridElementChildren = withStyles((theme) => ({
  root: {
    flexBasis: 'auto',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    alignItems: 'flex-start',
  },
}))(ESGridElement);

const ESForm = withStyles(() => ({
  root: {
    width: '100%',
    flexGrow: 2,
  },
}))(Form);

const ESPicker = ({ children, esPicker, left = false }) => {
  const {
    selectedDates,
    displaySelectedDates,
    displaySelectedDateFormats,
    searchTerm,
    dateRange,
    sortOrder,
    units,
    reportingType,
    handleOnSubmit,
    handleRangeChange,
    handleDirectionChange,
    handleDateChangeFrom,
    handleDateChangeTo,
    handleUnitChange,
    handleActive,
    handleDeliveryFilter,
    handleAccountChange,
    handleReportingTypeChange,
    useDate,
    useOrder,
    useSearch,
    useUnits,
    useOptional,
    useReportingType,
    useIsDelivery,
    active,
    isDeliveryActive,
    accountId,
    accounts,
  } = esPicker;

  const classes = useStyles();

  const { fromDisplay, toDisplay } = displaySelectedDateFormats;

  const existingTheme = useTheme();

  const [errorMessage, setErrorMessage] = useState();
  const [textWidth, setTextWidth] = useState(200);

  const checkDisableOperational = (type) => {
    if (type === REPORTING_TYPES.OPERATIONAL && dateRange === rangeEnums.CUSTOM) {
      if (!isMidnightFrom(selectedDates.from)) return true;
      if (!isMidnightTo(selectedDates.to)) return true;
    }
    return false; // default
  };

  useEffect(() => {
    setTextWidth({
      left: `calc(${Math.max(displaySelectedDates.fromDisplay.length + 2, 8)}ch + 2px)`,
      right: `calc(${Math.max(displaySelectedDates.toDisplay.length + 2, 8)}ch + 2px)`,
    });
  }, [displaySelectedDates]);

  useEffect(() => {
    const diff = moment(selectedDates.to) - moment(selectedDates.from);
    setErrorMessage(diff >= 0 ? undefined : 'End date cannot be before start date');
  }, [selectedDates]);

  return (
    <>
      <Formik
        initialValues={{ sortOrder, searchTerm, dateRange, displaySelectedDates, units }}
        onSubmit={handleOnSubmit}
      >
        <ESForm className={classes.picker}>
          {accounts && (
            <Grid container direction="row">
              <ESGridElement item xs={4} sm={4}>
                <Field
                  component={TextField}
                  select
                  name="accountId"
                  value={accountId}
                  label="Account"
                  size="small"
                  variant="outlined"
                  disabled={!active}
                  onChange={handleAccountChange}
                >
                  {accounts.map(({ accountId: laccountId, name, enabled }) => (
                    <MenuItem value={laccountId} key={laccountId}>
                      {name}
                      {enabled ? (
                        ''
                      ) : (
                        <span className={classes.accountStatus}>
                          <StatusLozenge status="inactive" text="Disabled" thin />
                        </span>
                      )}
                    </MenuItem>
                  ))}
                </Field>
              </ESGridElement>
            </Grid>
          )}
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            style={{
              justifyContent: left ? 'flex-start' : 'flex-end',
              columnGap: `${existingTheme.spacing(2)}`,
              rowGap: `${existingTheme.spacing(1)}`,
            }}
          >
            {children && (
              <ESGridElementChildren item xs={12}>
                {children}
              </ESGridElementChildren>
            )}

            {useSearch && (
              <ESGridElement item xs={12} sm={4}>
                <Field
                  fullWidth
                  component={TextField}
                  color="primary"
                  name="searchTerm"
                  label="search"
                  size="small"
                  variant="outlined"
                  disabled={!active}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton type="submit" size="large">
                          <MdSearch />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </ESGridElement>
            )}
            {useOrder && (
              <ESGridElement item xs={12} sm={4}>
                <Field
                  component={TextField}
                  select
                  fullWidth
                  name="sortOrder"
                  label="Sort Order"
                  value={sortOrder}
                  size="small"
                  variant="outlined"
                  disabled={!active}
                  onChange={handleDirectionChange}
                >
                  {sortOrders.map((lsortOrder) => (
                    <MenuItem value={lsortOrder} key={lsortOrder}>
                      {lsortOrder}
                    </MenuItem>
                  ))}
                </Field>
              </ESGridElement>
            )}
            {useDate && (
              <>
                <Field
                  component={TextField}
                  select
                  name="dateRange"
                  value={dateRange}
                  size="small"
                  variant="outlined"
                  disabled={!active}
                  className={classes.dateRange}
                  onChange={handleRangeChange}
                >
                  {selectRanges.map((lrange) => (
                    <MenuItem value={lrange.id} key={lrange.id}>
                      {lrange.label}
                    </MenuItem>
                  ))}
                </Field>
                {useReportingType && (
                  <FormControlLabel
                    control={
                      <Field
                        component={TextField}
                        select
                        name="reportingType"
                        value={reportingType}
                        size="small"
                        variant="outlined"
                        disabled={!active}
                        onChange={handleReportingTypeChange}
                      >
                        {Object.keys(REPORTING_TYPES).map((type) => {
                          const reportingTypeVal = REPORTING_TYPES[type];
                          return (
                            <MenuItem
                              value={reportingTypeVal}
                              key={reportingTypeVal}
                              disabled={checkDisableOperational(reportingTypeVal)}
                            >
                              {DISPLAY_REPORTING_TYPES[reportingTypeVal]}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    }
                    labelPlacement="start"
                    label={
                      <Tooltip
                        title={
                          <span style={{ whiteSpace: 'pre-line' }}>{reportingTypeHelperText}</span>
                        }
                      >
                        <span className={classes.tip}>
                          Reporting <MdHelp color="#555555" />
                        </span>
                      </Tooltip>
                    }
                  />
                )}
                <div style={{ cursor: 'pointer' }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                      timezone="system"
                      format={fromDisplay}
                      maxDate={moment(selectedDates.to)}
                      disabled={!active}
                      value={moment(toUTC(moment(selectedDates.from), reportingType))}
                      onChange={(date) => handleDateChangeFrom(fromUTC(date, reportingType))}
                      ampm={false}
                      InputProps={{
                        readOnly: true,
                        style: { width: textWidth.left },
                      }}
                      sx={{ maxWidth: '200px' }}
                      slotProps={{
                        textField: {
                          size: 'small',
                        },
                      }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                      timezone="system"
                      format={toDisplay}
                      minDate={moment(selectedDates.from)}
                      disabled={!active}
                      value={moment(toUTC(moment(selectedDates.to), reportingType))}
                      onChange={(date) => handleDateChangeTo(fromUTC(date, reportingType))}
                      ampm={false}
                      InputProps={{
                        readOnly: true,
                        style: { width: textWidth.right },
                      }}
                      sx={{ maxWidth: '200px' }}
                      slotProps={{
                        textField: {
                          size: 'small',
                        },
                      }}
                    />
                  </LocalizationProvider>
                  {errorMessage && (
                    <ESGridElement item xs={12} sm={4}>
                      <div style={{ color: existingTheme.palette.error.main }}>{errorMessage}</div>
                    </ESGridElement>
                  )}
                </div>
                {useIsDelivery && (
                  <ESGridElement style={{ textAlign: 'right' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          type="checkbox"
                          size="small"
                          checked={isDeliveryActive}
                          onChange={handleDeliveryFilter}
                        />
                      }
                      label="Delivery reviews"
                    />
                  </ESGridElement>
                )}
              </>
            )}
            {useOptional && (
              <ESGridElement item xs={12} style={{ textAlign: 'right' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      type="checkbox"
                      size="small"
                      checked={active}
                      onChange={handleActive}
                    />
                  }
                  label="Specify dates"
                />
              </ESGridElement>
            )}
            {useUnits && (
              <>
                <Field
                  component={TextField}
                  select
                  name="units"
                  value={units}
                  size="small"
                  variant="outlined"
                  disabled={Object.keys(RANGEUNITS[dateRange]).length <= 1}
                  onChange={handleUnitChange}
                >
                  {Object.keys(RANGEUNITS[dateRange]).map((lunit) => {
                    const unit = UNITS[lunit];
                    return (
                      <MenuItem value={unit} key={unit}>
                        {DISPLAYUNITS[unit]}
                      </MenuItem>
                    );
                  })}
                </Field>
              </>
            )}
          </Grid>
        </ESForm>
      </Formik>
    </>
  );
};

export default ESPicker;
