import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Typography, Button, Grid, Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { fetchMenuItems, clearMenuItems } from '../../store/menuItems';
import {
  clearVenueMenu,
  clearVenueMenus,
  fetchVenueMenu,
  clearVenueMenuStatus,
  fetchVenueMenuStatus,
} from '../../store/venueMenus';
import { getVenueMenuState, getVenueMenuStatusState } from '../../store/venueMenus/selectors';
import {
  clearVenueMenuItems,
  fetchVenueMenuItems,
  setVenueMenuItems,
} from '../../store/venueMenuItems';
import { getVenueMenuItemsState } from '../../store/venueMenuItems/selectors';

import Page from '../../components/Page';
import MenuGroups from '../../components/MenuGroups';
import CreateMenuGroupButton from '../../components/CreateMenuGroupButton';

import { getMenuItemsState } from '../../store/menuItems/selectors';
import withVenue from '../../hoc/withVenue';
import MenuExportImport from '../../components/MenuExportImport';
import BackArrow from '../../components/BackArrow';
import {
  getVenuePrioritiesState,
  getVenuesSettingsState,
  isDeliveryOnly,
} from '../../store/venues/selectors';
import { clearVenuePriorities, fetchVenuePriorities } from '../../store/venues';
import shouldLoad from '../../shared/utils/shouldLoad';
import useRoles from '../../hooks/useRoles';
import { getServiceStatusState } from '../../store/serviceStatus/selectors';
import MenuWarning from './MenuWarning';
import { fetchServiceStatus } from '../../store/serviceStatus';
import PageHeader from '../../components/PageHeader';
import PushOtterMenu from '../../components/PushOtterMenu';
import PublishOtterMenu from '../../components/PublishOtterMenu';
import CustomWarningBanner from '../../components/CustomWarningBanner';

const useStyles = makeStyles(() => ({
  heading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  info: {
    marginRight: 30,
  },
  warning: {
    marginTop: 15,
    marginBottom: 15,
  },
}));

const Menu = ({ redirect }) => {
  const { menuName } = useParams();
  const { isAdmin, isRoleAtLeastManager } = useRoles();
  const typeDeliveryOnly = useSelector(isDeliveryOnly);
  const permissionReadOnly = !isAdmin() && typeDeliveryOnly;
  const classes = useStyles();
  const dispatch = useDispatch();
  const venueMenuState = useSelector(getVenueMenuState);
  const venueMenuStatusState = useSelector(getVenueMenuStatusState);
  const venueMenuItemsState = useSelector(getVenueMenuItemsState);
  const menuItemsState = useSelector(getMenuItemsState);
  const venuePrioritiesState = useSelector(getVenuePrioritiesState);
  const serviceStatusState = useSelector(getServiceStatusState);
  const venueSettingsState = useSelector(getVenuesSettingsState);

  const { data: venueSettings } = venueSettingsState;
  const { loading: venueMenuLoading, data: venueMenu, error: errorVenueMenu } = venueMenuState;
  const { data: menuStatus = {}, loading: venueMenuStatusLoading } = venueMenuStatusState;
  const { data: venueMenuItems, error: errorVenueMenuItems } = venueMenuItemsState;
  const { data: menuItems, error: errorMenuItems } = menuItemsState;
  const { data: serviceStatus, error: errorServiceStatus } = serviceStatusState;
  const {
    loading: prioritiesLoading,
    data: venuePriorities,
    error: prioritiesError,
  } = venuePrioritiesState;
  const { displayMenuName, description, otterStoreId, importUploadUrl, readonly } = venueMenu || {};
  const {
    otterPushStatus,
    otterPublishStatus,
    otterPublishedAt,
    otterPushedAt,
    deliveryPlatforms: venueMenuTargets,
  } = menuStatus || {};
  const isReadonly = readonly === true;
  const [expandAll, setExpandAll] = useState(false);
  const isSessionsBrandsEnabled = !!venueSettings?.find(
    (setting) => setting.settingName === 'SESSIONS_BRAND_ENABLED' && setting.value === true,
  );

  useEffect(() => {
    if (shouldLoad(serviceStatusState)) dispatch(fetchServiceStatus());
    if (shouldLoad(venueMenuState)) dispatch(fetchVenueMenu(menuName));
    if (shouldLoad(venueMenuItemsState)) dispatch(fetchVenueMenuItems(menuName));
    if (shouldLoad(menuItemsState)) dispatch(fetchMenuItems());
    if (shouldLoad(venuePrioritiesState)) dispatch(fetchVenuePriorities());
  }, [
    menuName,
    dispatch,
    venueMenuState,
    venueMenuItemsState,
    menuItemsState,
    venuePrioritiesState,
    serviceStatus,
    serviceStatusState,
    otterPublishedAt,
    readonly,
  ]);

  useEffect(() => {
    if (venueMenu && venueMenu.otterStoreId && shouldLoad(venueMenuStatusState))
      dispatch(fetchVenueMenuStatus(venueMenu));
  }, [dispatch, venueMenuStatusState, venueMenu]);

  useEffect(() => {
    if (venueMenu) {
      dispatch(setVenueMenuItems(venueMenu));
    }
  }, [dispatch, venueMenu]);
  return (
    <>
      <PageHeader fullWidth>
        <div className={classes.heading}>
          <BackArrow redirect={redirect} text="Menus" />
          <Typography variant="h1" component="h1">
            {displayMenuName}
          </Typography>
          {description && <Typography variant="h2">{description}</Typography>}
        </div>
      </PageHeader>
      <Page
        fullWidth
        error={
          errorVenueMenuItems ||
          errorMenuItems ||
          errorVenueMenu ||
          prioritiesError ||
          errorServiceStatus
        }
        loading={venueMenuLoading || prioritiesLoading}
      >
        {venueMenuItems && menuItems && (
          <>
            {serviceStatus && <MenuWarning serviceStatus={serviceStatus} />}
            <Box component="header">
              <Grid container direction="row" justifyContent="flex-end" spacing={4}>
                <Grid item xs={12} md={7} component="aside">
                  <Grid container justifyContent="flex-end" spacing={1}>
                    {isAdmin() && otterStoreId && (
                      <>
                        <Grid item xs={12} md={6}>
                          <PushOtterMenu menuName={menuName} otterStoreId={otterStoreId} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <PublishOtterMenu
                            menuName={menuName}
                            otterStoreId={otterStoreId}
                            venueMenuTargets={menuStatus?.deliveryPlatforms}
                          />
                        </Grid>
                      </>
                    )}
                    {isAdmin() && (
                      <Grid item xs={12} md={6}>
                        <MenuExportImport
                          type="import"
                          menuName={menuName}
                          uploadUrl={importUploadUrl}
                          disabled={!isRoleAtLeastManager() || permissionReadOnly}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                      <MenuExportImport
                        type="export"
                        menuName={menuName}
                        disabled={permissionReadOnly}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CreateMenuGroupButton
                        menuName={menuName}
                        disabled={isReadonly || !isRoleAtLeastManager() || permissionReadOnly}
                        venuePriorities={venuePriorities}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {(otterPushStatus || otterPublishStatus) && !venueMenuStatusLoading && (
                <div className={classes.warning}>
                  <CustomWarningBanner backgroundColor="lightyellow" title="Otter Menu Details">
                    {otterPushedAt && (
                      <Typography variant="body2">
                        Last pushed: {moment(otterPushedAt).format('YYYY-MM-DD HH:mm')}
                      </Typography>
                    )}
                    {otterPublishedAt && (
                      <Typography variant="body2">
                        Last published: {moment(otterPublishedAt).format('YYYY-MM-DD HH:mm')}
                      </Typography>
                    )}
                    {otterPushStatus && (
                      <Typography variant="body2">Push Status: {otterPushStatus}</Typography>
                    )}
                    {otterPublishStatus && (
                      <>
                        <Typography variant="body2">Publish Status: </Typography>
                        {Object.entries(otterPublishStatus).map(([key, { status, message }]) => (
                          <li key={key} style={{ marginLeft: '1em' }}>
                            {key}: {status} {message ? ` (${message})` : ''}
                          </li>
                        ))}
                      </>
                    )}
                    {venueMenuTargets && (
                      <Typography variant="body2">
                        Available Delivery Platforms:{' '}
                        {venueMenuTargets.map(({ displayName }) => displayName).join(', ')}
                      </Typography>
                    )}
                  </CustomWarningBanner>
                </div>
              )}
              <Grid container justifyContent="space-between" alignItems="flex-start">
                <Grid item xs={12}>
                  <Typography paragraph className={classes.info}>
                    Create new sections in your menu, add or remove products from menu sections,
                    rename and reorder sections and items in each section, set up serving times for
                    sections
                  </Typography>
                </Grid>
                <Grid item component="aside" xs={12}>
                  <Box display="flex" justifyContent="flex-end" mb={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<KeyboardArrowDownIcon />}
                      onClick={() => setExpandAll(!expandAll)}
                      to="/menus/add"
                    >
                      {expandAll ? 'Collapse All' : 'Expand All'}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <MenuGroups
              expandAll={expandAll}
              isSessionsBrandsEnabled={isSessionsBrandsEnabled}
              isReadonly={isReadonly || permissionReadOnly}
            />
            {isAdmin() ||
              (!isReadonly && (
                <CreateMenuGroupButton
                  menuName={menuName}
                  disabled={!isRoleAtLeastManager() || permissionReadOnly}
                  venuePriorities={venuePriorities}
                />
              ))}
          </>
        )}
      </Page>
    </>
  );
};

export default withVenue(Menu, '/menus', [
  clearVenueMenuItems,
  clearVenuePriorities,
  clearVenueMenus,
  clearVenueMenuStatus,
  clearVenueMenu,
  clearMenuItems,
]);
