import { useCallback } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useDispatch, useSelector } from 'react-redux';
import { setVenue } from '../store/venues';
import { getVenuesState } from '../store/venues/selectors';

const useSwitchVenue = () => {
  const dispatch = useDispatch();
  const { data: venues } = useSelector(getVenuesState);
  const { shutdown } = useIntercom();

  const switchVenue = useCallback(
    async (venueId) => {
      const newVenue = venues?.find((ven) => ven.venueId === venueId);
      sessionStorage.removeItem('menuItemsFilter');
      if (newVenue) {
        shutdown();
        await dispatch(setVenue(newVenue));
      }
    },
    [dispatch, shutdown, venues],
  );

  return switchVenue;
};

export default useSwitchVenue;
