import React from 'react';

import { useParams } from 'react-router';
import { Typography } from '@mui/material';
import withVenue from '../../../hoc/withVenue';
import TabContainer from '../../../components/TabContainer';
import PageHeader from '../../../components/PageHeader';
import BrandsMenuItems from '../BrandsMenuItems';
import BrandsCategories from '../BrandsCategories';
import BrandIngredients from '../BrandsIngredients';
import BrandsModifiers from '../BrandsModifiers';
import BrandsOptions from '../BrandsOptions';
import { clearBrandsMenuItem, clearBrandsMenuItems } from '../../../store/brands/brandsMenuItems';
import { clearBrandsModifiers } from '../../../store/brands/brandsModifiers';
import { clearBrandsOptions } from '../../../store/brands/brandsOptions';

const BrandItems = () => {
  const { tabname } = useParams();

  return (
    <PageHeader fullWidth>
      <Typography style={{ flexGrow: 1 }} variant="h2" component="h1">
        Brand Items
      </Typography>
      <TabContainer
        titles={['Menu items', 'Ingredients', 'Modifiers', 'Sizes', 'Reporting categories']}
        defaultTab={tabname}
        path="/brand-menu-management/items/"
      >
        <BrandsMenuItems />
        <BrandIngredients />
        <BrandsModifiers />
        <BrandsOptions />
        <BrandsCategories />
      </TabContainer>
    </PageHeader>
  );
};

export default withVenue(BrandItems, null, [
  clearBrandsMenuItems,
  clearBrandsMenuItem,
  clearBrandsModifiers,
  clearBrandsOptions,
]);
