import { getBare, patchBare, deleteBare, get, patch, post, del } from './httpRequest';

export const fetchSettings = async () => {
  const response = await getBare(`settings`);
  return response.data;
};

export const updateSettings = async ({ values }) => {
  await patchBare(`settings`, {
    data: values,
  });
};

export const forcePasswordReset = async ({ values }) => {
  await post(`/emails`, {
    data: values,
  });
};

export const deleteVenue = async (venueId) => {
  await deleteBare(`venues/${venueId}`);
};

export const patchVenue = async (venueId, isPaidupEnabled) => {
  await patchBare(`venues/${venueId}`, {
    data: {
      isPaidupEnabled,
    },
  });
};

export const fetchPaymentFees = async () => {
  await get(`/paymentFees`);
};

export const updatePaymentFees = async ({ values }) => {
  await patch(`/paymentFees`, {
    data: values,
  });
};

export const deleteUser = async (email) => {
  await del(`/users`, {
    data: {
      email,
    },
  });
};
