import { dateRanges, rangeEnums } from '../shared/utils/dateRanges';
import { get, patch, postBare } from './httpRequest';
import { getIdToken } from '../cognito/sessionTokens';
import { sortOrderEnums } from '../shared/utils/sortOrder';
import toUTC from '../shared/utils/toUTC';

export const fetchOrders = async ({
  first = 0,
  size = 50,
  sortOrder = sortOrderEnums.DESC,
  searchTerm = '',
  dateRange = rangeEnums.TODAY,
  accountId = '',
  from,
  to,
  includeStatus = '',
  excludeStatus = '',
  reportingType = '',
}) => {
  const customRange = from &&
    to && {
      label: rangeEnums.CUSTOM,
      from: () => from,
      to: () => to,
    };

  const range = dateRange === rangeEnums.CUSTOM ? customRange : dateRanges[dateRange];
  const params = range
    ? `from=${toUTC(range.from(), reportingType)}&to=${toUTC(range.to(), reportingType)}
    &order=${sortOrder}&first=${first}
    &size=${size}&terms=${searchTerm}
    &includeStatus=${includeStatus}&excludeStatus=${excludeStatus}&accountId=${accountId}`
    : '';
  const response = await get(`/orders?${params}`);
  return response.data;
};

export const fetchLiveOrders = async () => {
  const response = await get(`/orders/live`);
  return response.data;
};

export const fetchTakeawayOrders = async ({ from, to, dateRange, reportingType = '' }) => {
  const customRange = from &&
    to && {
      label: rangeEnums.CUSTOM,
      from: () => from,
      to: () => to,
    };

  const range = dateRange === rangeEnums.CUSTOM ? customRange : dateRanges[dateRange];
  const params = dateRange
    ? `from=${toUTC(range.from(), reportingType)}&to=${toUTC(range.to(), reportingType)}`
    : '';
  const response = await get(`/orders/takeaway?${params}`);
  return response.data;
};

export const fetchOrder = async (orderId) => {
  const response = await get(`/orders/${orderId}`);
  return response.data;
};

export const fetchOrderReceipt = async (orderId) => {
  const sessionVenueId = localStorage.getItem('venue_id');
  const idToken = getIdToken();
  const url = `${process.env.REACT_APP_API_URI}/venues/${sessionVenueId}/orders/${orderId}/receipt`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: idToken,
      Accept: 'application/pdf',
    },
  });
  const blob = await response.blob();
  return blob;
};

export const acceptOrder = async (orderId) => {
  const response = await patch(`/orders/${orderId}/accept`);
  return response.data;
};

export const refundOrder = async (orderId, refundAmount) => {
  const response = refundAmount
    ? await patch(`/orders/${orderId}/refund`, { data: { refundAmount } })
    : await patch(`/orders/${orderId}/refund`);
  return response.data;
};

export const cancelOrder = async (orderId) => {
  const response = await patch(`/orders/${orderId}/cancel`);
  return response.data;
};

export const acceptLiveOrders = async () => {
  await patch('/accept-live-orders');
};

export const sendPolarisOrders = async (data) => {
  await postBare('orders/polaris-orders', { data });
};

export const sendOrderEmail = async ({ orderId, email }) => {
  const response = await patch(`/orders/${orderId}/receipt`, { data: { email } });

  return response.data;
};
