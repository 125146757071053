import { sortOrderEnums } from '../shared/utils/sortOrder';
import { get, patch } from './httpRequest';

const fetchCardPayouts = async ({ from = 0, size = 50, gte, lte, order = sortOrderEnums.DESC }) => {
  const dateFrom = gte || new Date('12 April 2021').toISOString();
  const dateTo = lte || new Date().toISOString(); // Start of Payouts integration
  const params = `gte=${dateFrom}&lte=${dateTo}&order=${order}&from=${from}&size=${size}`;
  const response = await get(`/combinedPayouts?${params}`);

  return response.data;
};

const retryPayout = async ({ day }) => {
  await patch(`/payouts/${day}`);
};

export { fetchCardPayouts, retryPayout };
