import { get, patch } from './httpRequest';

export const fetchBilling = async () => {
  const response = await get('/billing');
  return response.data;
};

export const fetchDirectDebitMandateUrl = async () => {
  const response = await get('/direct-debit-url');
  return response.data;
};

export const fetchInvoices = async (values) => {
  const { to, from } = values;
  if (to && from) {
    const response = await get(`/invoices/?to=${to}&from=${from}`);
    return response.data;
  }
  const response = await get('/invoices');
  return response.data;
};

export const updateSubscription = async (values) => {
  const payload = {
    data: {
      fixedCosts: values.fixedCosts,
      hardware: values.hardware,
      discount: values.discount,
      type: values.type,
      active: values.active,
      variableCosts: values.variableCosts.map(
        ({ name, label, unitPrice, discountedPrice, enabled }) => ({
          name,
          label,
          unitPrice,
          discountedPrice,
          enabled,
        }),
      ),
    },
  };
  await patch('/subscription', payload);
};
