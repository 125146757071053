import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import * as Yup from 'yup';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { Switch, TextField } from 'formik-mui';
import { useDispatch } from 'react-redux';
import CreateHeader from './img/createHeader.png';
import PageHeader from '../../components/PageHeader';
import BackArrow from '../../components/BackArrow';
import WithVenue from '../../hoc/withVenue';
import AutoComplete from '../../components/AutoComplete';
import useEndpoint from '../../hooks/useEndpoint';
import { getMenuItemsState } from '../../store/menuItems/selectors';
import { clearMenuItems, fetchMenuItems } from '../../store/menuItems';
import Page from '../../components/Page';
import UniversalSave from '../../components/UniversalSave';
import {
  clearSmartUpsellGroup,
  createUpsellGroup,
  fetchUpsellGroups,
} from '../../store/upsellGroups';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import AddGroupItems from '../../components/AddGroupItems';
import useItemsOnMenu from '../../hooks/useItemsOnMenu';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '8px',
  },
  pageHeader: {
    marginBottom: '24px',
    ...theme.customFonts.largeBold,
  },
  cardHeader: {
    ...theme.customFonts.smallBold,
    color: theme.customPalette.greyDarker,
  },
  subtitle: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
  },
  cardSectionSeperator: {
    borderTop: `1px solid ${theme.customPalette.greyLight}`,
    paddingTop: '1rem',
    marginLeft: '-1rem',
    marginRight: '-1rem',
    paddingLeft: '1rem',
  },
  autoCompleteTitle: {
    marginTop: '15px',
    ...theme.customFonts.label,
  },
  upsellAddItemTitle: {
    ...theme.customFonts.smallBold,
    marginTop: '34px',
    color: theme.customPalette.greyDarker,
  },
  initiatingItemsList: {
    background: theme.customPalette.greyLightest,
    display: 'flex',
    flexDirection: 'row',
    padding: '8px',
    flexWrap: 'wrap',
    borderRadius: '8px',
  },
  initiatingItemsListText: {
    ...theme.customFonts.medium,
  },
  addItemButton: {
    ...theme.customFonts.medium,
    borderRadius: '8px',
    marginTop: '15px',
    marginBottom: '34px',
  },
  searchFieldTitle: {
    ...theme.customFonts.label,
    marginTop: '15px',
  },
  smallTitle: {
    ...theme.customFonts.small,
    marginTop: '15px',
  },
  upsellEnabled: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  upsellEnabledTitle: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
  },
  dialogButton: {
    borderRadius: '8px',
  },
  media: {
    height: '149px',
    backgroundSize: 'initial',
    backgroundColor: theme.customPalette.midnight,
    backgroundPosition: '50% -35%',
  },
}));

const NewUpsellGroupSchema = Yup.object().shape({
  name: Yup.string().min(1).required('Upsell name is required'),
  enabled: Yup.boolean().required(),
  suggestedItemIds: Yup.array().of(Yup.string().min(1)).required(),
  initiatingItems: Yup.array().of(Yup.string()).optional(),
});

const FormObserver = ({ setFieldValue, setFormikValues }) => {
  const { values, dirty } = useFormikContext();
  useEffect(() => {
    setFormikValues(values);
  }, [values, dirty, setFieldValue, setFormikValues]);
};

const AddCustomUpsellGroupForm = ({ redirect }) => {
  const classes = useStyles();
  const { data: menuItems } = useEndpoint(getMenuItemsState, fetchMenuItems()) || {};
  const [formikValues, setFormikValues] = useState();
  const [checkedItems, setCheckedItems] = useState([]);
  const { checkIfItemIsOnMenu } = useItemsOnMenu();
  const sortedMenuItems = _.sortBy(menuItems, 'itemName');
  const sanitisedAvaialbleSuggestionMenuItems = sortedMenuItems?.filter(
    (item) =>
      !formikValues ||
      (!formikValues.initiatingItems?.includes(item.itemId) &&
        !formikValues.suggestedItemIds?.includes(item.itemId)),
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState();
  const sanitisedItems = menuItems?.filter(
    (item) => !formikValues?.suggestedItemIds.includes(item.itemId),
  );

  // This is so the checkboxes remain checked when creating initiating items in a new upsell group
  const updatedMenuItems = sanitisedItems?.map((item) => {
    // check if the id of item is the same as an initiating item in formik values
    const isInitiatingItem = formikValues?.initiatingItems.includes(item.itemId);
    // add the initiatingItem property to the item if it is an initiating item
    return isInitiatingItem ? { ...item, upsellGroupId: 'create' } : item;
  });

  const dispatch = useDispatch();
  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const initialValues = {
    name: '',
    enabled: true,
    suggestedItemIds: ['', '', ''],
    initiatingItems: [],
  };

  const handleEdit = () => {
    setIsDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSubmit = async (values) => {
    try {
      const suggestedItemIds = values.suggestedItemIds.filter((id) => id !== '');
      const initiatingItemIds = selectedItems?.map((item) => item.itemId);
      const transformedValues = {
        ...values,
        suggestedItemIds,
        initiatingItems: initiatingItemIds || [],
      };
      const updatedValues = { type: 'CUSTOM_UPSELLS', ...transformedValues };
      await dispatch(createUpsellGroup(updatedValues));
      await dispatch(fetchUpsellGroups());
      await dispatch(fetchMenuItems());
      showSuccessNotification('Upsell group created successfully');
      redirect();
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const handleOnSave = (values) => {
    const transformedValues = values.filter((value) => value.checked === true);
    setSelectedItems(transformedValues);
    const ids = values.filter((value) => value.checked === true).map((value) => value.itemId);
    setCheckedItems(ids);
    setIsDialogOpen(false);
  };

  return (
    <>
      <PageHeader>
        <BackArrow text="Item upsells" redirect={redirect} />
        <Typography className={classes.pageHeader}>Create upsell</Typography>
      </PageHeader>
      <Page>
        <Card>
          <CardMedia className={classes.media} image={CreateHeader} title="Contemplative Reptile" />
          <Formik initialValues={initialValues} validationSchema={NewUpsellGroupSchema}>
            {({ values, dirty, isValid, errors, resetForm, setFieldValue, setFieldTouched }) => (
              <>
                <UniversalSave
                  isValid={isValid}
                  dirty={dirty}
                  onSave={() => {
                    handleSubmit(values);
                  }}
                  onDiscard={() => {
                    resetForm();
                    setSelectedItems([]);
                    setCheckedItems([]);
                  }}
                  errors={errors}
                />
                <Form>
                  <FormObserver setFieldValue={setFieldValue} setFormikValues={setFormikValues} />
                  <CardContent>
                    <header className={classes.cardHeader}>
                      1. Choose up to 3 items to upsell
                    </header>
                    <div className={classes.subtitle}>
                      These items are suggested after the customer adds something to the basket
                    </div>
                    <Box>
                      <Typography className={classes.autoCompleteTitle}>
                        Select an item to upsell
                      </Typography>
                      <AutoComplete
                        addUnknownOff
                        label=""
                        options={sanitisedAvaialbleSuggestionMenuItems}
                        value={
                          sortedMenuItems.find(
                            ({ itemId }) => itemId === values.suggestedItemIds[0],
                          ) || []
                        }
                        optionLabelKey="itemName"
                        optionValueKey="itemId"
                        checkIfItemIsOnMenu={checkIfItemIsOnMenu}
                        onSelect={(item) => {
                          setFieldValue('suggestedItemIds[0]', item);
                          setFieldTouched('suggestedItemsIds[0]');
                        }}
                        onInputChange={(event, value, reason) => {
                          if (reason === 'clear') {
                            setFieldValue('suggestedItemIds[0]', '');
                          }
                        }}
                      />
                      <Typography className={classes.autoCompleteTitle}>
                        Select an item to upsell
                      </Typography>
                      <AutoComplete
                        addUnknownOff
                        checkIfItemIsOnMenu={checkIfItemIsOnMenu}
                        label=""
                        options={sanitisedAvaialbleSuggestionMenuItems}
                        value={
                          sortedMenuItems.find(
                            ({ itemId }) => itemId === values.suggestedItemIds[1],
                          ) || []
                        }
                        optionLabelKey="itemName"
                        optionValueKey="itemId"
                        onSelect={(item) => {
                          setFieldValue('suggestedItemIds[1]', item);
                          setFieldTouched('suggestedItemsIds[1]');
                        }}
                        onInputChange={(event, value, reason) => {
                          if (reason === 'clear') {
                            setFieldValue('suggestedItemIds[1]', '');
                          }
                        }}
                      />
                      <Typography className={classes.autoCompleteTitle}>
                        Select an item to upsell
                      </Typography>
                      <AutoComplete
                        checkIfItemIsOnMenu={checkIfItemIsOnMenu}
                        addUnknownOff
                        label=""
                        options={sanitisedAvaialbleSuggestionMenuItems}
                        value={
                          sortedMenuItems.find(
                            ({ itemId }) => itemId === values.suggestedItemIds[2],
                          ) || []
                        }
                        optionLabelKey="itemName"
                        optionValueKey="itemId"
                        onSelect={(item) => {
                          setFieldValue('suggestedItemIds[2]', item);
                          setFieldTouched('suggestedItemsIds[2]');
                        }}
                        onInputChange={(event, value, reason) => {
                          if (reason === 'clear') {
                            setFieldValue('suggestedItemIds[2]', '');
                          }
                        }}
                      />
                    </Box>
                    <Typography
                      className={`${classes.upsellAddItemTitle} ${classes.cardSectionSeperator}`}
                    >
                      2. Select items that will use this upsell
                    </Typography>

                    <div className={classes.subtitle}>
                      <p>E.g. When beer is added to the basket, it will trigger this upsell.</p>
                    </div>
                    {selectedItems && selectedItems.length > 0 && (
                      <Box className={classes.initiatingItemsList}>
                        {selectedItems.map((item, index) => (
                          <Typography className={classes.initiatingItemsListText} key={item.itemId}>
                            {item.itemName}
                            {index !== selectedItems.length - 1 ? ',\u00A0' : ''}
                          </Typography>
                        ))}
                      </Box>
                    )}
                    <Button
                      className={classes.addItemButton}
                      variant="outlined"
                      onClick={handleEdit}
                    >
                      {selectedItems?.length > 0 ? 'Edit items' : 'Add items'}
                    </Button>
                    {menuItems && (
                      <AddGroupItems
                        menuItems={updatedMenuItems}
                        isDialogOpen={isDialogOpen}
                        handleCloseDialog={handleCloseDialog}
                        handleOnSave={handleOnSave}
                        checkedItems={checkedItems}
                      />
                    )}
                    <Typography className={`${classes.cardHeader} ${classes.cardSectionSeperator}`}>
                      3. Setup
                    </Typography>
                    <Typography className={classes.searchFieldTitle}>
                      Name your upsell (internal)*
                    </Typography>
                    <Field
                      component={TextField}
                      name="name"
                      variant="outlined"
                      fullWidth
                      required
                      margin="none"
                    />
                    <Typography className={classes.smallTitle}>Status of upsell</Typography>
                    <Box className={classes.upsellEnabled}>
                      <Field name="enabled" component={Switch} color="primary" type="checkbox" />
                      <Typography className={classes.upsellEnabledTitle}>
                        {values.enabled ? 'Active' : 'Disabled'}
                      </Typography>
                    </Box>
                  </CardContent>
                </Form>
              </>
            )}
          </Formik>
        </Card>
      </Page>
    </>
  );
};

export default WithVenue(AddCustomUpsellGroupForm, '/upsells/item-upsells', [
  clearMenuItems,
  clearSmartUpsellGroup,
]);
