import React, { useState } from 'react';
import { Box, Paper, CardContent, Typography, Button, List, ListItem, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ConnectComponentsProvider } from '@stripe/react-connect-js';
import { loadConnectAndInitialize } from '@stripe/connect-js';

import withVenue from '../../../hoc/withVenue';
import { clearVenue } from '../../../store/venues';
import BackArrow from '../../../components/BackArrow';
import CustomWarningBanner from '../../../components/CustomWarningBanner';
import { createStripeConnectAccount } from '../../../api/stripe';
import StepAccordion from '../../Signup/StepAccordion';
import PageLoader from '../../../components/PageLoader';
import { ReactComponent as TickCircleIcon } from '../../../assets/images/tick-circle-solid.svg';
import { ReactComponent as ExternalLinkIconWhite } from '../../../assets/images/external-link-white.svg';
import { ReactComponent as ExternalLinkIconGrey } from '../../../assets/images/external-link-grey.svg';
import { ReactComponent as ExternalLinkIcon } from '../../../assets/images/external-link.svg';
import { ReactComponent as InfoIcon } from '../../../assets/images/info.svg';
import { ReactComponent as WarningIcon } from '../../../assets/images/warning-icon.svg';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import useRoles from '../../../hooks/useRoles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& > :nth-child(3)': {
      marginBottom: '10px',
    },
  },
  title: {
    ...theme.customFonts.largeBold,
    marginBottom: '16px',
  },
  text: {
    padding: '16px 16px 0 16px',
  },
  list: {
    padding: '0',
    listStyleType: 'disc',
    paddingLeft: '48px',
  },
  listItem: {
    padding: '4px',
    display: 'list-item',
  },
  button: {
    cursor: 'pointer',
    margin: '12px',
    marginTop: '8px',
  },
  externalLink: {
    marginLeft: '8px',
  },
  accordianText: {
    backgroundColor: '#F2F2F7',
    borderRadius: '8px',
    padding: '8px',
  },
  bankDetailsTextWrapper: {
    display: 'flex',
    paddingTop: '16px',
  },
  bankDetailsText: {
    fontWeight: 'bold',
    marginLeft: '4px',
  },
  bottomCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: '#F2F2F7',
    borderRadius: '0 0 16px 16px',
    alignItems: 'center',
  },
}));

const StripePaymentDetails = ({ accountDetails, redirect }) => {
  const { isAdmin } = useRoles();
  const classes = useStyles();
  const [disabled, setDisabled] = useState(false);

  const {
    hasAcceptedTOS = false,
    hasFailedPaymentMethod = false,
    hasOutstandingRequirements,
    dueDate,
    kycLink = '',
    // payoutsEnabled = false,
    status = '',
    stripeClientSecret,
    stripeAccountDashboardLink,
  } = accountDetails || {};
  const { accountNumber = '', sortCode = '' } = accountDetails?.bankAccount || {};
  const { created = 0 } = accountDetails?.account?.created || {};

  const createdDate = new Date(created * 1000);
  const date7DaysLater = new Date(createdDate.getTime() + 7 * 24 * 60 * 60 * 1000);
  const currentDate = new Date();

  const { showErrorNotification } = useNotifications();

  const handleRegisterWithStripe = async () => {
    try {
      if (!hasAcceptedTOS) await createStripeConnectAccount();
    } catch (err) {
      showErrorNotification(err);
    }
  };

  const [stripeConnectInstance] = useState(() =>
    loadConnectAndInitialize({
      publishableKey: process.env.REACT_APP_STRIPE_PK,
      fetchClientSecret: () => stripeClientSecret,
    }),
  );

  const info = hasAcceptedTOS
    ? 'Click the button that says "Update your details" and make sure all your infos are up to date.'
    : 'Click the button that says "Register with Stripe" and answer all the Stripe registration questions.';
  let stripeStatusTitle = 'Register your business & bank details with our Stripe account.';
  let stripeCallToActionText = 'Register with Stripe';
  let bannerColour = '#FFE87A';

  if (!!hasAcceptedTOS && hasOutstandingRequirements) {
    stripeStatusTitle =
      'We need some more information from you to make sure you can continue to receive payouts.';
    stripeCallToActionText = 'Update your details';
    bannerColour = '#FFE87A';
  }

  if (!!hasAcceptedTOS && hasOutstandingRequirements) {
    stripeStatusTitle =
      'We need some more information from you to make sure you can continue to receive payouts.';
    stripeCallToActionText = 'Update your details';
    bannerColour = '#ff6e6e';
  }

  if (!!hasAcceptedTOS && hasOutstandingRequirements && dueDate) {
    stripeStatusTitle = `We need some more information from you by the
      ${dueDate} to make sure you can continue to receive payouts.`;
    stripeCallToActionText = 'Update your details ASAP';
    bannerColour = '#ff6e6e';
  }

  const getStripeStatus = () => {
    switch (true) {
      case status === 'restricted' || hasFailedPaymentMethod === true || hasOutstandingRequirements:
        return (
          <>
            <Box>
              <CustomWarningBanner
                title={stripeStatusTitle}
                titleFont="smallBold"
                titleColor="#000000"
                backgroundColor={bannerColour}
                iconColor={bannerColour}
                borderRadius="16px 16px 0 0"
                customIcon={<WarningIcon />}
              />
              <CardContent className={classes.container}>
                <Box className={classes.bankDetailsTextWrapper}>
                  <Typography>Account number:</Typography>
                  <Typography className={classes.bankDetailsText}>{accountNumber}</Typography>
                </Box>
                <Box className={classes.bankDetailsTextWrapper}>
                  <Typography>Sort code: </Typography>
                  <Typography className={classes.bankDetailsText}>{sortCode}</Typography>
                </Box>
              </CardContent>
            </Box>
            {!hasAcceptedTOS && (
              <Box>
                <Typography className={classes.text}>To do this, you&apos;ll need:</Typography>
                <List className={classes.list}>
                  <ListItem className={classes.listItem}>
                    A form of ID e.g. driving license, passport
                  </ListItem>
                  <ListItem className={classes.listItem}>Up to date bank info</ListItem>
                  <ListItem className={classes.listItem}>5-10 minutes</ListItem>
                </List>
              </Box>
            )}
            <Box>
              <Typography className={classes.text}>
                By registering to your account, you agree to our Services Agreement and the{' '}
                <Link
                  href="https://stripe.com/gb/legal/connect-account"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stripe Connected Account Agreement
                </Link>
              </Typography>
            </Box>
            <Box sx={{ borderBottom: '#E0E0EB 1px solid' }}>
              <Button
                href={kycLink}
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleRegisterWithStripe}
                target="_blank"
                rel="noopener noreferrer"
              >
                {stripeCallToActionText} <ExternalLinkIconWhite className={classes.externalLink} />
              </Button>
            </Box>
            <Box>
              <StepAccordion
                title="Why are we using Stripe?"
                description={
                  <Box className={classes.accordianText}>
                    <List className={classes.list}>
                      <ListItem className={classes.listItem}>
                        Fewer or no outages, means all your payouts happen on time
                      </ListItem>
                      <ListItem className={classes.listItem}>
                        Payouts now happen much faster
                      </ListItem>
                      <ListItem className={classes.listItem}>
                        Greater flexibility means we can develop more features for your benefit in
                        the near future
                      </ListItem>
                    </List>
                  </Box>
                }
                fontWeight="normal"
                fontColor="#4747E2"
              />
              <StepAccordion
                title="What do I have to do?"
                description={<Box className={classes.accordianText}>{info}</Box>}
                fontWeight="normal"
                fontColor="#4747E2"
              />
            </Box>
          </>
        );
      case status === 'pending':
        return (
          <CustomWarningBanner
            title="Stripe is processing your new details, we’ll email you when it's completed."
            titleFont="smallBold"
            titleColor="#000000"
            backgroundColor="#E0E0EB"
            iconColor="#E0E0EB"
            borderRadius="8px"
            customIcon={<InfoIcon />}
          />
        );
      default:
        return (
          <>
            <CardContent className={classes.container}>
              {date7DaysLater < currentDate && (
                <Box sx={{ pb: 2 }}>
                  <CustomWarningBanner
                    title="Your bank details are registered with Stripe"
                    titleFont="smallBold"
                    titleColor="#000000"
                    backgroundColor="#87EB80"
                    iconColor="#87EB80"
                    borderRadius="8px"
                    customIcon={<TickCircleIcon />}
                  />
                </Box>
              )}
              <Box className={classes.bankDetailsTextWrapper}>
                <Typography>Account number:</Typography>
                <Typography className={classes.bankDetailsText}>{accountNumber}</Typography>
              </Box>
              <Box className={classes.bankDetailsTextWrapper}>
                <Typography>Sort code: </Typography>
                <Typography className={classes.bankDetailsText}>{sortCode}</Typography>
              </Box>
            </CardContent>
            <CardContent className={classes.bottomCard}>
              <Typography>Your bank details and payouts are managed by Stripe</Typography>
              <Button
                href={kycLink}
                onClick={() => setDisabled(true)}
                variant="outlined"
                color="primary"
                className={classes.button}
                sx={{ borderRadius: 3, background: 'white' }}
                target="_blank"
                rel="noopener noreferrer"
                disabled={disabled}
              >
                Update
                {disabled ? (
                  <ExternalLinkIconGrey className={classes.externalLink} />
                ) : (
                  <ExternalLinkIcon className={classes.externalLink} />
                )}
              </Button>
            </CardContent>
          </>
        );
    }
  };

  if (!stripeConnectInstance) return <PageLoader />;

  return (
    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: { xs: 1, md: 0.75 } }}>
          <BackArrow redirect={redirect} text="Settings" />
          <Typography className={classes.title}>
            {isAdmin() ? (
              <Link target="_blank" underline="always" href={stripeAccountDashboardLink}>
                Payout details
              </Link>
            ) : (
              'Payout details'
            )}
          </Typography>
          {accountDetails ? (
            <>
              {/* <ConnectNotificationBanner /> */}
              <Paper sx={{ borderRadius: 4, mt: 2 }}>{getStripeStatus()}</Paper>
            </>
          ) : (
            <PageLoader />
          )}
        </Box>
      </Box>
    </ConnectComponentsProvider>
  );
};

export default withVenue(StripePaymentDetails, '/settings/business', clearVenue);
