import React from 'react';

import { useParams } from 'react-router';
import { Typography } from '@mui/material';

import withVenue from '../../hoc/withVenue';
import TabContainer from '../../components/TabContainer';
import PageHeader from '../../components/PageHeader';
import MenuItems from '../MenuItems';
import Ingredients from '../Ingredients';
import ItemPhotos from '../ItemPhotos';
import Modifiers from '../Modifiers';
import Categories from '../Categories';
import Options from '../Options';

const ItemTabs = () => {
  const { tabname } = useParams();

  return (
    <PageHeader fullWidth>
      <Typography style={{ flexGrow: 1 }} variant="h2" component="h1">
        Items
      </Typography>
      <TabContainer
        titles={['Menu items', 'Ingredients', 'Modifiers', 'Sizes', 'Photos', 'Categories']}
        defaultTab={tabname}
        path="/items/"
      >
        <MenuItems />
        <Ingredients />
        <Modifiers />
        <Options />
        <ItemPhotos />
        <Categories />
      </TabContainer>
    </PageHeader>
  );
};

export default withVenue(ItemTabs);
