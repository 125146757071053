import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';

import { FiList } from 'react-icons/fi';

import { RiWifiOffLine, RiWifiLine } from 'react-icons/ri';
import { isEqual, orderBy } from 'lodash';
import CustomDialog from '../CustomDialog/index';
import { getPrintersState } from '../../store/printers/selectors';
import TransferList from '../TransferList';
import { getMissingTablesState, getTablesState } from '../../store/tables/selectors';

import { clearTables, fetchTables } from '../../store/tables';
import { fetchPrinters, updatePrinter, deletePrinter, clearPrinters } from '../../store/printers';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import TableLink from '../TableLink';
import withVenue from '../../hoc/withVenue';
import shouldLoad from '../../shared/utils/shouldLoad';
import useRoles from '../../hooks/useRoles';

const useStyles = makeStyles(() => ({
  dialog: {
    backgroundColor: 'cyan',
  },
}));

const PrintersTable = () => {
  const classes = useStyles();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();
  const { isAdmin } = useRoles();

  const tablesState = useSelector(getTablesState);
  const printersState = useSelector(getPrintersState);
  const { loading: tablesLoading, data: tablesData } = tablesState;
  const { unassignedFoodTables, unassignedDrinksTables, unassignedAllTables } =
    useSelector(getMissingTablesState);
  const { loading: printersLoading, data: printers } = printersState;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentPrinter, setCurrentPrinter] = useState(null);
  const [missing, setMissing] = useState({ FOOD: [], DRINKS: [], ALL: [] });

  useEffect(() => {
    if (shouldLoad(tablesState)) dispatch(fetchTables());
    if (shouldLoad(printersState)) dispatch(fetchPrinters());
  }, [dispatch, printers, printersState, tablesData, tablesState]);

  useEffect(() => {
    setMissing({
      FOOD: unassignedFoodTables,
      DRINKS: unassignedDrinksTables,
      ALL: unassignedAllTables,
    });
  }, [unassignedFoodTables, unassignedDrinksTables, unassignedAllTables]);

  if (!printers) return null;

  const handleCloseDialog = async () => {
    setIsDialogOpen(false);
  };

  const handleAssignment = async (printer, newTables) => {
    handleCloseDialog();
    try {
      const updatedPrinter = {
        ...printer,
        tables: isEqual(newTables.sort(), tablesData.map((table) => table.tableName).sort())
          ? []
          : orderBy(newTables, Number),
      };
      await dispatch(updatePrinter(updatedPrinter));
      showSuccessNotification('Printer has been updated successfully');
      dispatch(fetchTables());
      dispatch(fetchPrinters());
    } catch (localError) {
      showErrorNotification(getErrorMessage(localError));
    }
  };

  const removeCurrentPrinter = async (printer) => {
    const { printerId } = printer;
    try {
      await dispatch(deletePrinter({ printerId }));
      dispatch(fetchPrinters());
      showSuccessNotification('Printer has been removed successfully');
    } catch (err) {
      dispatch(fetchPrinters());
      showErrorNotification(getErrorMessage(err));
    }
  };

  return (
    (!tablesLoading || !printersLoading) && (
      <>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Printer Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Destination</TableCell>
                <TableCell>Autocut</TableCell>
                <TableCell>Connected</TableCell>
                <TableCell>Tables</TableCell>
                {isAdmin() && <TableCell> </TableCell>}
                {isAdmin() && <TableCell> </TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {printers.map((printer) => (
                <TableRow key={printer.printerId}>
                  {isAdmin() ? (
                    <TableCell component="th" scope="row">
                      <TableLink to={`/printers/edit/${printer.printerId}`}>
                        {printer.printerId}
                      </TableLink>
                    </TableCell>
                  ) : (
                    <TableCell>{printer.printerId}</TableCell>
                  )}
                  <TableCell>{printer.name}</TableCell>
                  <TableCell>{printer.destination}</TableCell>
                  <TableCell>{printer.autocut}</TableCell>
                  <TableCell>
                    {printer.online === true ? <RiWifiLine /> : <RiWifiOffLine />}
                  </TableCell>
                  <TableCell>
                    {printer.tables?.length === 0 ? 'ALL' : printer.tables?.join(', ')}
                  </TableCell>

                  {isAdmin() && (
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setIsDialogOpen(true);
                          setCurrentPrinter(printer);
                        }}
                        startIcon={<FiList />}
                      >
                        Assign
                      </Button>
                    </TableCell>
                  )}
                  {isAdmin() && (
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          removeCurrentPrinter(printer);
                        }}
                        startIcon={<FiList />}
                      >
                        Remove
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomDialog
          title="Assign Tables to printer"
          isDialogOpen={isDialogOpen}
          handleCloseDialog={handleCloseDialog}
        >
          <TransferList
            assigned={
              currentPrinter?.tables.length === 0
                ? tablesData.map((table) => table.tableName)
                : currentPrinter?.tables
            }
            unassigned={
              currentPrinter?.tables.length === 0 ? [] : missing[currentPrinter?.destination]
            }
            label="Table"
            className={classes.dialog}
            onTransfer={(updated) => handleAssignment(currentPrinter, updated)}
            onClose={handleCloseDialog}
          />
        </CustomDialog>
      </>
    )
  );
};

export default withVenue(PrintersTable, null, [clearTables, clearPrinters]);
