import { CardContent, Typography, Link, Button, Collapse } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import './HWForm.css';
import StatusLabel from '../StatusLabel';

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    ...theme.customFonts.mediumBold,
    marginBottom: '12px',
  },
  payoutText: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
    marginTop: '12px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  timer: {
    ...theme.customFonts.large,
    color: theme.customPalette.destructiveRed,
  },
  expandButton: {
    marginTop: theme.spacing(1),
  },
}));

export default ({ venue }) => {
  const classes = useStyles();
  const { hwUrl, hwAccessToken, hwStatus } = venue || {};
  const [hwDropIn, setHwDropIn] = useState(null);
  const [showHWSection, setShowHWSection] = useState(hwStatus !== 'VERIFIED');
  const [isCategoryOpen, setCategoryOpen] = useState(false);
  const onRefSet = useCallback((ref) => {
    setHwDropIn(ref);
  }, []);

  const handleHWScriptOnLoad = (el, accessToken) => {
    if (!window.HWWidgets) return;

    window.HWWidgets.initialize((onSuccess) => onSuccess(accessToken));

    window.HWWidgets.users
      .configure({
        template: 'plain',
        el,
      })
      .display(() => {});

    window.HWWidgets.events.on('widget:users:completed', (verificationObject, completionResult) => {
      // redirect payee to another page with the result, status and token
      const { token: hwToken, status: verfificationStatus } = verificationObject;
      const redirect = window.location.origin;
      const url = `http://${redirect}?result=${completionResult}&token=${hwToken}&status=${verfificationStatus}`;
      window.location = url;
    });

    window.HWWidgets.events.on('widget:loading:shown', () => {
      // code to add spinner or other loading icon
    });

    window.HWWidgets.events.on('widget:loading:hidden', () => {
      // code to remove loading icon
    });
  };

  const injectScript = useCallback(
    (scriptUrl) => {
      const hasScriptUrl = scriptUrl !== 'NONE';

      if (!hasScriptUrl) {
        setShowHWSection(false);
        return;
      }

      const script = document.createElement('script');
      script.src = scriptUrl;
      document.head.appendChild(script);
      script.id = 'hyperwallet-dropin';
      script.addEventListener('load', () => {
        handleHWScriptOnLoad(hwDropIn, hwAccessToken);
      });
    },
    [hwDropIn, hwAccessToken],
  );

  const handleScriptInjection = useCallback(
    (url) => {
      const scriptElement = document.querySelector('#hyperwallet-dropin');

      if (scriptElement) {
        scriptElement.remove();
        injectScript(url);
      } else {
        injectScript(url);
      }
    },
    [injectScript],
  );

  useEffect(() => {
    if (hwAccessToken && hwDropIn) {
      handleScriptInjection(hwUrl);
    }
  }, [hwAccessToken, hwDropIn, handleScriptInjection, hwUrl]);

  return (
    <>
      {!showHWSection || hwStatus !== 'REQUIRED' ? (
        <CardContent>
          <header>
            <Typography className={classes.cardTitle}>Payout verification</Typography>
          </header>
          {hwStatus && hwStatus !== 'NONE' && (
            <StatusLabel outlined status={hwStatus} text="lets try" backgroundColor />
          )}
          <Typography className={classes.payoutText}>
            Sessions Serve uses Paypal (Hyperwallet) payout services to securely deliver your
            payments. These services are subject to the{' '}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              underline="always"
              href="https://pay.hyperwallet.com/hw2web/consumer/page/legalAgreement.xhtml"
            >
              Hyperwallet Terms of Service and the Hyperwallet Privacy Policy
            </Link>
            .
          </Typography>
        </CardContent>
      ) : (
        <>
          <CardContent>
            <header className={classes.header}>
              <Typography className={classes.mcardTitle}>Payout verification</Typography>
              <div className={classes.timer}>
                Please fill in this form within 10 minutes. (<Timer countdownCondition={venue} />)
              </div>
            </header>
            {hwStatus && hwStatus !== 'NONE' && (
              <StatusLabel outlined status={hwStatus} backgroundColor />
            )}
            <Typography className={classes.payoutText}>
              Sessions Serve uses Paypal (Hyperwallet) payout services to securely deliver your
              payments.These services are subject to the{' '}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                underline="always"
                href="https://pay.hyperwallet.com/hw2web/consumer/page/legalAgreement.xhtml"
              >
                Hyperwallet Terms of Service and the Hyperwallet Privacy Policy
              </Link>
              .
            </Typography>
            <Button
              className={classes.expandButton}
              onClick={() => setCategoryOpen(!isCategoryOpen)}
            >
              Show form {isCategoryOpen ? <ExpandLess /> : <ExpandMore />}
            </Button>
            <Collapse in={isCategoryOpen} timeout="auto">
              <div ref={onRefSet} />
            </Collapse>
          </CardContent>
        </>
      )}
    </>
  );
};

const Timer = ({ countdownCondition }) => {
  const history = useHistory();
  const [timeLeft, setTimeLeft] = useState(600);

  const formatTimeLeft = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    const zeroPaddedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${minutes}:${zeroPaddedSeconds}`;
  };

  const formattedTimeLeft = formatTimeLeft(timeLeft);

  useEffect(() => {
    const startTimeLeftCountdown = () => {
      const timeLeftInterval = window.setInterval(() => {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
      }, 1000);

      return timeLeftInterval;
    };

    if (countdownCondition) {
      const noTimeLeft = !timeLeft;
      const timer = startTimeLeftCountdown();

      if (noTimeLeft) {
        clearTimeout(timer);
        history.go(0);
      }

      return () => clearTimeout(timer);
    }

    return null;
  }, [history, countdownCondition, timeLeft]);

  return <span>{formattedTimeLeft}</span>;
};
