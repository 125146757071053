import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Typography, Card, IconButton, Button, Chip, TextField, Grid } from '@mui/material';
import { MdDelete, MdAdd } from 'react-icons/md';
import Autocomplete from '@mui/material/Autocomplete';

import { pick } from 'lodash';
import { makeStyles } from '@mui/styles';
import { useHistory, useParams } from 'react-router';
import OrderableTable from '../../../components/OrderableTable';
import TableButton from '../../../components/TableButton/index';
import { getBrandsState } from '../../../store/brands/brands/selectors';
import { fetchBrands } from '../../../store/brands/brands';
import {
  createBrandsIngredient,
  fetchBrandsIngredients,
  updateBrandsIngredient,
  deleteBrandsIngredient,
} from '../../../store/brands/brandsIngredients';
import { getBrandsIngredientsState } from '../../../store/brands/brandsIngredients/selectors';

import Page from '../../../components/Page';
import withVenue from '../../../hoc/withVenue';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../shared/utils/errors';
import shouldLoad from '../../../shared/utils/shouldLoad';
import CustomDialog from '../../../components/CustomDialog';
import useRoles from '../../../hooks/useRoles';
import PageHeader from '../../../components/PageHeader';
import BrandsIngredientForm from '../../../components/Brands/BrandsIngredientForm';
import { filterTableData } from '../../../shared/utils/filterData';
import useSearch from '../../../hooks/useSearch';

const useStyles = makeStyles((theme) => ({
  tableControlsBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: `${theme.spacing(2)} 0`,
  },
  searchInput: {
    display: 'block',
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  brandSelect: {
    width: '200px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

const BrandsIngredients = () => {
  const localStorageBrandsFilter = JSON.parse(localStorage.getItem('brandsFilter'));
  const [brandsFilter, setBrandsFilter] = useState(localStorageBrandsFilter || []);
  const classes = useStyles();
  const dispatch = useDispatch();
  const ingredientsState = useSelector(getBrandsIngredientsState);
  const brandsState = useSelector(getBrandsState);
  const { data: brands } = brandsState;
  const history = useHistory();
  const { itemId: ingredientParamId } = useParams() || {};
  const { loading, data, error } = ingredientsState;
  const [valueData, setValueData] = useState(data);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [ingredientData, setIngredientData] = useState(null);
  const [formAction, setFormAction] = useState('');
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { isRoleAtLeastManager } = useRoles();
  const searchKeys = useMemo(() => ['ingredientName', 'label'], []);
  const threshold = 0.2;

  const { searchResults, searchError, handleSearch, filteredItems, setFilteredItems } = useSearch(
    data,
    searchKeys,
    threshold,
    valueData,
  );

  const handleEdit = useCallback(
    (ingredient) => {
      // When calling this function, a check is first made to see if the ingredient is an id, not an object
      if (ingredientParamId && data) {
        const ingrToUpdate = data.find((obj) => obj.ingredientId === ingredientParamId);
        setFormAction('update');
        setIngredientData(ingrToUpdate);
        setIsDialogOpen(true);
        return;
      }
      setFormAction('update');
      setIngredientData(ingredient);
      setIsDialogOpen(true);
    },
    [data, ingredientParamId],
  );

  const handleDelete = useCallback(
    async (ingredientId) => {
      try {
        await dispatch(deleteBrandsIngredient(ingredientId));
        await dispatch(fetchBrandsIngredients());
        showSuccessNotification('Ingredient has been deleted successfully');
      } catch (err) {
        showErrorNotification(getErrorMessage(err));
      }
    },
    [dispatch, showErrorNotification, showSuccessNotification],
  );

  const valueFormatter = useCallback(
    ({ value, valueName, row }) => {
      switch (valueName) {
        case 'ingredientName':
          return row.readonly ? (
            <p>
              {value} <Chip label="Read-only" />
            </p>
          ) : (
            <TableButton
              onClick={() => {
                handleEdit(row);
              }}
            >
              {row.ingredientName}
            </TableButton>
          );
        case 'delete':
          return (
            <IconButton
              edge="end"
              size="small"
              onClick={() => handleDelete(row.ingredientId)}
              disabled={row?.readonly}
            >
              <MdDelete />
            </IconButton>
          );
        default:
          return value;
      }
    },
    [handleDelete, handleEdit],
  );

  const newData = useCallback(() => {
    const pickedData = [];
    if (filteredItems) {
      filteredItems.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item = {
          ...item,
          delete: 'delete',
        };
        pickedData.push(
          pick(item, [
            'ingredientName',
            'label',
            'brandName',
            'type',
            'ingredientId',
            'delete',
            'isAlcoholic',
            'readonly',
          ]),
        );
      });
    }
    return pickedData;
  }, [filteredItems]);

  const handleOnSubmit = async (values) => {
    if (formAction === 'create') {
      try {
        await dispatch(createBrandsIngredient(values));
        await dispatch(fetchBrandsIngredients());
        showSuccessNotification('Ingredient has been added successfully');
        setIsDialogOpen(false);
      } catch (e) {
        showErrorNotification(getErrorMessage(e));
        setIsDialogOpen(false);
      }
    }

    if (formAction === 'update') {
      try {
        const { ingredientId, ingredientName } = ingredientData;
        await dispatch(updateBrandsIngredient({ ingredientId, values }));
        if (ingredientParamId) {
          history.goBack();
          await dispatch(fetchBrandsIngredients());
          showSuccessNotification(`${ingredientName} has been updated successfully`);
          return;
        }
        await dispatch(fetchBrandsIngredients());
        showSuccessNotification(`${ingredientName} has been updated successfully`);
        setIsDialogOpen(false);
      } catch (newError) {
        showErrorNotification(getErrorMessage(newError));
      }
    }
  };

  const handleOpenDialog = () => {
    setIngredientData(null);
    setFormAction('create');
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    if (ingredientParamId) {
      history.goBack();
    }
  };

  const dialogTitle = useMemo(() => {
    if (formAction === 'update') {
      return 'Update Ingredient';
    }
    return 'Create Ingredient';
  }, [formAction]);

  useEffect(() => {
    if (shouldLoad(ingredientsState)) dispatch(fetchBrandsIngredients());
    if (shouldLoad(brandsState)) dispatch(fetchBrands());
    setValueData(data);
    setFilteredItems(filterTableData(brandsFilter, searchResults || data));
  }, [
    brandsFilter,
    brandsState,
    data,
    dispatch,
    ingredientsState,
    searchResults,
    setFilteredItems,
  ]);

  // To check if param for ingredient id is present in URL
  useEffect(() => {
    if (ingredientParamId) {
      handleEdit(ingredientParamId);
    }
  }, [handleEdit, ingredientParamId]);

  const handleBrandsFilter = (_e, value) => {
    setBrandsFilter(value);
    localStorage.setItem('brandsFilter', JSON.stringify(value));
    setFilteredItems(filterTableData(value, searchResults));
  };

  return (
    <>
      <PageHeader fullWidth>
        <Box>
          <aside>
            <CustomDialog
              isDialogOpen={isDialogOpen}
              handleCloseDialog={handleCloseDialog}
              title={dialogTitle}
            >
              <BrandsIngredientForm
                formAction={formAction}
                ingredientData={ingredientData}
                onSubmit={handleOnSubmit}
                onCancel={handleCloseDialog}
              />
            </CustomDialog>
          </aside>
        </Box>
        <Typography paragraph>
          Ingredients appear as options on products (e.g. mixers for spirits, flavours for ice
          cream)
        </Typography>
      </PageHeader>
      <Grid container className={classes.tableControlsBox} direction="row">
        <Grid item container spacing={2} sm={12} md={9} direction="row">
          <Grid item md={4} sm={6} lg={4} xl={3}>
            <TextField
              className={classes.searchInput}
              label="Search Ingredients"
              variant="outlined"
              fullWidth
              helperText={searchError}
              onChange={handleSearch}
            />
          </Grid>
          <Grid item md={8} sm={6} lg={7} xl={4}>
            {brands && brandsFilter && (
              <Autocomplete
                fullWidth
                multiple
                limitTags={2}
                id="brands-autocomplete"
                options={brands}
                getOptionLabel={(option) => option.name}
                defaultValue={
                  brandsFilter.length > 0
                    ? brands.filter((brand) =>
                        brandsFilter.some((filter) => filter.brandId === brand.brandId),
                      )
                    : brands
                }
                onChange={handleBrandsFilter}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Brands" />
                )}
              />
            )}
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          disabled={!isRoleAtLeastManager()}
          onClick={handleOpenDialog}
          startIcon={<MdAdd />}
        >
          Create Ingredient
        </Button>
      </Grid>

      <Page loading={loading} error={error} fullWidth>
        {filteredItems === null && (
          <Box style={{ paddingTop: 16 }}>
            <Typography align="left" variant="h2" color="textSecondary">
              No brand selected, please select at least brand to view ingredients
            </Typography>
          </Box>
        )}
        {filteredItems && filteredItems.length === 0 && (
          <Box style={{ paddingTop: 16 }}>
            <Typography align="left" variant="h2" color="textSecondary">
              No ingredients found
            </Typography>
          </Box>
        )}
        {data && (
          <>
            {valueData && data && (
              <Card>
                <OrderableTable
                  tableData={[...newData()]}
                  titles={['INTERNAL NAME', 'EXTERNAL LABEL', 'BRAND', 'TYPE', '']}
                  keys={['ingredientId']}
                  excludeFields={['ingredientId', 'isAlcoholic']}
                  disableColumnTitles={['']}
                  valueFormatter={valueFormatter}
                />
              </Card>
            )}
          </>
        )}
      </Page>
    </>
  );
};

export default withVenue(BrandsIngredients);
