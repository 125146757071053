import React, { useState } from 'react';
import { Button, CircularProgress, Popover } from '@mui/material';
import DriveFolderUploadIcon from '@mui/icons-material/CloudUpload';
import { useDispatch } from 'react-redux';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { fetchVenueMenu, publishVenueMenu, fetchVenueMenuStatus } from '../../store/venueMenus';
import { getErrorMessage } from '../../shared/utils/errors';

const PublishOtterMenu = ({ otterStoreId, menuName, venueMenuTargets }) => {
  const dispatch = useDispatch();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(undefined);

  const handleClose = () => {
    setDropdownOpen(false);
    setAnchorEl(undefined);
  };

  const handleClick = async (_event, target) => {
    setLoading(true);
    try {
      const targets = target ? [target] : venueMenuTargets;
      await dispatch(
        publishVenueMenu(
          otterStoreId,
          targets.map((t) => t.name),
        ),
      );
      setLoading(false);
      dispatch(fetchVenueMenu(menuName));
      dispatch(fetchVenueMenuStatus({ menuName }));
      showSuccessNotification(`Menu published to ${targets.map((t) => t.displayName).join(', ')}`);
      return null;
    } catch (e) {
      setLoading(false);
      showErrorNotification(getErrorMessage(e));
      return null;
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <Button
        disabled={loading || !venueMenuTargets || venueMenuTargets.length === 0}
        variant="contained"
        color="primary"
        component="label"
        id={otterStoreId}
        onClick={handleClick}
        fullWidth
        startIcon={loading ? <CircularProgress size={20} /> : <DriveFolderUploadIcon />}
      >
        Publish All
      </Button>
      {/* Hide whilst we determine if we want to support this */}
      {/* {venueMenuTargets && venueMenuTargets.length > 0 && (
        <>
          <Button
            size="small"
            color="primary"
            variant="contained"
            style={{ marginLeft: '.5em' }}
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setDropdownOpen(!dropdownOpen);
            }}
          >
            <ArrowDropDown />
          </Button>
        </>
      )} */}
      {dropdownOpen && (
        <Popover
          open={dropdownOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          style={{ width: '100%' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div>
            {venueMenuTargets &&
              venueMenuTargets.length > 0 &&
              venueMenuTargets.map((target) => (
                <Button
                  key={target.name}
                  variant="outlined"
                  color="primary"
                  onClick={(e) => {
                    handleClick(e, target);
                    handleClose(e);
                  }}
                  style={{ width: '100%' }}
                >
                  {` Publish to ${target.displayName}`}
                </Button>
              ))}
          </div>
        </Popover>
      )}
    </div>
  );
};
export default PublishOtterMenu;
