import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sortBy, prop } from 'ramda';
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Box,
  TextField,
} from '@mui/material';
import Fuse from 'fuse.js';
import { useDebouncedCallback } from 'use-debounce';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';

import { setVenue } from '../../../../store/venues';
import { getVenuesState } from '../../../../store/venues/selectors';
import VenueTableRow from './VenueTableRow';

const tableHeaders = [
  'Venue Name',
  'Type',
  'Variation',
  'Otter Push',
  'Deliveroo',
  'Uber Eats',
  'Just Eat',
  'Actions',
];

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(2),
    '& .MuiTableCell-root:last-child': {
      paddingRight: '16px',
    },
  },
  tableHead: {
    backgroundColor: theme.customPalette.midnight,
    '& .MuiTableCell-head': {
      color: theme.customPalette.white,
      fontWeight: 'bold',
    },
  },
}));

const VenueTable = ({
  variationId,
  brandsVenueAssociations,
  handleVenueAssociation,
  handleRemoveAssociation,
  brandId,
}) => {
  const [noResultsFound, setNoResultsFound] = useState('press the plus button to add a venue');
  const [tableData, setTableData] = useState(brandsVenueAssociations);
  const [searchData, setSearchData] = useState(brandsVenueAssociations);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const venuesState = useSelector(getVenuesState);
  const { data: venues } = venuesState;

  const fuse = new Fuse(searchData, {
    keys: ['venueName', 'venueId'],
    threshold: 0.3,
  });

  const handleSearch = useDebouncedCallback((value) => {
    if (value === '') {
      setTableData(searchData);
    } else {
      const searchResults = fuse.search(value);
      if (searchResults.length === 0) setNoResultsFound('please try a different search term');
      const flatResults = searchResults.reduce((a, c) => [...a, c.item], []);
      setTableData(flatResults);
    }
  }, 350);

  const handleVenueMenuLink = async (newVenueId, menuId) => {
    const newVenue = venues.find((venue) => venue.venueId === newVenueId);
    if (newVenue) {
      await dispatch(setVenue(newVenue));
      history.push(`/menus/${menuId}`);
    }
  };

  useEffect(() => {
    if (variationId) {
      setTableData(brandsVenueAssociations.filter((venue) => venue.variationId === variationId));
      setSearchData(brandsVenueAssociations.filter((venue) => venue.variationId === variationId));
    }
  }, [variationId, brandsVenueAssociations]);

  return (
    <>
      <Box>
        <TextField
          variant="outlined"
          size="small"
          label="Venue name"
          onChange={(e) => handleSearch(e.target.value)}
          sx={{ minWidth: 1 / 3 }}
        />
      </Box>
      <TableContainer component={Paper} className={classes.table}>
        <Table size="small">
          <TableHead className={classes.tableHead}>
            <TableRow key="table-headers">
              {tableHeaders.map((header, index) => (
                <TableCell align={index === 0 ? 'left' : 'right'} key={header}>
                  <Typography variant="subtitle2">
                    <strong>{header}</strong>
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.length > 0 ? (
              sortBy(prop('venueName'), tableData).map((venue) => (
                <VenueTableRow
                  handleRemoveAssociation={handleRemoveAssociation}
                  venue={venue}
                  key={venue.venueId}
                  handleVenueAssociation={handleVenueAssociation}
                  handleVenueMenuLink={handleVenueMenuLink}
                  brandId={brandId}
                />
              ))
            ) : (
              <TableCell colSpan={4}>
                <Typography variant="body1" sx={{ p: 2, pl: 0 }}>
                  No venues found, {noResultsFound}
                </Typography>
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default VenueTable;
