import React from 'react';
import { Box, Divider, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import QRCode from 'qrcode.react';
import { FiArrowUpRight } from 'react-icons/fi';

const useStyles = makeStyles((theme) => ({
  qrImgContainer: {
    display: 'none',
  },
  viewMenuDiv: {
    marginTop: 'auto',
    flexDirection: 'column',
    position: 'sticky',
    width: '100%',
    bottom: '0',
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      '&:hover $qrImgContainer': {
        display: 'flex',
        opacity: '0.8',
        paddingBottom: '8px',
      },
    },
  },
  muiLink: {
    display: 'flex',
    alignItems: 'center',
    color: '#334E68',
  },
}));

const QrContainer = ({ title, value, isLink }) => {
  const classes = useStyles();

  return (
    <Box className={classes.viewMenuDiv}>
      <Divider />
      <Box p={2}>
        <QRCode value={value} size={125} className={isLink && classes.qrImgContainer} />
        {isLink && (
          <Link rel="noopener noreferrer" href={value} target="_blank" className={classes.muiLink}>
            {title}
            <FiArrowUpRight />
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default QrContainer;
