import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import { makeStyles } from '@mui/styles';

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  options: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.customPalette.greyLight,
    },
  },
  unavailable: {
    color: theme.customPalette.greyDarker,
    ...theme.customFonts.small,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  unavailableIcon: {
    width: '16px',
    height: '16px',
    marginLeft: '16px',
  },
  optionLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  brandItemSymbol: {
    width: 30,
    height: 30,
    marginLeft: theme.spacing(1),
  },
}));

const AutoComplete = ({
  label,
  options,
  optionSearchKey,
  optionLabelKey,
  optionValueKey,
  onSelect,
  value,
  disabled,
  addUnknownOff,
  onInputChange,
  suggestedPrefix = 'Add ',
  reset,
  checkIfItemIsOnMenu,
}) => {
  const classes = useStyles();
  const searchKey = optionSearchKey || optionLabelKey;
  const inputRef = useRef(null);
  const strikeThroughNotOnMenu = !!checkIfItemIsOnMenu;
  const strikeThroughUnavailable = !!checkIfItemIsOnMenu;
  const handleChange = (_, newValue) => {
    if (!newValue) return;
    if (typeof newValue === 'string') {
      onSelect(null, newValue);
      return;
    }
    if (newValue && newValue.inputValue) {
      onSelect(null, newValue.inputValue);
      return;
    }
    onSelect(newValue[optionValueKey], newValue[optionLabelKey]);
  };

  const filterOptions = (localOptions, params) => {
    const filtered = filter(localOptions, params);
    if (params.inputValue !== '' && !addUnknownOff) {
      filtered.push({
        inputValue: params.inputValue,
        [searchKey]: `${suggestedPrefix}"${params.inputValue}"`,
        suggestedItem: true,
      });
    }
    return filtered;
  };

  const getOptionLabel = () => (option) => {
    if (!option) return '';
    if (typeof option === 'string') {
      return option;
    }
    if (option.inputValue) {
      return option.inputValue;
    }

    return option[searchKey] || '';
  };

  useEffect(() => {
    if (reset) {
      inputRef.current.focus();
    }
  }, [inputRef, reset]);

  return (
    <Autocomplete
      selectOnFocus
      handleHomeEndKeys
      fullWidth
      freeSolo
      autoHighlight
      disabled={disabled}
      open={reset}
      defaultValue={value}
      value={value || (reset && '')}
      options={options}
      groupBy={(option) => option.category}
      onInputChange={onInputChange}
      onChange={handleChange}
      filterOptions={filterOptions}
      getOptionKey={(option) => option.itemId || option.inputValue || option.id}
      getOptionLabel={getOptionLabel()}
      renderOption={(props, option) => {
        const isUnavailable = !option.available;

        return (
          <div {...props} className={classes.options} key={option.key || props.key}>
            <span className={classes.optionLabel}>
              {option[optionLabelKey]} {option.brandName && ` | ${option.brandName}`}
              {option.brandName && (
                <img
                  src="/img/sessions_symbol.svg"
                  className={classes.brandItemSymbol}
                  alt="Sessions Brand Item"
                />
              )}
            </span>
            {!!strikeThroughNotOnMenu && (
              <span className={classes.unavailable}>
                {strikeThroughNotOnMenu &&
                  !checkIfItemIsOnMenu(option.itemId) &&
                  !option.suggestedItem && (
                    <span>
                      <img
                        className={classes.unavailableIcon}
                        src="/img/visibility/not-on-menu.svg"
                        alt="Not on menu"
                        title="Item is not on active menu"
                      />
                      &nbsp; Not on menu
                    </span>
                  )}
                {strikeThroughUnavailable &&
                  isUnavailable &&
                  checkIfItemIsOnMenu(option.itemId) &&
                  !option.suggestedItem && (
                    <span>
                      <img
                        className={classes.unavailableIcon}
                        src="/img/visibility/unavailable.svg"
                        alt="Unavailable"
                        title="Item is unavailable"
                      />
                      &nbsp; Unavailable
                    </span>
                  )}
              </span>
            )}
          </div>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} inputRef={inputRef} label={label} variant="outlined" />
      )}
    />
  );
};

AutoComplete.defaultProps = {
  onSelect: () => {},
};

AutoComplete.propTypes = {
  label: PropTypes.string.isRequired,
  optionLabelKey: PropTypes.string.isRequired,
  optionValueKey: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func,
};

export default AutoComplete;
