import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Box, Button, Card } from '@mui/material';
import { pick } from 'lodash';
import OptionForm from '../../components/OptionForm/index';

import { clearOptions, createOption, fetchOptions } from '../../store/options';
import { getOptionsState } from '../../store/options/selectors';

import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';
import shouldLoad from '../../shared/utils/shouldLoad';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import CustomDialog from '../../components/CustomDialog';
import { getErrorMessage } from '../../shared/utils/errors';
import OrderableTable from '../../components/OrderableTable';
import useRoles from '../../hooks/useRoles';
import PageHeader from '../../components/PageHeader';

const useStyles = makeStyles(() => ({
  heading: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  buttonClass: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: '10px',
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
    alignItems: 'center',
  },
}));

const Options = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const optionsState = useSelector(getOptionsState);
  const { loading, data, error } = optionsState;
  const [valueData, setValueData] = useState(data);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [optionData, setOptionData] = useState(null);
  const [formAction, setFormAction] = useState('');
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { isRoleAtLeastManager } = useRoles();

  const handleOnSubmit = async (values) => {
    if (formAction === 'create') {
      try {
        await dispatch(createOption(values));
        showSuccessNotification('Size has been added successfully');
        setIsDialogOpen(false);
        dispatch(fetchOptions());
      } catch (e) {
        showErrorNotification(getErrorMessage(e));
        setIsDialogOpen(false);
      }
    }
  };

  const handleOpenDialog = () => {
    setOptionData(null);
    setFormAction('create');
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const newData = useCallback(() => {
    const pickedData = [];
    if (data) {
      data.forEach((item) => {
        pickedData.push(pick(item, ['optionName', 'type', 'optionId']));
      });
    }
    return pickedData;
  }, [data]);

  const ProductSizeOptionForm = () => (
    <OptionForm
      formAction={formAction}
      optionData={optionData}
      onSubmit={handleOnSubmit}
      onCancel={handleCloseDialog}
    />
  );

  const dialogTitle = useMemo(() => {
    if (formAction === 'update') {
      return 'Update product size';
    }
    return 'Create new product size';
  }, [formAction]);

  useEffect(() => {
    if (shouldLoad(optionsState)) dispatch(fetchOptions());
    setValueData(data);
  }, [data, dispatch, optionsState]);

  return (
    <>
      <PageHeader fullWidth>
        <div className={classes.heading}>
          <Box className={classes.buttonClass}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenDialog}
              disabled={!isRoleAtLeastManager()}
            >
              New Size
            </Button>
          </Box>
          <aside>
            <CustomDialog
              isDialogOpen={isDialogOpen}
              handleCloseDialog={handleCloseDialog}
              title={dialogTitle}
            >
              <ProductSizeOptionForm />
            </CustomDialog>
          </aside>
        </div>
      </PageHeader>
      <Page loading={loading} error={error} fullWidth>
        {data && (
          <>
            {valueData && data && (
              <Card>
                <>
                  <OrderableTable
                    tableData={[...newData()]}
                    titles={['NAME', 'TYPE']}
                    keys={['optionId']}
                    excludeFields={['optionId']}
                  />
                </>
              </Card>
            )}
          </>
        )}
      </Page>
    </>
  );
};

export default withVenue(Options, '/items/sizes', clearOptions);
