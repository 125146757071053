export const WEEKDAYS = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
];

export const formatTimeString = (hour, minute) => {
  if (hour === null || minute === null) {
    return null;
  }
  return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
};

export const removeAllHoursForDay = (openingHours, day) => {
  const nextDayIndex = (WEEKDAYS.indexOf(day) + 1) % 7;

  const currentDayHours = openingHours.filter((opening) => opening.day === day);
  const nextDayHours = openingHours.filter((opening) => opening.day === WEEKDAYS[nextDayIndex]);

  const currentDayHoursToRemove = currentDayHours.filter((opening) => opening.fromHour >= 5);
  const nextDayHoursToRemove = nextDayHours.filter((opening) => opening.toHour < 5);

  return openingHours.filter(
    (opening) =>
      !currentDayHoursToRemove.includes(opening) && !nextDayHoursToRemove.includes(opening),
  );
};

const entryShouldBePreviousDay = (openingHourEntry) => {
  const { fromHour, fromMinute, toHour, toMinute } = openingHourEntry;

  return (fromHour === 0 && fromMinute === 0 && toHour <= 5 && toMinute === 0) || toHour < 5;
};

const generateWeek = () => {
  const week = [];

  WEEKDAYS.forEach((weekday) => {
    week.push({
      day: weekday,
      openingHours: [],
      closed: true,
    });
  });

  return week;
};

export const parseOpeningHoursforUi = ({ openingHours, addEmptyArray = false }) => {
  const weekArr = generateWeek();

  if (!openingHours) {
    return weekArr;
  }

  openingHours.forEach((openingEntry) => {
    const { day, fromHour, fromMinute, toHour, toMinute } = openingEntry;
    const entryDayInWeek = weekArr.find((weekday) => weekday.day === day);

    // See if entry that should be in previous day
    const entryDayShouldBePreviousDay = entryShouldBePreviousDay(openingEntry);

    if (entryDayShouldBePreviousDay) {
      // get the previous day within the weekArr
      const previousDayIndex = WEEKDAYS.indexOf(day) - 1;
      const previousDay = weekArr[previousDayIndex >= 0 ? previousDayIndex : WEEKDAYS.length - 1];

      // New object with the combined data
      const combinedPreviousDayEntry = {
        ...previousDay.openingHours[previousDay.openingHours.length - 1],
        toHour,
        toMinute,
        nextDay: day.toUpperCase(),
      };
      entryDayInWeek.closed = false;
      // Replace the last entry in the previous day with the combined object
      previousDay.openingHours[previousDay.openingHours.length - 1] = combinedPreviousDayEntry;
    } else {
      // Add the new entry to the day
      entryDayInWeek.closed = false;
      entryDayInWeek.openingHours.push({
        fromHour,
        fromMinute,
        toHour,
        toMinute,
        nextDay: false,
      });
    }
  });

  if (addEmptyArray) {
    // Add empty object if no opening hours
    weekArr.forEach((openingDay, index) => {
      if (openingDay.openingHours.length === 0) {
        weekArr[index].closed = true;
        openingDay.openingHours.push({
          fromHour: null,
          fromMinute: null,
          toHour: null,
          toMinute: null,
          nextDay: false,
        });
      }
    });
  }

  // if any of the days are not closed but also have no opening hours, set it to closed
  weekArr.forEach((openingDay, index) => {
    if (openingDay.openingHours.length === 0) {
      weekArr[index] = {
        ...openingDay,
        closed: true,
      };
    }
  });
  return weekArr;
};

export const parseOpeningHoursforApi = (openingHours) => {
  const openingHoursApi = [];
  openingHours.forEach((day, dayIndex) => {
    if (day.closed) return;

    day.openingHours.forEach((segment) => {
      const nextDayIndex = (dayIndex + 1) % 7;

      if (
        segment.fromHour === null ||
        segment.fromMinute === null ||
        segment.toHour === null ||
        segment.toMinute === null
      )
        return;

      if (segment.nextDay) {
        openingHoursApi.push({
          day: day.day,
          fromHour: segment.fromHour,
          fromMinute: segment.fromMinute,
          toHour: 23,
          toMinute: 59,
        });

        openingHoursApi.push({
          day: WEEKDAYS[nextDayIndex],
          fromHour: 0,
          fromMinute: 0,
          toHour: segment.toHour,
          toMinute: segment.toMinute,
        });
      } else {
        openingHoursApi.push({
          day: day.day,
          fromHour: segment.fromHour,
          fromMinute: segment.fromMinute,
          toHour: segment.toHour,
          toMinute: segment.toMinute,
        });
      }
    });
  });

  return openingHoursApi;
};
