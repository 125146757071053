import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import Login from '../pages/Login';
import Callback from '../pages/Callback';
import Onboarding from '../pages/Onboarding';
import Signup from '../pages/Signup';
import LayoutNotAuthenticated from '../components/LayoutNotAuthenticated';

const NotAuthenticatedApp = () => {
  const isSignupRoute = useRouteMatch('/signup');
  const isBrandUp = !!useRouteMatch('/signup/brandup');
  const isLayoutRoute = !isSignupRoute; // chain here " || otherRoutesThatDontNeedLayout"

  if (!isLayoutRoute) {
    return (
      <Switch>
        <Route path="/signup" render={() => <Signup isBrandUp={isBrandUp} />} />
      </Switch>
    );
  }

  if (isLayoutRoute) {
    return (
      <LayoutNotAuthenticated>
        <Switch>
          <Route exact path="/onboarding" component={Onboarding} />
          <Route exact path="/onboarding-fail" component={Onboarding} />
          <Route exact path="/callback" component={Callback} />
          <Route component={Login} />
        </Switch>
      </LayoutNotAuthenticated>
    );
  }

  return null;
};

export default NotAuthenticatedApp;
