import React, { useState } from 'react';
import { Button, Popover, CircularProgress, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { KeyboardArrowDown, KeyboardArrowUp, Update } from '@mui/icons-material';
import DriveFolderUploadIcon from '@mui/icons-material/CloudUpload';

import {
  publishBrandsVenuesAssociationsMenu,
  fetchBrandsVenuesAssociations,
} from '../../../../../../store/brands/brandsVenueAssociations';
import { useNotifications } from '../../../../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../../../../shared/utils/errors';

import PushOtterMenu from '../../../../../PushOtterMenu';
import StatusTooltip from '../Status/StatusTooltip';

const useStyles = makeStyles((theme) => ({
  popover: {
    '& .MuiPopover-paper': {
      padding: theme.spacing(1),
    },
  },
}));

const VenueActions = ({
  venueAssociationValues,
  menuName,
  isVersionUpToDate,
  handleVenueAssociation,
  deliveryPlatforms,
  brandId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { otterStoreId, venueId } = venueAssociationValues;
  const [actionDropdownOpen, setActionDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const { showErrorNotification, showSuccessNotification } = useNotifications();

  const handleActionDropdownClose = () => {
    setActionDropdownOpen(false);
    setAnchorEl(undefined);
  };

  const handlePublishClick = async (_event, target) => {
    setLoading(true);
    try {
      const targets = target ? [target] : deliveryPlatforms;
      await dispatch(
        publishBrandsVenuesAssociationsMenu({
          otterStoreId,
          targets: targets.map((t) => t.name),
          venueId,
        }),
      );
      setLoading(false);
      await dispatch(fetchBrandsVenuesAssociations(brandId));
      showSuccessNotification(`Menu published to ${targets.map((t) => t.displayName).join(', ')}`);
    } catch (e) {
      setLoading(false);
      showErrorNotification(getErrorMessage(e));
    }
  };

  if (!isVersionUpToDate)
    return (
      <StatusTooltip title="Update association">
        <IconButton
          color="primary"
          onClick={() => handleVenueAssociation({ venueId, menuName }, true)}
        >
          <Update />
        </IconButton>
      </StatusTooltip>
    );

  return (
    <>
      <IconButton
        color="primary"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          setActionDropdownOpen(!actionDropdownOpen);
        }}
      >
        {actionDropdownOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </IconButton>
      {actionDropdownOpen && (
        <Popover
          open={actionDropdownOpen}
          onClose={handleActionDropdownClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          className={classes.popover}
        >
          <PushOtterMenu
            otterStoreId={otterStoreId}
            menuName={menuName}
            venueId={venueId}
            brandId={brandId}
            isVenueAssociation
          />
          <Button
            disabled={loading || !deliveryPlatforms || deliveryPlatforms.length === 0}
            variant="contained"
            color="primary"
            component="label"
            id={otterStoreId}
            onClick={handlePublishClick}
            fullWidth
            style={{ marginTop: '.5em' }}
            startIcon={
              loading ? <CircularProgress size={20} color="inherit" /> : <DriveFolderUploadIcon />
            }
          >
            Publish All
          </Button>
          {/* Hide whilst we determine if we want to support this */}
          {/* {deliveryPlatforms &&
            deliveryPlatforms.length > 0 &&
            deliveryPlatforms.map((target) => (
              <Button
                key={target.name}
                variant="contained"
                color="primary"
                onClick={(e) => {
                  handlePublishClick(e, target);
                  handleActionDropdownClose();
                }}
                style={{ width: '100%', marginTop: '.5em' }}
              >
                {`Publish to ${target.displayName}`}
              </Button>
            ))} */}
        </Popover>
      )}
    </>
  );
};

export default VenueActions;
