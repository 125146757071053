import { del, get, patch, post } from './httpRequest';

export const fetchKiosksApi = async () => {
  const response = await get(`/kiosks`);

  return response.data;
};

export const fetchKioskOnboardingKeyApi = async () => {
  const response = await get(`/kiosks/onboarding/key`);

  return response.data;
};

export const updateKioskApi = async ({ data, kioskId }) => {
  const response = await patch(`/kiosks/${kioskId}`, {
    data,
  });

  return response.data;
};

export const deleteKioskApi = async ({ kioskId }) => {
  const response = await del(`/kiosk/${kioskId}`);

  return response.data;
};

export const createKioskReboot = async ({ kioskId }) => {
  await post(`/kiosks/${kioskId}/restart`);
};
