import React from 'react';
import { Formik, Form } from 'formik';
import { makeStyles } from '@mui/styles';
import { TextField, Box, Button } from '@mui/material';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  helperText: {
    color: theme.palette.error.main,
  },
}));

const AddNewManagerForm = ({ email, setEmail, setConfirmDialogOpen }) => {
  const classes = useStyles();

  const NewManagerSchema = Yup.object().shape({
    email: Yup.string().email('Email must be valid').required('This field is required'),
  });

  const handleOpenDialog = ({ email: managerEmail }) => {
    setEmail(managerEmail);
    setConfirmDialogOpen(true);
  };

  return (
    <>
      <Formik initialValues={{ email }} validationSchema={NewManagerSchema} enableReinitialize>
        {({ errors, touched, handleBlur, handleChange, isValid, values }) => (
          <Form>
            <Box display="flex" justifyContent="flex-end" alignItems="flex-start">
              <Box mr={2} m={-2}>
                <TextField
                  helperText={errors.email && touched.email && errors.email}
                  FormHelperTextProps={{ className: classes.helperText }}
                  name="email"
                  size="small"
                  label="Manager Email"
                  variant="outlined"
                  placeholder="manager@email.com"
                  required
                  margin="normal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                />
              </Box>
              <Box>
                <Button
                  disabled={!isValid}
                  variant="contained"
                  color="primary"
                  onClick={() => handleOpenDialog(values)}
                >
                  Add manager
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddNewManagerForm;
