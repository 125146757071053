import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Box, Button, Collapse, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Fuse from 'fuse.js';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { Checkbox } from 'formik-mui';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { pick } from 'lodash';
import CustomDialog from '../CustomDialog';
import getCategoryGroups from '../../shared/utils/getCategoryGroups';
import useItemsOnMenu from '../../hooks/useItemsOnMenu';

const useStyles = makeStyles((theme) => ({
  button: {
    color: 'green',
  },
  container: {
    marginLeft: '22px',
  },
  category: {
    ...theme.customFonts.mediumBold,
    color: theme.customPalette.greyDarker,
  },
  item: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  groupCheckBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '2rem',
  },
  menuItems: {
    marginLeft: '33px',
  },
  searchBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '16px',
    marginTop: 0,
    marginBottom: 0,
  },
  itemCategories: {
    ...theme.customFonts.mediumBold,
    color: theme.customPalette.greyDarker,
  },
  disabledItem: {
    textDecoration: 'line-through',
  },
  unavailable: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  unavailableIcon: {
    width: '16px',
    height: '16px',
  },
}));

const FormObserver = ({ setFieldValue, setFormData }) => {
  const { values, dirty } = useFormikContext();
  useEffect(() => {
    setFormData(values);
  }, [values, dirty, setFieldValue, setFormData]);
  return null;
};

// This finds the index of the item from Formik values (this is necessary to handle changing indexes during a search)
const findArrayIndex = (itemId, values) => values?.findIndex((item) => item?.itemId === itemId);

// This checks an individual item to be selected or deselected depending on its current state
const setCheckedValue = (index, values, setFieldValue) => {
  const menuItem = values[index];
  if (!menuItem.checked) {
    setFieldValue(index, { ...menuItem, checked: true });
  } else {
    const updatedMenuItem = { ...menuItem };
    delete updatedMenuItem.checked;
    setFieldValue(index, updatedMenuItem);
  }
};

const checkIfCategoryItemsChecked = (values, groupItems) => {
  const groupItemIds = new Set(groupItems.map((groupItem) => groupItem.itemId));
  const groupedValues = values.filter((value) => groupItemIds.has(value.itemId));
  return groupedValues.every((item) => item.checked);
};

// This is to support a user being able to select all or deselect all items in a category
const changeAllCategoryCheckedValues = ({ values, groupItems, setFieldValue }) => {
  const result = checkIfCategoryItemsChecked(values, groupItems);
  const groupItemIds = groupItems.map((groupItem) => groupItem.itemId);
  const groupedValues = values.filter((value) => groupItemIds.includes(value.itemId));
  groupedValues.forEach((categoryItem) => {
    const index = findArrayIndex(categoryItem.itemId, values);
    if (!result) {
      setFieldValue(index, { ...categoryItem, checked: true });
    } else {
      const updatedCategoryItem = { ...categoryItem };
      delete updatedCategoryItem.checked;
      setFieldValue(index, updatedCategoryItem);
    }
  });
};

// This will check if an item is selected or not (necessary for Formik Field: checked={}')
const checkIfItemChecked = (index, values) => {
  const item = values[index];
  return Boolean(item?.checked);
};

const AddGroupItems = ({
  menuItems,
  isDialogOpen,
  handleCloseDialog,
  handleOnSave,
  checkedItems,
  title,
}) => {
  const classes = useStyles();
  const [noResults, setNoResults] = useState();
  const [searchError, setSearchError] = useState();
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [formData, setFormData] = useState();
  const [filteredSearch, setFilteredSearch] = useState(getCategoryGroups(menuItems));
  const { checkIfItemIsOnMenu } = useItemsOnMenu();

  const initialValues = useMemo(
    () =>
      menuItems?.map((menuItem) => {
        const isItemChecked = checkedItems?.some((checkedItem) => checkedItem === menuItem.itemId);
        const pickedMenuItem = pick(menuItem, ['itemId', 'itemName']);
        return isItemChecked ? { ...pickedMenuItem, checked: true } : pickedMenuItem;
      }),
    [menuItems, checkedItems],
  );

  const handleExpandClick = useCallback(
    (group) => {
      setExpandedGroup(expandedGroup === group ? null : group);
    },
    [expandedGroup, setExpandedGroup],
  );

  const handleSearch = useCallback(
    (e) => {
      if (!menuItems) return;
      if (e.target.value.length >= 3) {
        setSearchError('');
        const searchData = menuItems;
        const fuse = new Fuse(searchData, {
          threshold: 0.3,
          keys: [
            { name: 'category', weight: 0.7 },
            { name: 'itemName', weight: 0.3 },
          ],
        });
        const result = fuse.search(e.target.value);
        const data = result.map((item) => item.item);
        const filtered = getCategoryGroups(data);
        setFilteredSearch(filtered);
        setNoResults(false);
        if (filtered.length === 0) {
          setNoResults(true);
        }
      } else {
        setNoResults(false);
        setSearchError('Please enter at least 3 characters');
        const searchData = getCategoryGroups(menuItems);
        setFilteredSearch(searchData);
      }
    },
    [menuItems],
  );

  useEffect(() => {
    if (isDialogOpen) {
      setFilteredSearch(getCategoryGroups(menuItems));
    }
  }, [isDialogOpen, menuItems]);

  return (
    <CustomDialog
      handleCloseDialog={handleCloseDialog}
      isDialogOpen={isDialogOpen}
      title={title || 'Add items'}
      actions={
        <>
          <Button
            className={classes.dialogButton}
            variant="outlined"
            onClick={() => handleCloseDialog()}
          >
            Cancel
          </Button>
          <Button onClick={() => handleOnSave(formData)} color="primary" variant="contained">
            Save
          </Button>
        </>
      }
    >
      <Box className={classes.searchBar}>
        <TextField
          helperText={searchError}
          className={classes.searchBar}
          id="outlined-basic"
          label="Search Items"
          variant="outlined"
          onChange={handleSearch}
          size="small"
          fullWidth
        />
      </Box>
      <Box className={classes.container}>
        <Formik initialValues={initialValues} enableReinitialize>
          {({ values, setFieldValue }) => (
            <Form>
              <FormObserver
                setFieldValue={setFieldValue}
                setFormData={setFormData}
                values={values}
              />
              {menuItems &&
                filteredSearch &&
                filteredSearch.map((group) => (
                  <Box key={group.category} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Box className={classes.groupCheckBox}>
                      {expandedGroup === group ? (
                        <ExpandLess
                          key={`${group.category}.expandLess`}
                          onClick={() => handleExpandClick(group)}
                        />
                      ) : (
                        <ExpandMore
                          key={`${group.category}.expandMore`}
                          onClick={() => handleExpandClick(group)}
                        />
                      )}
                      <Field
                        id={`checkBox.${group.category}`}
                        key={`checkBox.${group.category}`}
                        component={Checkbox}
                        color="primary"
                        type="checkbox"
                        checked={checkIfCategoryItemsChecked(values, group.items)}
                        onChange={() => {
                          // This selects or unselects all items in a category
                          changeAllCategoryCheckedValues({
                            values,
                            groupItems: group.items,
                            setFieldValue,
                          });
                        }}
                      />
                      <span className={classes.itemCategories} key={`${group.category}.category`}>
                        {group.category}
                      </span>
                    </Box>
                    <Collapse
                      className={classes.menuItems}
                      in={expandedGroup === group}
                      timeout="auto"
                      key={`collapse.${group.category}`}
                    >
                      {group.items.map(({ itemId, itemName, available }) => {
                        const index = findArrayIndex(itemId, values);
                        return (
                          <div className={classes.groupCheckBox} key={itemId}>
                            <Field
                              key={`${itemName}.${index}`}
                              id={`values[${index}]`}
                              component={Checkbox}
                              color="primary"
                              type="checkbox"
                              checked={checkIfItemChecked(index, values)}
                              onChange={() => {
                                setCheckedValue(index, values, setFieldValue);
                              }}
                            />
                            {checkIfItemIsOnMenu(itemId) && available && (
                              <span key={`${group.category}.itemName`} className={classes.item}>
                                {itemName}
                              </span>
                            )}
                            {!checkIfItemIsOnMenu(itemId) && (
                              <div className={classes.item}>
                                <span style={{ textDecoration: 'line-through' }}>{itemName}</span>
                                <div style={{ textAlign: 'right' }}>
                                  <img
                                    className={classes.unavailableIcon}
                                    src="/img/visibility/not-on-menu.svg"
                                    alt="Not on menu"
                                    title="Item is not on active menu"
                                  />{' '}
                                  <span style={{ textAlign: 'right' }}>Not on menu</span>
                                </div>
                              </div>
                            )}
                            {checkIfItemIsOnMenu(itemId) && !available && (
                              <div className={classes.item}>
                                <span style={{ textDecoration: 'line-through' }}>{itemName}</span>
                                <div style={{ textAlign: 'right' }}>
                                  <img
                                    className={classes.unavailableIcon}
                                    src="/img/visibility/unavailable.svg"
                                    alt="unavailable"
                                    title="Item is unavailable"
                                  />{' '}
                                  <span style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                                    Unavailable
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </Collapse>
                  </Box>
                ))}
              {noResults && <Typography>No results found</Typography>}
            </Form>
          )}
        </Formik>
      </Box>
    </CustomDialog>
  );
};

export default AddGroupItems;
