const getChangedValues = (values, initialValues) =>
  Object.entries(values).reduce((acc, [key, value]) => {
    const hasChanged = initialValues[key] !== value;
    const returnAcc = { ...acc };
    if (hasChanged) {
      returnAcc[key] = value;
    }

    return returnAcc;
  }, {});
export default getChangedValues;
