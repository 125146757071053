import { createSelector, createSlice } from '@reduxjs/toolkit';
import { REPORTING_TYPES, UNITS } from '../../shared/constants/units';
import { rangeEnums, dateRanges } from '../../shared/utils/dateRanges';
import { sortOrderEnums } from '../../shared/utils/sortOrder';

const unitsObj = {
  [rangeEnums.TODAY]: UNITS.HOURS,
  [rangeEnums.YESTERDAY]: UNITS.HOURS,
  [rangeEnums.LAST_7_DAYS]: UNITS.DAYS,
  [rangeEnums.LAST_FULL_WEEK]: UNITS.DAYS,
  [rangeEnums.LAST_4_WEEKS]: UNITS.DAYS,
  [rangeEnums.LAST_3_MONTHS]: UNITS.WEEKS,
  [rangeEnums.LAST_12_MONTHS]: UNITS.MONTHS,
  [rangeEnums.MONTH_TO_DATE]: UNITS.DAYS,
  [rangeEnums.QUARTER_TO_DATE]: UNITS.WEEKS,
  [rangeEnums.YEAR_TO_DATE]: UNITS.MONTHS,
};

// TODO: temporary default values while in development. should be today and hours.
const defaultRangeName = localStorage.getItem('date_range') || rangeEnums.LAST_7_DAYS;
const defaultRange = dateRanges[defaultRangeName];
const defaultUnit = unitsObj[localStorage.getItem('date_range')] || UNITS.DAYS;
const customFromDate = localStorage.getItem('from_date') || defaultRange.from();
const defaultFromDate =
  defaultRangeName === rangeEnums.CUSTOM ? customFromDate : defaultRange.from();
const customToDate = localStorage.getItem('to_date') || defaultRange.to();
const defaultToDate = defaultRangeName === rangeEnums.CUSTOM ? customToDate : defaultRange.to();
const defaultReportingType =
  localStorage.getItem('reporting_type') || REPORTING_TYPES.TRANSACTIONAL;
const defaultIsDelivery = 'true';

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    searchTerm: '',
    accountId: '',
    dateRange: defaultRangeName,
    fromDate: defaultFromDate,
    toDate: defaultToDate,
    sortOrder: sortOrderEnums.DESC,
    units: defaultUnit,
    reportingType: defaultReportingType,
    isDelivery: defaultIsDelivery,
  },
  reducers: {
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload;
      localStorage.setItem('date_range', action.payload);
      if (action.payload !== rangeEnums.CUSTOM) {
        state.units = unitsObj[action.payload];
      }
    },
    setFromDate: (state, action) => {
      state.fromDate = action.payload;
      localStorage.setItem('from_date', action.payload);
    },
    setToDate: (state, action) => {
      state.toDate = action.payload;
      localStorage.setItem('to_date', action.payload);
    },
    setSortOrder: (state, action) => {
      state.sortOrder = action.payload;
    },
    setUnits: (state, action) => {
      state.units = action.payload;
    },
    setAccountId: (state, action) => {
      state.accountId = action.payload;
    },
    setReportingType: (state, action) => {
      state.reportingType = action.payload;
      localStorage.setItem('reporting_type', action.payload);
    },
    setIsDelivery: (state, action) => {
      state.isDelivery = action.payload;
    },
  },
});

export const { reducer: search } = searchSlice;

export const {
  setSearchTerm,
  setDateRange,
  setFromDate,
  setToDate,
  setSortOrder,
  setUnits,
  setAccountId,
  setReportingType,
  setIsDelivery,
} = searchSlice.actions;

export const getSearchState = (state) => state.search;
export const getSearch = createSelector(getSearchState, (searchState) => searchState.data);
