import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { move, without } from 'ramda';

import { makeStyles } from '@mui/styles';
import {
  Button,
  IconButton,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { FiPlus } from 'react-icons/fi';
import { TiDelete } from 'react-icons/ti';

import { DragDropContext } from 'react-beautiful-dnd';

import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../shared/utils/errors';
import { updateBrandsMenuItem } from '../../../store/brands/brandsMenuItems';
import { getBrandsAttributeValues } from '../../../store/brands/brandsMenuItems/selectors';
import { getModifiersObj } from '../../../store/brands/brandsModifiers/selectors';

import Droppable from '../../DragAndDrop/Droppable';
import Draggable from '../../DragAndDrop/Draggable';

const useStyles = makeStyles(() => ({
  heading: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  description: {
    marginTop: '10px',
  },
  list: {
    marginTop: '10px',
    borderBottom: '1px solid #F0F4F8',
  },
  listItem: {
    paddingLeft: 0,
    borderTop: '1px solid #F0F4F8',
  },
  listItemButton: {
    paddingLeft: '35px',
  },
  dragIcon: {
    top: 0,
    bottom: 0,
    margin: 'auto',
  },
}));

const BrandsModifiersList = ({ menuItemId, readonly }) => {
  const attribute = 'modifiers';
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showErrorNotification, showSuccessNotification } = useNotifications();

  const modifierValues = useSelector((state) => getBrandsAttributeValues(state, attribute));
  const modifiers = useSelector(getModifiersObj);

  const update = async (values) => {
    try {
      await dispatch(updateBrandsMenuItem({ menuItemId, values: { [attribute]: values } }));
      showSuccessNotification('Item has been successfully updated');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const handleDelete = (value) => () => {
    update(without([value], modifierValues));
  };

  const onDragEnd = async ({ source, destination }) => {
    if (!destination) {
      return;
    }
    update(move(source.index, destination.index, modifierValues));
  };

  return (
    <article>
      <header className={classes.heading}>
        <Typography className={classes.title} variant="h4">
          Product Modifiers
        </Typography>
        <aside>
          <Button
            variant="contained"
            color="primary"
            startIcon={<FiPlus />}
            component={Link}
            to={`/brand-menu-management/item/${menuItemId}/modifier-attributes`}
            disabled={readonly}
          >
            Add
          </Button>
        </aside>
      </header>
      {!!modifierValues?.length && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="attributes"
            type="droppable_attributes"
            isDropDisabled={modifierValues.length < 2}
          >
            <List className={classes.list}>
              {modifierValues?.map(
                (value, index) =>
                  modifiers?.[value] && (
                    <Draggable
                      className={classes.listItem}
                      key={value}
                      draggableId={`attribute${value}`}
                      index={index}
                      isDragDisabled={readonly || modifierValues.length < 2}
                      dragIconClassName={classes.dragIcon}
                    >
                      <ListItemButton
                        className={classes.listItemButton}
                        component={Link}
                        to={`/brand-menu-management/items/modifiers/${value}`}
                        key={value}
                        disabled={readonly}
                      >
                        <ListItemText
                          primary={modifiers?.[value].modifierName}
                          secondary={modifiers[value].label || modifiers[value].ingredientName}
                          secondaryTypographyProps={{ variant: 'caption' }}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={handleDelete(value)}
                            disabled={readonly}
                            size="large"
                          >
                            <TiDelete style={{ fontSize: 20 }} />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                    </Draggable>
                  ),
              )}
            </List>
          </Droppable>
        </DragDropContext>
      )}
      <Typography className={classes.description} variant="h4">
        e.g. list of optional pizza toppings or a list of mixers
      </Typography>
    </article>
  );
};

BrandsModifiersList.propTypes = {
  menuItemId: PropTypes.string.isRequired,
};

export default BrandsModifiersList;
