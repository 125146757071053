import { makeStyles } from '@mui/styles';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  discountCard: {
    margin: '15px 0',
    fontSize: '0.875em',
    '& p': {
      margin: '0.5rem',
    },
  },
  column: {
    flexBasis: '50%',
  },
  button: {
    margin: '1.5rem 0',
  },
  itemCategory: {
    color: '#647F99',
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  itemRight: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  smallButton: {
    padding: '5px',
    minWidth: '39px',
  },
}));
export default function DiscountItem({ children, item, hasIcon, subtitleText }) {
  const classes = useStyles();
  const { itemName, category, itemId, itemPrices } = item || {};
  return (
    <div key={itemId} className={classes.item}>
      <div>
        {itemName}
        <br />
        <span className={classes.itemCategory}>
          {hasIcon && <LocalOfferIcon style={{ fontSize: '1rem', marginRight: '0.5rem' }} />}
          {subtitleText || category}
        </span>
      </div>
      <div className={classes.itemRight}>
        {itemPrices && <div>£{Number(itemPrices[0]).toFixed(2)}</div>}
        {children}
      </div>
    </div>
  );
}
