import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Card, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import { createManager, fetchManagers, updateManager } from '../../../store/managers';
import ManagerFormDialog from './ManagerFormDialog';
import useRoles from '../../../hooks/useRoles';
import ROLES from '../../../shared/constants/roles';
import { getRolesFromToken, getUserName } from '../../../store/session/selectors';
import DISPLAYROLES from '../../../shared/constants/displayRoles';
import KebabMenu from '../../../components/KebabMenu';

const useStyles = makeStyles((theme) => ({
  heading: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tableTitle: {
    ...theme.customFonts.mediumBold,
    color: theme.customPalette.greyDarker,
    marginBottom: '8px',
  },
  tableheader: {
    '& td': {
      padding: '8px 16px 8px 16px',
    },
    '& td:first-child': {
      paddingLeft: '24px',
      paddingRight: 0,
    },
  },
  tablerow: {
    '& td': {
      padding: '24px 16px 24px 16px',
    },
    '& td:first-child': {
      paddingLeft: '16px',
      paddingRight: 0,
    },
  },
  columnTitle: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDark,
  },
  rowName: {
    ...theme.customFonts.smallBold,
    color: theme.customPalette.greyDarker,
  },
  role: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
  },
  userId: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDark,
  },

  newManagerCard: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderRadius: '8px',
  },
  tabPanel: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  tableCard: {
    borderRadius: '8px',
    marginBottom: '24px',
  },
  truncate: {
    width: '155px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const displayRole = (isUpCoSuper, isUpCoAdmin, role) => {
  if (isUpCoSuper) {
    return 'Sessions Super Admin';
  }
  if (isUpCoAdmin) {
    return 'Sessions Admin';
  }
  return role;
};

const ManagersTableTeam = ({ managers }) => {
  const dispatch = useDispatch();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const classes = useStyles();
  const { isRoleLessthanMe } = useRoles();
  const [addManagerDialogOpen, setAddManagerDialogOpen] = useState(false);
  const [newManagerEmail, setNewManagerEmail] = useState('');
  const [newManagerCountryCode, setNewManagerCountryCode] = useState('+44');
  const [newManagerPhoneNumber, setNewManagerPhoneNumber] = useState('');
  const [newManagerName, setNewManagerName] = useState(undefined);
  const [newManagerRole, setNewManagerRole] = useState(ROLES.STAFF);
  const [enabledManagers, setEnabledManagers] = useState([]);
  const [disabledManagers, setDisabledManager] = useState([]);
  const currentUserName = useSelector(getUserName);
  const currentUser = managers?.find((manager) => manager.userId === currentUserName);

  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up('sm'));

  const isEdit = useCallback(
    () => newManagerEmail && newManagerEmail.length > 0,
    [newManagerEmail],
  );

  const handleCloseAddNewManagerDialog = () => {
    setAddManagerDialogOpen(false);
  };

  const handleEditManager = ({ userId, name, role, countryCode, phone }) => {
    setNewManagerEmail(userId);
    setNewManagerName(name);
    setNewManagerRole(role);
    setNewManagerCountryCode(countryCode || '+44');
    setNewManagerPhoneNumber(phone || '');
    setAddManagerDialogOpen(true);
  };

  const submitUpdateManager = async (values) => {
    handleCloseAddNewManagerDialog();
    const { userId, email, role, enabled, name, phone, countryCode } = values;
    try {
      await dispatch(
        updateManager({ userId: userId || email, role, enabled, name, phone, countryCode }),
      );
      showSuccessNotification('Manager updated successfully');
      dispatch(fetchManagers());
    } catch (newError) {
      showErrorNotification('Manager could not be updated');
    }
  };

  const submitAddNewManagerDialog = async ({ email, role, name, phone, countryCode }) => {
    handleCloseAddNewManagerDialog();

    try {
      await dispatch(createManager({ email, role, name, phone, countryCode }));
      setNewManagerEmail('');
      showSuccessNotification('New manager added successfully');
      dispatch(fetchManagers());
    } catch (newError) {
      showErrorNotification('New manager could not be created');
    }
  };

  useEffect(() => {
    setEnabledManagers(managers?.filter((manager) => manager.enabled === true));
    setDisabledManager(managers?.filter((manager) => manager.enabled === false));
  }, [managers]);

  if (!managers) return null;
  return (
    <>
      <header className={classes.heading}>
        <aside>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleEditManager({ userId: undefined, name: undefined, role: ROLES.STAFF });
            }}
          >
            Invite
          </Button>
        </aside>
      </header>
      <Typography className={classes.tableTitle}>Enabled users</Typography>
      <Card className={classes.tableCard}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow className={classes.tableheader}>
                <TableCell
                  component="td"
                  className={classes.columnTitle}
                  width={(isMedium && '75%') || null}
                >
                  Name
                </TableCell>
                <TableCell component="td" className={classes.columnTitle}>
                  Role
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {enabledManagers?.map((manager) => {
                const cognitoRoles = getRolesFromToken(manager.dashboardIdToken);
                const isUpCoSuper = cognitoRoles.includes(ROLES.SUPERADMIN);
                const isUpCoAdmin = cognitoRoles.includes(ROLES.ADMIN);
                const { userId, role, name = 'No name', countryCode, phone } = manager;
                const noTrailingZeroPhone = phone && phone.replace(/^0+/, '');
                const displayPhone = `${countryCode}${noTrailingZeroPhone}`;
                const displayName = `${name} ${currentUserName === userId ? '(You)' : ''}`;
                return (
                  <TableRow key={userId} className={classes.tablerow}>
                    <TableCell component="td" scope="row" width={(isMedium && '80%') || null}>
                      {displayName.length > 0 && (
                        <div className={`${classes.rowName} ${!isMedium && classes.truncate}`}>
                          <strong>{displayName}</strong>
                          <br />
                        </div>
                      )}
                      <div className={`${classes.userId} ${!isMedium && classes.truncate}`}>
                        {userId}
                      </div>
                      {countryCode && phone && (
                        <div className={`${classes.userId} ${!isMedium && classes.truncate}`}>
                          {displayPhone}
                        </div>
                      )}
                    </TableCell>
                    <TableCell className={classes.role} width={(isMedium && '80%') || null}>
                      {displayRole(isUpCoSuper, isUpCoAdmin, DISPLAYROLES[role])}
                    </TableCell>
                    <TableCell align="right">
                      <Box display="flex" justifyContent="flex-end" mr={1}>
                        {(isRoleLessthanMe(role) || currentUserName === userId) && (
                          <KebabMenu
                            items={[
                              {
                                name: `Edit${currentUserName === userId ? ' my' : ''} info`,
                                handler: () => {
                                  handleEditManager(manager);
                                },
                              },
                              ...[
                                currentUserName !== userId && {
                                  name: `Disable`,
                                  handler: () => {
                                    submitUpdateManager({ ...manager, enabled: false });
                                  },
                                },
                              ],
                            ]}
                          />
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      {disabledManagers.length !== 0 && (
        <>
          <Typography className={classes.tableTitle}>Disabled users</Typography>
          <Card className={classes.tableCard}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow className={classes.tableheader}>
                    <TableCell
                      component="td"
                      className={classes.columnTitle}
                      width={(isMedium && '75%') || null}
                    >
                      Name
                    </TableCell>
                    <TableCell component="td" className={classes.columnTitle}>
                      Role
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {disabledManagers.map((manager) => {
                    const cognitoRoles = getRolesFromToken(manager.dashboardIdToken);
                    const isUpCoSuper = cognitoRoles.includes(ROLES.SUPERADMIN);
                    const isUpCoAdmin = cognitoRoles.includes(ROLES.ADMIN);
                    const { userId, role, name = 'No name', phone, countryCode } = manager;
                    const noTrailingZeroPhone = phone && phone.replace(/^0+/, '');
                    const displayPhone = `${countryCode}${noTrailingZeroPhone}`;
                    const displayName = `${name} ${currentUserName === userId ? '(You)' : ''}`;
                    return (
                      <TableRow key={userId} className={classes.tablerow}>
                        <TableCell component="td" scope="row" width={(isMedium && '80%') || null}>
                          {displayName.length > 0 && (
                            <div className={`${classes.rowName} ${!isMedium && classes.truncate}`}>
                              <strong>{displayName}</strong>
                              <br />
                            </div>
                          )}
                          <div className={`${classes.userId} ${!isMedium && classes.truncate}`}>
                            {userId}
                          </div>
                          {countryCode && phone && (
                            <div className={`${classes.userId} ${!isMedium && classes.truncate}`}>
                              {displayPhone}
                            </div>
                          )}
                        </TableCell>
                        <TableCell className={classes.role} width={(isMedium && '80%') || null}>
                          {displayRole(isUpCoSuper, isUpCoAdmin, DISPLAYROLES[role])}
                        </TableCell>
                        <TableCell align="right">
                          <Box display="flex" justifyContent="flex-end" mr={1}>
                            {(isRoleLessthanMe(role) || currentUserName === userId) && (
                              <KebabMenu
                                items={[
                                  {
                                    name: `Edit${currentUserName === userId ? ' my' : ''} info`,
                                    handler: () => {
                                      handleEditManager(manager);
                                    },
                                  },
                                  ...[
                                    currentUserName !== userId && {
                                      name: `Enable`,
                                      handler: () => {
                                        submitUpdateManager({ ...manager, enabled: true });
                                      },
                                    },
                                  ],
                                ]}
                              />
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </>
      )}
      <ManagerFormDialog
        action={isEdit() ? 'update' : 'create'}
        email={newManagerEmail}
        name={newManagerName}
        role={newManagerRole}
        countryCode={newManagerCountryCode}
        phone={newManagerPhoneNumber}
        currentUser={currentUser}
        submitDialog={isEdit() ? submitUpdateManager : submitAddNewManagerDialog}
        handleDialogOpen={addManagerDialogOpen}
        handleCloseDialog={() => setAddManagerDialogOpen(false)}
      />
    </>
  );
};

export default ManagersTableTeam;
