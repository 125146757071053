import React from 'react';
import { useSelector } from 'react-redux';
import { List } from '@mui/material';
import { makeStyles } from '@mui/styles';
import withVenue from '../../../hoc/withVenue';
import SettingsListItem from '../../../components/SettingsListItem';
import Page from '../../../components/Page';
import useEndpoint from '../../../hooks/useEndpoint';
import { fetchDeliveryStatements } from '../../../store/deliveryStatements';
import { getPlatformInvoices } from '../../../store/platformInvoices/selectors';
import { getDeliveryStatements } from '../../../store/deliveryStatements/selectors';
import { fetchPlatformInvoices } from '../../../store/platformInvoices';
import { isPro } from '../../../store/venues/selectors';
import useRoles from '../../../hooks/useRoles';

const useStyles = makeStyles(() => ({
  list: {
    backgroundColor: 'white',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const Business = () => {
  const classes = useStyles();
  const { data: invoiceData } = useEndpoint(getPlatformInvoices, fetchPlatformInvoices());
  const { data: statementsData } = useEndpoint(getDeliveryStatements, fetchDeliveryStatements());
  const hasInvoices = invoiceData && invoiceData?.length > 0;
  const hasStatements = statementsData && statementsData?.length > 0;
  const { isRoleAtleastOwner } = useRoles();
  const isProAndOwner = useSelector(isPro) && isRoleAtleastOwner();

  return (
    <Page>
      <List className={classes.list}>
        <SettingsListItem
          title="Business details"
          subtitle="Address &amp; VAT number used to generate VAT receipts"
          to="/settings/business/business-details"
          divider
        />
        <SettingsListItem
          title="Payment details"
          subtitle="Your business bank account"
          to={
            isProAndOwner
              ? '/settings/business/payment-details'
              : '/settings/business/payment-details-old'
          }
          divider
        />
        <SettingsListItem
          title="Report emails"
          subtitle="Manage who receives daily email reports"
          to="/settings/business/report-emails"
          divider={hasInvoices}
        />
        {hasInvoices && (
          <SettingsListItem
            title="Platform Invoices"
            subtitle="View VAT invoices for your platform fees"
            to="/settings/business/platform-invoices"
            divider={hasStatements}
          />
        )}
        {hasStatements && (
          <SettingsListItem
            title="Sessions delivery proceeds reports"
            subtitle="View your financial reports from operating Sessions' brands"
            to="/settings/business/delivery-statements"
          />
        )}
      </List>
    </Page>
  );
};
export default withVenue(Business, '/settings/business');
