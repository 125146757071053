import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import * as Yup from 'yup';

import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';
import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  TableHead,
  FormControlLabel,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { Field, Form, Formik } from 'formik';
import { Switch } from 'formik-mui';
import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';
import { getVoucherState } from '../../store/vouchers/selectors';
import { clearVoucher, fetchVoucher, updateVoucher } from '../../store/vouchers';
import decideVoucherStatus from './decideVoucherStatus';
import VoucherStatuses from './VoucherStatuses';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import TableSkeletonLoader from '../../components/TableSkeletonLoader';
import TableLink from '../../components/TableLink';
import shouldLoad from '../../shared/utils/shouldLoad';
import UniversalSave from '../../components/UniversalSave';
import BackArrow from '../../components/BackArrow';

const useStyles = makeStyles((theme) => ({
  heading: {
    margin: '15px 0',
  },
  card: {
    marginBottom: '20px',
  },
  button: {
    marginRight: theme.spacing(),
  },
  refundForm: {
    justifyContent: 'flex-end',
  },
  datePicker: {
    margin: 0,
    '& input': {
      textAlign: 'right',
    },
  },
}));

const Voucher = ({ redirect }) => {
  const { voucherCode } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const voucherState = useSelector(getVoucherState);
  const { loading, data: voucher, error } = voucherState;
  const { showErrorNotification, showSuccessNotification } = useNotifications();

  const {
    endDate,
    orderId,
    email,
    phone,
    name,
    enabled,
    balance,
    updatedAt,
    startDate,
    amount,
    orders,
    description,
    venueId: voucherVenueId,
  } = voucher || {};

  const initialValues = {
    enabled,
    endDate,
    startDate,
  };

  const hasOrders = orders && orders.length > 0;

  const status = decideVoucherStatus(balance, enabled, endDate);

  const VoucherSchema = Yup.object().shape({
    voucher: Yup.object().shape({
      enabled: Yup.boolean(),
      startDate: Yup.date(),
      endDate: Yup.date(),
    }),
  });

  const onSubmit = useCallback(
    async (values) => {
      try {
        await dispatch(updateVoucher(voucherCode, values));
        showSuccessNotification(`Voucher has been updated`);
        dispatch(fetchVoucher(voucherCode));
      } catch (err) {
        showErrorNotification(getErrorMessage(err));
        dispatch(fetchVoucher(voucherCode));
      }
    },
    [dispatch, showErrorNotification, showSuccessNotification, voucherCode],
  );

  useEffect(() => {
    if (shouldLoad(voucherState)) dispatch(fetchVoucher(voucherCode));
  }, [voucherState, dispatch, voucherCode]);

  return (
    <Page loading={loading} error={error}>
      <header>
        <BackArrow redirect={redirect} text="Vouchers" />
        <Typography className={classes.heading} variant="h2" component="h1">
          Voucher: {voucherCode}
        </Typography>
      </header>
      {voucher && (
        <Formik onSubmit={onSubmit} validationSchema={VoucherSchema} initialValues={initialValues}>
          {({ setFieldValue, setFieldTouched, dirty, isValid, errors, resetForm, values }) => (
            <>
              <UniversalSave
                isValid={isValid}
                errors={errors}
                dirty={dirty}
                onSave={() => onSubmit(values)}
                onDiscard={resetForm}
              />
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TableContainer component={Card} className={classes.card}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>Voucher Code:</TableCell>
                            <TableCell align="right">{voucherCode || 'N/A'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Status:</TableCell>
                            <TableCell align="right">{VoucherStatuses[status]}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Enabled:</TableCell>
                            <TableCell align="right">
                              <FormControlLabel
                                control={
                                  <Field
                                    name="enabled"
                                    component={Switch}
                                    type="checkbox"
                                    color="primary"
                                  />
                                }
                                label="Enabled"
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Order ID:</TableCell>
                            <TableCell align="right">
                              <TableLink
                                to={{
                                  pathname: `/orders/${orderId}`,
                                }}
                              >
                                {orderId}
                              </TableLink>
                            </TableCell>
                          </TableRow>
                          {phone && (
                            <TableRow>
                              <TableCell>Phone:</TableCell>
                              <TableCell align="right">{phone}</TableCell>
                            </TableRow>
                          )}
                          <TableRow>
                            <TableCell>Amount:</TableCell>
                            <TableCell align="right">
                              £{amount.toFixed(2).toLocaleString()}
                            </TableCell>
                          </TableRow>

                          {description && (
                            <TableRow>
                              <TableCell>Description:</TableCell>
                              <TableCell align="right">{description}</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TableContainer component={Card} className={classes.card}>
                      <Table className={classes.table} aria-label="simple table">
                        <TableBody>
                          {name && (
                            <TableRow>
                              <TableCell>Name:</TableCell>
                              <TableCell align="right">{name}</TableCell>
                            </TableRow>
                          )}
                          <TableRow>
                            <TableCell>Start Date:</TableCell>
                            <TableCell align="right">
                              <Field
                                className={classes.datePicker}
                                component={DatePicker}
                                variant="dialog"
                                required
                                format="DD/MM/YYYY"
                                margin="normal"
                                value={moment.utc(values.startDate)}
                                timezone="system"
                                onChange={async (date) => {
                                  await setFieldValue('startDate', date);
                                  setFieldTouched('startDate', true);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>End Date:</TableCell>
                            <TableCell align="right">
                              <Field
                                className={classes.datePicker}
                                component={DatePicker}
                                variant="dialog"
                                required
                                format="DD/MM/YYYY"
                                margin="normal"
                                value={moment.utc(values.endDate)}
                                timezone="system"
                                onChange={async (date) => {
                                  await setFieldValue('endDate', date);
                                  setFieldTouched('endDate', true);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Updated at:</TableCell>
                            <TableCell align="right">
                              {moment(updatedAt).local().format('DD MMM yyyy HH:mm')}
                            </TableCell>
                          </TableRow>
                          {email && (
                            <TableRow>
                              <TableCell>Email:</TableCell>
                              <TableCell align="right">{email}</TableCell>
                            </TableRow>
                          )}
                          <TableRow>
                            <TableCell>Balance:</TableCell>
                            <TableCell align="right">
                              £{balance.toFixed(2).toLocaleString()}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid item xs={12}>
                    {hasOrders && (
                      <TableContainer component={Paper}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography variant="h3">Date</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h3">Time</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h3">Order ID</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h3">Multi Venue</Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="h3">Amount Used</Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="h3">Balance</Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="h3">Order Subtotal</Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="h3">Order Total</Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="h3">Total</Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {loading && <TableSkeletonLoader rows={10} cells={8} />}
                          {!loading && hasOrders && (
                            <>
                              <TableBody>
                                {orders.map((order) => {
                                  const {
                                    amountUsed,
                                    balance: orderBalance,
                                    updatedAt: orderUpdatedAt,
                                    orderSubtotal,
                                    orderTotal,
                                    orderId: orderOrderId,
                                    venueId: venueOrderId,
                                    venueName,
                                  } = order;
                                  return (
                                    <TableRow key={orderOrderId}>
                                      <TableCell>
                                        <Typography variant="body2">
                                          {moment(orderUpdatedAt).local().format('DD/MM')}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        {moment(orderUpdatedAt).local().format('HH:mm')}
                                      </TableCell>
                                      <TableCell>
                                        <TableLink
                                          to={{
                                            pathname: `/orders/${orderOrderId}`,
                                          }}
                                        >
                                          {orderOrderId}
                                        </TableLink>
                                      </TableCell>
                                      <TableCell align="right">
                                        {voucherVenueId !== venueOrderId ? venueName : 'NO'}
                                      </TableCell>
                                      <TableCell align="right">
                                        £{amountUsed?.toFixed(2).toLocaleString()}
                                      </TableCell>
                                      <TableCell align="right">
                                        £{orderBalance?.toFixed(2).toLocaleString()}
                                      </TableCell>
                                      <TableCell align="right">
                                        £{orderSubtotal?.toFixed(2).toLocaleString()}
                                      </TableCell>
                                      <TableCell align="right">
                                        £{orderTotal?.toFixed(2).toLocaleString()}
                                      </TableCell>
                                      <TableCell align="right">
                                        £{parseFloat(orderTotal).toFixed(2)}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </>
                          )}
                        </Table>
                      </TableContainer>
                    )}
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      )}
    </Page>
  );
};

export default withVenue(Voucher, '/vouchers', clearVoucher);
