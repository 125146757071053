import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';
import {
  IconButton,
  ListItemText,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Tooltip,
  ListItemButton,
  Box,
} from '@mui/material';

import { MdDelete } from 'react-icons/md';

import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';

import { getMenuItem } from '../../store/menuItems/selectors';
import { deleteMenuGroupItem, updateVenueMenuItems } from '../../store/venueMenuItems';

import InlineChip from '../InlineChip';
import useRoles from '../../hooks/useRoles';
import CustomDialog from '../CustomDialog';
import { fetchVenueMenus } from '../../store/venueMenus';
import MenuItemDialog from '../../pages/MenuItems/MenuItemDialog';

const useStyles = makeStyles((theme) => ({
  menuItemContainer: {
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    backgroundColor: 'white',
    paddingLeft: '40px',
  },
  menuItemLabel: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  brandItemSymbol: {
    width: 25,
    height: 25,
    marginLeft: theme.spacing(1),
  },
}));

const formatPrice = (price) =>
  `£${(typeof price === 'string' ? parseInt(price, 10) : price).toFixed(2)}`;

const MenuGroupItem = ({ groupId, itemId, itemIndex, isReadonly }) => {
  const classes = useStyles();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();
  const { isRoleAtLeastManager } = useRoles();
  const menuItem = useSelector((state) => getMenuItem(state, itemId));
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isMenuItemDialogOpen, setIsMenuItemDialogOpen] = useState(false);

  if (!menuItem) return null;
  const { itemName, itemPrices, available, hidden, readonly, brandName, brandId, label } = menuItem;

  const allowDeletion = brandId ? isRoleAtLeastManager() : !readonly && isRoleAtLeastManager();

  const handleDelete = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    setIsDialogOpen(false);
    try {
      dispatch(deleteMenuGroupItem({ groupId, itemIndex }));
      await dispatch(updateVenueMenuItems());
      await dispatch(fetchVenueMenus());
      showSuccessNotification('Menu Item has been removed');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <>
      <Box className={classes.menuItemContainer}>
        <ListItemButton className={classes.button} onClick={() => setIsMenuItemDialogOpen(true)}>
          <ListItemText
            primary={
              <>
                <span
                  style={{
                    textDecoration: !available ? 'line-through' : '',
                    marginRight: '0.5rem',
                  }}
                  className={classes.menuItemLabel}
                >
                  {itemName}
                  {brandName && ` | ${brandName}`}
                  {brandId && (
                    <img
                      src="/img/sessions_symbol.svg"
                      className={classes.brandItemSymbol}
                      alt="Sessions Brand Item"
                    />
                  )}
                </span>
                {!available && (
                  <InlineChip
                    label="Unavailable"
                    icon={
                      <img
                        style={{ filter: 'brightness(0) invert(1)' }}
                        src="/img/visibility/unavailable.svg"
                        alt="unavailable"
                        title="Item is unavailable"
                      />
                    }
                  />
                )}
              </>
            }
            secondary={
              <>
                {label && (
                  <>
                    <Typography variant="caption">{label}</Typography> <br />
                  </>
                )}
                <Typography variant="button">
                  {available &&
                    !hidden &&
                    itemPrices.length &&
                    itemPrices.map((price) => formatPrice(price)).join(', ')}
                </Typography>
              </>
            }
          />
        </ListItemButton>
        <Box>
          <Tooltip
            title={`Remove ${itemName} from menu`}
            aria-label={`Remove ${itemName} from menu`}
          >
            {allowDeletion ? (
              <IconButton edge="end" onClick={handleDelete} disabled={isReadonly} size="large">
                <MdDelete style={{ fontSize: 20 }} />
              </IconButton>
            ) : (
              <></>
            )}
          </Tooltip>
        </Box>
      </Box>
      <CustomDialog
        title="Are you sure?"
        isDialogOpen={isDialogOpen}
        handleCloseDialog={handleCloseDialog}
      >
        <DialogContent dividers>
          <Typography gutterBottom>
            Are you sure you want to remove {itemName} from the menu?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" autoFocus onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleConfirmDelete} color="primary">
            Remove
          </Button>
        </DialogActions>
      </CustomDialog>
      <MenuItemDialog
        handleOpen={() => setIsMenuItemDialogOpen(!isMenuItemDialogOpen)}
        isOpen={isMenuItemDialogOpen}
        itemId={itemId}
      />
    </>
  );
};

MenuGroupItem.propTypes = {
  groupId: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  itemIndex: PropTypes.number.isRequired,
};

export default MenuGroupItem;
