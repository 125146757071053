import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
  fetchPageAsyncReducerHandler,
} from '../utils/async';
import {
  fetchVouchers as fetchVouchersApi,
  fetchVoucher as fetchVoucherApi,
  updateVoucher as updateVoucherApi,
  createVoucher as createVoucherApi,
} from '../../api/vouchers';

export const fetchVouchers = createAsyncAction('fetch-vouchers', fetchVouchersApi);
export const fetchVouchersPage = createAsyncAction('fetch-vouchers-page', fetchVouchersApi);

export const fetchVoucher = createAsyncAction('fetch-voucher', fetchVoucherApi);
export const updateVoucher = createAsyncAction('update-voucher', updateVoucherApi);
export const createVoucher = createAsyncAction('create-voucher', createVoucherApi);

const vouchersSlice = createSlice({
  name: 'vouchers',
  initialState,
  reducers: {
    clearVouchers(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchVouchers),
    ...fetchPageAsyncReducerHandler(fetchVouchersPage),
  },
});

const voucherSlice = createSlice({
  name: 'voucher',
  initialState,
  reducers: {
    clearVoucher(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchVoucher),
    ...createAsyncReducerHandlers(updateVoucher),
    ...createAsyncReducerHandlers(createVoucher),
  },
});
export const { clearVouchers } = vouchersSlice.actions;
export const { clearVoucher } = voucherSlice.actions;

export const { reducer: vouchers } = vouchersSlice;
export const { reducer: voucher } = voucherSlice;
