import React from 'react';
import PropTypes from 'prop-types';

import { Card, CardActions, CardContent, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CustomDialog from '../CustomDialog';

const useStyles = makeStyles(() => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const PaidupVenueDialog = ({
  handleCloseDialog,
  handleDialogOpen,
  submitDialog,
  venueId,
  venueName,
  isPaidupEnabled,
}) => {
  const classes = useStyles();

  return (
    <CustomDialog
      title="Are you sure?"
      isDialogOpen={handleDialogOpen}
      handleCloseDialog={handleCloseDialog}
    >
      <Card className={classes.card}>
        <CardContent>
          You are going to
          {isPaidupEnabled === true ? ' DISABLE ' : ' ENABLE '}
          paidup for <b>{venueName}</b>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={() => submitDialog(venueId, !isPaidupEnabled)}
            variant="contained"
            color="primary"
          >
            {isPaidupEnabled === true ? 'Disable Paidup' : 'Enable Paidup'}
          </Button>
        </CardActions>
      </Card>
    </CustomDialog>
  );
};

PaidupVenueDialog.propTypes = {
  handleCloseDialog: PropTypes.func.isRequired,
  handleDialogOpen: PropTypes.bool.isRequired,
  submitDialog: PropTypes.func.isRequired,
  venueId: PropTypes.string.isRequired,
  isPaidupEnabled: PropTypes.bool.isRequired,
  venueName: PropTypes.string.isRequired,
};

export default PaidupVenueDialog;
