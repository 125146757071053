import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import {
  dateRanges,
  dateRangesPrevious,
  getPreviousPeriod,
  rangeEnums,
} from '../shared/utils/dateRanges';

import {
  getSearchState,
  setSearchTerm,
  setDateRange,
  setSortOrder,
  setFromDate,
  setToDate,
  setUnits,
  setAccountId,
  setReportingType,
  setIsDelivery,
} from '../store/search';
import formatDisplayDates from '../shared/utils/formatDisplayDates';
import { REPORTING_TYPES } from '../shared/constants/units';
import isMidnightFrom from '../shared/utils/isMidnightFrom';
import isMidnightTo from '../shared/utils/isMidnightTo';

const useESPicker = (config) => {
  const {
    useDate = true,
    useOrder = true,
    useSearch = true,
    isLocal = false,
    useUnits = false,
    useOptional = false,
    useReportingType = true,
    useIsDelivery = false,
    accounts,
  } = config || {};
  const dispatch = useDispatch();
  const [localDateRange, setLocalDateRange] = useState(rangeEnums.LAST_4_WEEKS);
  const [localFromDate, setLocalFromDate] = useState(dateRanges.YESTERDAY);
  const [localToDate, setLocalToDate] = useState(dateRanges.TODAY);
  const [active, setActive] = useState(!useOptional);
  const [isDeliveryActive, setIsDeliveryActive] = useState(useIsDelivery);
  const {
    searchTerm,
    dateRange,
    sortOrder,
    fromDate,
    toDate,
    units,
    accountId,
    reportingType,
    isDelivery,
  } = useSelector(getSearchState);
  const [selectedDates, setSelectedDates] = useState({
    from: fromDate,
    to: toDate,
  });
  const [localSelectedDates, setLocalSelectedDates] = useState({
    from: localFromDate,
    to: localToDate,
  });
  const selectedDateFromRef = useRef(
    dateRange === rangeEnums.CUSTOM ? fromDate : selectedDates.from,
  );
  const selectedDateToRef = useRef(dateRange === rangeEnums.CUSTOM ? toDate : selectedDates.to);
  const selectedLocalDateToRef = useRef(
    dateRange === rangeEnums.CUSTOM ? toDate : localSelectedDates.to,
  );
  const selectedLocalDateFromRef = useRef(
    dateRange === rangeEnums.CUSTOM ? fromDate : localSelectedDates.from,
  );
  const [displaySelectedDateFormats, setDisplaySelectedDateFormats] = useState(
    formatDisplayDates(isLocal ? localSelectedDates : selectedDates, { format: true }),
  );
  const [displaySelectedDates, setDisplaySelectedDates] = useState(
    formatDisplayDates(isLocal ? localSelectedDates : selectedDates, { range: dateRange }),
  );

  useEffect(() => {
    const range = isLocal ? localDateRange : dateRange;
    const newRageToDisplay =
      dateRange === rangeEnums.CUSTOM ? { from: fromDate, to: toDate } : dateRanges[range];
    setDisplaySelectedDateFormats(
      formatDisplayDates(newRageToDisplay, { format: true, range: dateRange }),
    );
    setDisplaySelectedDates(formatDisplayDates(newRageToDisplay, { range: dateRange }));
  }, [isLocal, localDateRange, dateRange, fromDate, toDate]);

  const handleDateChangeFrom = (value) => {
    const momentValue = (moment.isMoment(value) && value) || moment(value);

    dispatch(setDateRange(rangeEnums.CUSTOM));

    const isMidnight = isMidnightFrom(momentValue);
    if (!isMidnight && reportingType === REPORTING_TYPES.OPERATIONAL) {
      dispatch(setReportingType(REPORTING_TYPES.TRANSACTIONAL));
    }

    if (!isLocal) {
      selectedDateFromRef.current = momentValue;
      setSelectedDates({ ...selectedDates, from: momentValue.toISOString() });
      dispatch(setFromDate(momentValue.toISOString()));
    } else {
      selectedLocalDateFromRef.current = momentValue;
      setLocalSelectedDates({ ...localSelectedDates, from: momentValue });
    }
  };

  const handleDateChangeTo = (value) => {
    const momentValue = (moment.isMoment(value) && value) || moment(value);

    dispatch(setDateRange(rangeEnums.CUSTOM));

    const isMidnight = isMidnightTo(momentValue);
    if (!isMidnight && reportingType === REPORTING_TYPES.OPERATIONAL) {
      dispatch(setReportingType(REPORTING_TYPES.TRANSACTIONAL));
    }

    if (!isLocal) {
      selectedDateToRef.current = momentValue;
      setSelectedDates({
        ...selectedDates,
        to: momentValue.toISOString(),
      });
      dispatch(setToDate(momentValue.toISOString()));
    } else {
      selectedLocalDateToRef.current = momentValue;
      setLocalSelectedDates({ ...localSelectedDates, to: momentValue });
    }
  };

  const handleRangeChange = ({ target }) => {
    const startValue = moment(dateRanges[target.value].from()).startOf('day').toISOString();
    const endValue = moment(dateRanges[target.value].to()).startOf('day').toISOString();

    if (!isLocal) {
      setSelectedDates({ from: startValue, to: endValue });
      dispatch(setDateRange(target.value));
      dispatch(setFromDate(startValue));
      dispatch(setToDate(endValue));

      selectedDateFromRef.current = startValue;
      selectedDateToRef.current = endValue;
    } else {
      setLocalSelectedDates({ from: startValue, to: endValue });
      setLocalFromDate(startValue);
      setLocalToDate(endValue);
      setLocalDateRange(target.value);

      selectedLocalDateFromRef.current = startValue;
      selectedLocalDateToRef.current = endValue;
    }
  };

  const handleDeliveryFilter = ({ target }) => {
    const { checked } = target;
    dispatch(setIsDelivery(checked));
    setIsDeliveryActive(checked);
  };

  const handleAccountChange = ({ target }) => {
    const { value } = target;
    dispatch(setAccountId(value));
  };
  const handleUnitChange = ({ target }) => {
    const { value } = target;
    dispatch(setUnits(value));
  };

  const handleActive = ({ target }) => {
    const { checked } = target;
    setActive(checked);
  };

  const handleDirectionChange = ({ target }) => {
    const { value } = target;
    dispatch(setSortOrder(value));
  };

  const handleOnSubmit = ({ searchTerm: lSearchTerm }) => {
    dispatch(setSearchTerm(lSearchTerm));
  };

  const handleReportingTypeChange = ({ target }) => {
    const { value } = target;
    dispatch(setReportingType(value));
  };

  const previousSelectedDates =
    dateRange === rangeEnums.CUSTOM
      ? getPreviousPeriod(selectedDates)
      : { from: dateRangesPrevious[dateRange].from(), to: dateRangesPrevious[dateRange].to() };

  return {
    selectedDates: isLocal ? localSelectedDates : selectedDates,
    previousSelectedDates,
    displaySelectedDates,
    displaySelectedDateFormats,
    searchTerm,
    dateRange: isLocal ? localDateRange : dateRange,
    sortOrder,
    units,
    reportingType,
    isDelivery,
    handleDateChangeFrom,
    handleDateChangeTo,
    handleRangeChange,
    handleDirectionChange,
    handleUnitChange,
    handleOnSubmit,
    handleActive,
    handleAccountChange,
    handleReportingTypeChange,
    handleDeliveryFilter,
    useDate,
    useOrder,
    useSearch,
    useUnits,
    useOptional,
    useReportingType: useReportingType && useDate,
    useIsDelivery,
    active,
    isDeliveryActive,
    accountId,
    accounts,
  };
};

export default useESPicker;
