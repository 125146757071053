import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Box, Button, Card, Chip, IconButton, Typography } from '@mui/material';

import { pick } from 'lodash';
import { MdDelete } from 'react-icons/md';
import { createCategory, deleteCategory, fetchCategories } from '../../store/categories';
import { getCategoriesState } from '../../store/categories/selectors';
import { isDeliveryOnly } from '../../store/venues/selectors';

import Page from '../../components/Page';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import withVenue from '../../hoc/withVenue';
import shouldLoad from '../../shared/utils/shouldLoad';
import CustomDialog from '../../components/CustomDialog';
import CategoryForm from '../../components/CategoryForm';
import OrderableTable from '../../components/OrderableTable';
import useRoles from '../../hooks/useRoles';
import PageHeader from '../../components/PageHeader';

const useStyles = makeStyles(() => ({
  heading: {
    display: 'flex',
  },
  buttonClass: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: '10px',
  },
  title: {
    flexGrow: 1,
  },
}));

const Categories = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const categoriesState = useSelector(getCategoriesState);
  const { loading, data, error } = categoriesState;
  const [valueData, setValueData] = useState(data);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [categoryData, setCategoryData] = useState(null);
  const [formAction, setFormAction] = useState('');
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { isRoleAtLeastManager, isAdmin } = useRoles();
  const typeDeliveryOnly = useSelector(isDeliveryOnly);

  const permissionReadOnly = !isAdmin() && typeDeliveryOnly;

  const handleOnSubmit = async (values) => {
    if (formAction === 'create') {
      try {
        await dispatch(createCategory(values));
        showSuccessNotification('Category has been added successfully');
        setIsDialogOpen(false);
        dispatch(fetchCategories());
      } catch (e) {
        showErrorNotification(getErrorMessage(e));
        setIsDialogOpen(false);
      }
    }
  };

  const handleDelete = useCallback(
    async (categoryId) => {
      try {
        await dispatch(deleteCategory(categoryId));
        showSuccessNotification('Category has been deleted successfully');
      } catch (err) {
        await dispatch(fetchCategories());
        showErrorNotification(getErrorMessage(err));
      }
    },
    [dispatch, showErrorNotification, showSuccessNotification],
  );

  const handleOpenDialog = () => {
    setCategoryData(null);
    setFormAction('create');
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const newData = useCallback(() => {
    const pickedData = [];
    if (data) {
      data.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item = {
          ...item,
          delete: 'delete',
        };
        pickedData.push(pick(item, ['categoryName', 'type', 'delete', 'categoryId', 'readonly']));
      });
    }
    return pickedData;
  }, [data]);

  const valueFormatter = useCallback(
    ({ value, valueName, row }) => {
      switch (valueName) {
        case 'delete':
          return (
            <IconButton
              disabled={row.readonly || permissionReadOnly}
              edge="end"
              size="small"
              onClick={() => handleDelete(row.categoryId)}
            >
              <MdDelete />
            </IconButton>
          );
        case 'modifierItems':
          return row.modifierItems.length;
        case 'categoryName':
          return row.readonly ? (
            <p>
              {row.categoryName} <Chip label="Read-only" />
            </p>
          ) : (
            row.categoryName
          );
        default:
          return value;
      }
    },
    [handleDelete, permissionReadOnly],
  );

  const CategoryOptionForm = () => (
    <CategoryForm
      formAction={formAction}
      categoryData={categoryData}
      onSubmit={handleOnSubmit}
      onCancel={handleCloseDialog}
    />
  );

  const dialogTitle = useMemo(() => {
    if (formAction === 'update') {
      return 'Category';
    }
    return 'Create new category';
  }, [formAction]);

  useEffect(() => {
    if (shouldLoad(categoriesState)) dispatch(fetchCategories());
    setValueData(data);
  }, [categoriesState, data, dispatch]);

  return (
    <>
      <PageHeader fullWidth>
        <Box className={classes.buttonClass}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenDialog}
            disabled={!isRoleAtLeastManager() || permissionReadOnly}
          >
            New Category
          </Button>
        </Box>
        <Box className={classes.heading}>
          <aside>
            <CustomDialog
              isDialogOpen={isDialogOpen}
              handleCloseDialog={handleCloseDialog}
              title={dialogTitle}
            >
              <CategoryOptionForm />
            </CustomDialog>
          </aside>
        </Box>
        <Typography paragraph>
          Categories are not visible to your customers, but are used for reporting. Use categories
          to describe the product type (e.g. Wine, Whiskey, Pizzas). Use{' '}
          <Button color="primary" size="small" component={Link} to="/menus">
            Menu Sections
          </Button>{' '}
          to group things together on your menu.
        </Typography>
      </PageHeader>
      <Page loading={loading} error={error} fullWidth>
        {data && (
          <>
            {valueData && data && (
              <Card>
                <>
                  <OrderableTable
                    tableData={[...newData()]}
                    titles={['NAME', 'TYPE', '']}
                    keys={['categoryId']}
                    excludeFields={['categoryId']}
                    disableColumnTitles={['']}
                    valueFormatter={valueFormatter}
                  />
                </>
              </Card>
            )}
          </>
        )}
      </Page>
    </>
  );
};

export default withVenue(Categories);
