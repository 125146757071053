import React from 'react';
import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sentenceCase } from 'change-case';
import { TextField } from 'formik-mui';
import { Field } from 'formik';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    ...theme.customFonts.largeBold,
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  form: {
    // maxWidth: '755px',
    flexBasis: '647px',
    [theme.breakpoints.down('md')]: {
      flexBasis: 'auto',
    },
  },
  display: {
    flexBasis: '389px',
    height: 'fit-content',
    [theme.breakpoints.down('md')]: {
      flexBasis: 'auto',
      marginTop: '24px',
    },
  },
  planType: {
    marginRight: '8px',
  },
  sectionTitle: {
    ...theme.customFonts.medium,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  fee: {
    ...theme.customFonts.medium,
    marginRight: '35px',
  },
  addDiscount: {
    color: theme.customPalette.trueBlue,
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: '16px',
    '&.MuiButton-text.MuiButton-text': {
      color: theme.customPalette.trueBlue,
    },
    '& .MuiButton-label': {
      paddingLeft: theme.spacing(1),
    },
  },
  feature: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  featureTitleSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  featureLabel: {
    ...theme.customFonts.small,
  },
  counter: {
    width: '67px',
    borderRadius: '8px',
    marginTop: '8px',
    marginRight: '8px',
  },
  inputCounter: {
    // width: '67px',
    borderRadius: '8px',
    marginTop: '8px',
    marginRight: '8px',
    '& .MuiInputBase-input': {
      height: '12px',
    },
  },
  totalPriceBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  totalPriceLabel: {
    ...theme.customFonts.medium,
  },
  totalPrice: {
    ...theme.customFonts.xxLarge,
  },
  displayGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  displayVariableCostsBox: {
    background: theme.customPalette.greyLightest,
    borderRadius: '8px',
    padding: '16px',
  },
  displayVariableCostsTitle: {
    ...theme.customFonts.smallBold,
    color: theme.customPalette.greyDarker,
    marginBottom: '16px',
  },
  displayVariableCosts: {
    ...theme.customFonts.small,
  },
  displayVariableCostsLabel: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
    textAlign: 'center',
  },
  displayVariableCostsValue: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
  },
  displayVariableCostsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  displayPreviousCost: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDark,
    textDecoration: 'line-through',
  },
  displayAfterCost: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
  },
  displayPriceChangeBox: {
    display: 'flex',
    flexDirection: 'row',
  },
  displayVariableCostsLabelTitle: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
  },
}));
const TotalCard = ({ values }) => {
  const classes = useStyles();
  const calculatedTotal = () => {
    let total = values.monthlyFee;
    const hardwareTotal = values?.hardware?.reduce((acc, curr) => {
      if (curr.quantity > 0) {
        return (
          acc + (Number(curr.discountedPrice) || Number(curr.unitPrice)) * Number(curr.quantity)
        );
      }
      return acc;
    }, 0);

    const featuresTotal = values?.fixedCosts?.reduce((acc, curr) => {
      if (curr.enabled) {
        return acc + (Number(curr.discountedPrice) || Number(curr.unitPrice));
      }
      return acc;
    }, 0);

    return (total += hardwareTotal + featuresTotal - values.discount);
  };

  return (
    <Card className={classes.display}>
      <CardContent>
        <Grid className={classes.displayGrid} container spacing={2}>
          <Grid item xs={4} />
          <Grid item xs={2}>
            <Typography className={classes.displayVariableCostsTitle}>Qty</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.displayVariableCostsTitle}>Price</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.displayVariableCostsTitle}>Amount</Typography>
          </Grid>
        </Grid>
        <Grid className={classes.displayGrid} container spacing={2}>
          {values?.type && (
            <Grid item xs={5}>
              <Typography className={classes.displayVariableCostsLabelTitle}>
                {sentenceCase(values.type)} plan
              </Typography>
            </Grid>
          )}
          <Grid item xs={1}>
            <Typography className={classes.displayVariableCostsLabel}>x1</Typography>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={2}>
            <Typography className={classes.displayVariableCostsLabel}>
              £{values?.monthlyFee}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.displayVariableCostsLabel}>
              £{values?.monthlyFee}
            </Typography>
          </Grid>
        </Grid>
        {values?.fixedCosts?.some((feature) => feature.enabled) && (
          <>
            <Divider
              variant="fullWidth"
              style={{ background: '#BCCCDC', marginTop: '16px', marginBottom: '16px' }}
            />
            <Grid
              className={classes.displayGrid}
              container
              spacing={2}
              justify-content="space-between"
            >
              {values?.fixedCosts?.map(
                (feature) =>
                  feature.enabled && (
                    <React.Fragment key={feature.label}>
                      <Grid item xs={5}>
                        <Typography className={classes.displayVariableCostsLabelTitle}>
                          {feature.label}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography className={classes.displayVariableCostsLabel}>x1</Typography>
                      </Grid>
                      <Grid item xs={1}>
                        {feature.discountedPrice && (
                          <Typography className={classes.displayPreviousCost}>
                            £{feature.unitPrice}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        <Typography className={classes.displayVariableCostsLabel}>
                          £{feature.discountedPrice || feature.unitPrice}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.displayVariableCostsLabel}>
                          £{feature.discountedPrice ? feature.discountedPrice : feature.unitPrice}
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  ),
              )}
            </Grid>
          </>
        )}
        {values?.hardware?.some((feature) => feature.quantity > 0) && (
          <>
            <Divider
              variant="fullWidth"
              style={{ background: '#BCCCDC', marginTop: '16px', marginBottom: '16px' }}
            />
            <Grid className={classes.displayGrid} container spacing={2}>
              {values?.hardware?.map(
                (hardware) =>
                  hardware.quantity > 0 && (
                    <React.Fragment key={hardware.label}>
                      <Grid item xs={5}>
                        <Typography className={classes.displayVariableCostsLabelTitle}>
                          {hardware.label}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography className={classes.displayVariableCostsLabel}>
                          x{hardware.quantity}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        {hardware.discountedPrice && (
                          <Typography className={classes.displayPreviousCost}>
                            £{hardware.unitPrice}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        <Typography className={classes.displayVariableCostsLabel}>
                          £{hardware.discountedPrice || hardware.unitPrice}
                        </Typography>
                      </Grid>
                      {/* <Grid item xs={3}>
                    <Typography className={classes.displayVariableCostsLabel}>
                      £{hardware.unitPrice}
                    </Typography>
                  </Grid> */}
                      <Grid item xs={3}>
                        <Typography className={classes.displayVariableCostsLabel}>
                          £
                          {hardware.discountedPrice
                            ? hardware.quantity * hardware.discountedPrice
                            : hardware.quantity * hardware.unitPrice}
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  ),
              )}
            </Grid>
          </>
        )}
      </CardContent>
      <CardContent>
        <Divider
          variant="fullWidth"
          style={{ background: '#BCCCDC', marginTop: '16px', marginBottom: '16px' }}
        />
        {/* <Grid container alignItems="center">
          <Grid item xs={8}>
            Subtotal:
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'right' }}>
            £{values.subtotal}
          </Grid>
        </Grid> */}
        <Grid container alignItems="center">
          <Grid item xs={8}>
            Discount:
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'right' }}>
            <Field
              className={classes.inputCounter}
              name="discount"
              type="number"
              InputProps={{ startAdornment: '£', inputProps: { step: '1', min: '0' } }}
              component={TextField}
              variant="outlined"
              size="small"
            />
          </Grid>
        </Grid>
        <Box className={classes.totalPriceBox}>
          <Typography className={classes.totalPriceLabel}>Total per month</Typography>
          <Typography className={classes.totalPrice}>£{calculatedTotal()}</Typography>
        </Box>
      </CardContent>

      <CardContent>
        <Grid className={classes.displayVariableCostsBox} container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.displayVariableCostsTitle}>Variable costs</Typography>
          </Grid>
          {values?.variableCosts?.map((cost) => (
            <React.Fragment key={cost.label}>
              <Grid item xs={8}>
                <Typography className={classes.displayVariableCostsLabelTitle}>
                  {cost.label}
                </Typography>
              </Grid>

              <Grid item xs={2}>
                {cost.discountedPrice && (
                  <Typography className={classes.displayPreviousCost}>£{cost.unitPrice}</Typography>
                )}
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.displayVariableCostsLabel}>
                  £{cost.discountedPrice || cost.unitPrice}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TotalCard;
