import { combineReducers, configureStore, createAction } from '@reduxjs/toolkit';
import { authentication, session, refreshSession } from './session';
import { accounts, account } from './accounts';
import { admin } from './admin';
import { campaigns } from './campaigns';
import { campaign } from './campaign';
import { categories, category } from './categories';
import { modifiers, modifier } from './modifiers';
import { tabs, tab } from './tabs';
import { vouchers, voucher } from './vouchers';
import { debits } from './debits';
import { discounts, discount } from './itemDiscounts';
import { devices, device, availableDevices, availableDeviceCapabilities } from './devices';
import { availableTerminalDevices, terminalDevice, terminalDevices } from './terminals';
import { mobileDevices, mobileDevice, onboardingDeviceKey } from './mobiles';
import { qrcodes, qrcode, availableQrcodes } from './qrcodes';
import { promoCodes, promoCode } from './promoCodes';
import { imageGallery } from './imageGallery';
import { imagesReview } from './imagesReview';
import { ingredients, ingredient } from './ingredients';
import { menuItems, menuItem } from './menuItems';
import { managers, manager } from './managers';
import { options, option } from './options';
import { signup } from './signup';
import { builderMenu, builderMenus } from './brands/builderMenu/builderMenus';
import { builderMenuItems } from './brands/builderMenu/builderMenuItems';
import { brands } from './brands/brands';
import { brand } from './brands/brand';
import { venueBrands } from './brands/venueBrands';
import { brandsVenueAssociations } from './brands/brandsVenueAssociations';
import { brandsMenuItems, brandsMenuItem } from './brands/brandsMenuItems';
import { brandsCategories, brandsCategory } from './brands/brandsCategories';
import { brandsOptions, brandsOption } from './brands/brandsOptions';
import { brandsIngredients, brandsIngredient } from './brands/brandsIngredients';
import { brandsModifiers, brandsModifier } from './brands/brandsModifiers';
import { brandsMenus, brandsMenusList } from './brands/brandsMenus';
import { staff, staffAll } from './staff';
import { kiosks, kioskOnboardingKey } from './kiosks';
import { dietaryRequirements } from './dietaryRequirements';
import { tables, table } from './tables';
import { theme } from './theme';
import { orders, order, ordersLive, ordersTakeaway, polarisOrders } from './orders';
import { cashup, cashupHistory } from './cashups';
import { cardPayouts } from './payouts-card';
import { externalPayouts, externalPayout } from './payouts-external';
import { payments } from './payments';
import { personalisation, bannerGallery } from './personalisation';
import { printer, printers, availablePrinters } from './printers';
import {
  venues,
  venue,
  venueSettings,
  venuePriorities,
  venueAdditionalCharges,
  venueHideGMVSetting,
} from './venues';
import { venueMenus, venueMenu, venueMenuStatus } from './venueMenus';
import { venueFees } from './venueFees';
import { venueMenuItems } from './venueMenuItems';
import { salesArea, salesAreas } from './salesAreas';
import { search } from './search';
import { serviceStatus } from './serviceStatus';
import { customer } from './customer';
import {
  upsellGroup,
  upsellGroups,
  smartUpsellGroup,
  upsellGroupInitiatingItems,
  smartUpsellGroupInitiatingItems,
} from './upsellGroups';
import {
  reportSalesByStaff,
  reportSalesOverview,
  reportSalesByArea,
  reportSalesByItem,
  reportSalesByTable,
  reportSalesByCategory,
  reportTipsByStaff,
  reportCustomersOverview,
  reportOverview,
  reportRepeatCustomers,
  reportTopCustomers,
  deliveryMetrics,
} from './reports';
import { billing, directDebitMandate, invoice } from './billing';
import { reviews } from './reviews';
import { introTasks, introTask } from './introTasks';
import { floats } from './floats';
import { till, tills } from './tills';
import { platformInvoices } from './platformInvoices';
import { deliveryStatements } from './deliveryStatements';
import { sessionsScore } from './sessionsScore';

import { setStore, setRefreshSession } from '../api/httpRequest';

const app = combineReducers({
  authentication,
  session,
});

export const reducer = combineReducers({
  accounts,
  account,
  admin,
  app,
  billing,
  bannerGallery,
  builderMenu,
  builderMenus,
  builderMenuItems,
  brand,
  brands,
  brandsMenus,
  brandsMenusList,
  brandsVenueAssociations,
  brandsMenuItems,
  brandsMenuItem,
  brandsOptions,
  brandsOption,
  brandsIngredients,
  brandsIngredient,
  brandsModifiers,
  brandsModifier,
  brandsCategories,
  brandsCategory,
  campaigns,
  campaign,
  categories,
  category,
  customer,
  debits,
  deliveryMetrics,
  device,
  devices,
  directDebitMandate,
  discounts,
  discount,
  availableTerminalDevices,
  terminalDevice,
  terminalDevices,
  floats,
  mobileDevice,
  mobileDevices,
  kiosks,
  kioskOnboardingKey,
  onboardingDeviceKey,
  dietaryRequirements,
  availableDevices,
  availableDeviceCapabilities,
  qrcodes,
  qrcode,
  promoCodes,
  promoCode,
  availableQrcodes,
  invoice,
  imageGallery,
  imagesReview,
  ingredients,
  ingredient,
  introTasks,
  introTask,
  managers,
  manager,
  menuItems,
  menuItem,
  modifiers,
  modifier,
  options,
  option,
  order,
  orders,
  ordersLive,
  ordersTakeaway,
  personalisation,
  polarisOrders,
  printer,
  printers,
  availablePrinters,
  payments,
  salesArea,
  salesAreas,
  reportSalesByStaff,
  reportSalesOverview,
  reportSalesByArea,
  reportSalesByItem,
  reportSalesByTable,
  reportSalesByCategory,
  reportTipsByStaff,
  reportCustomersOverview,
  reportOverview,
  reportRepeatCustomers,
  reportTopCustomers,
  search,
  serviceStatus,
  sessionsScore,
  staff,
  staffAll,
  signup,
  tab,
  tables,
  table,
  tabs,
  till,
  tills,
  theme,
  venues,
  venue,
  venueFees,
  venueMenus,
  venueMenu,
  venueMenuStatus,
  venueSettings,
  venuePriorities,
  venueAdditionalCharges,
  venueHideGMVSetting,
  venueMenuItems,
  venueBrands,
  vouchers,
  voucher,
  cardPayouts,
  externalPayouts,
  externalPayout,
  reviews,
  upsellGroup,
  upsellGroups,
  smartUpsellGroup,
  upsellGroupInitiatingItems,
  smartUpsellGroupInitiatingItems,
  cashup,
  cashupHistory,
  platformInvoices,
  deliveryStatements,
});

// In order to clear most of the state, we need access to it at the store level.
const rootReducer = (state, action) => {
  if (action.type === 'reset-venue') {
    return reducer({ app: state.app, venues: state.venues, search: state.search }, action);
  }

  return reducer(state, action);
};

export const resetVenue = createAction('reset-venue', () => ({
  reset: true,
}));

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
    }),
});

export default store;

setStore(store);
setRefreshSession(refreshSession);
