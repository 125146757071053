import React from 'react';

import { makeStyles } from '@mui/styles';
import { Card, CardContent, Typography, Link as MuiLink, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
// import { useSelector } from 'react-redux';
// import { isBrandUp } from '../../store/venues/selectors';

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'Sen',
    fontWeight: 'bold',
    flexGrow: 1,
    marginBottom: '1rem',
  },
  link: {
    '&:visited': {
      color: '#333333',
    },
  },
}));

const QuickActionsCard = () => {
  // const isBrandup = useSelector(isBrandUp);
  const classes = useStyles();
  const { show } = useIntercom();

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Typography className={classes.title} variant="h4">
            Quick actions
          </Typography>
          <p>
            <Link to="/orders-history" className={classes.link}>
              Refund an order
            </Link>
          </p>
          <p>
            <Link to="/items" className={classes.link}>
              Mark an item as out of stock
            </Link>
          </p>
          {/**
           <p>
            <MuiLink
              underline="always"
              href={
                isBrandup
                  ? 'https://servedup.co/brandup-help'
                  : 'https://servedup.co/faq?hsLang=en-gb'
              }
              className={classes.link}
              target="_blank"
            >
              FAQ’s
            </MuiLink>
          </p>
          * */}
          <p>
            <MuiLink underline="always" href="#" onClick={show} className={classes.link}>
              Need help? Message us!
            </MuiLink>
          </p>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default QuickActionsCard;
