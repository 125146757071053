import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import WarningIcon from '@mui/icons-material/Warning';
import { Button } from '@mui/material';

const ManagersTable = ({
  managers,
  adminMode = false,
  setResetEmail,
  setConfirmPwdDialogOpen,
  setDeleteEmail,
  setConfirmManagerDeleteDialogOpen,
}) => {
  if (!managers) return null;
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>{!adminMode && 'Created At'}</TableCell>
            <TableCell>{!adminMode && 'Updated At'}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {managers.map((manager) => {
            const { email, status, createdAt, updatedAt } = manager;
            return (
              <TableRow key={email}>
                <TableCell component="th" scope="row">
                  {email}
                </TableCell>
                <TableCell>{status}</TableCell>
                {adminMode ? (
                  <>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<WarningIcon />}
                        onClick={() => {
                          setResetEmail(email);
                          setConfirmPwdDialogOpen(true);
                        }}
                      >
                        Reset Password
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<WarningIcon />}
                        onClick={() => {
                          setDeleteEmail(email);
                          setConfirmManagerDeleteDialogOpen(true);
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell>{createdAt}</TableCell>
                    <TableCell>{updatedAt}</TableCell>
                  </>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ManagersTable;
