import React from 'react';
import { Formik, Form } from 'formik';
import { makeStyles } from '@mui/styles';
import { TextField, Box, Button } from '@mui/material';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  helperText: {
    color: theme.palette.error.main,
  },
}));

const PaymentFeesForm = ({ initialValues, onSubmit }) => {
  const classes = useStyles();

  const NewManagerSchema = Yup.object().shape({
    cardFixedFee: Yup.number().min(0).required('Card fixed fee is required'),
    cardPercentFee: Yup.number().min(0).required('Card percent fee is required'),
    cashFixedFee: Yup.number().min(0).required('Cash fixed fee is required'),
    cashPercentFee: Yup.number().min(0).required('Cash percent fee is required'),
    amexFixedFee: Yup.number().min(0).required('Amex fixed fee is required'),
    amexPercentFee: Yup.number().min(0).required('Amex percent fee is required'),
    openBankingFixedFee: Yup.number().min(0).required('OpenBanking fixed fee is required'),
    openBankingPercentFee: Yup.number().min(0).required('OpenBanking percent fee is required'),
  });

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={NewManagerSchema}
        enableReinitialize
      >
        {({ errors, touched, handleBlur, handleChange, isValid, values, dirty }) => (
          <Form>
            <Box display="flex" justifyContent="space-between" alignItems="flex-start" ml={2}>
              <Box mr={2} m={-2} display="flex" flexDirection="column">
                <TextField
                  helperText={errors.cardFixedFee && touched.cardFixedFee && errors.cardFixedFee}
                  FormHelperTextProps={{ className: classes.helperText }}
                  name="cardFixedFee"
                  size="small"
                  label="Card fixed fee"
                  variant="outlined"
                  placeholder="0"
                  type="number"
                  inputProps={{ step: '0.1' }}
                  required
                  margin="normal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.cardFixedFee}
                />
                <TextField
                  helperText={
                    errors.cardPercentFee && touched.cardPercentFee && errors.cardPercentFee
                  }
                  FormHelperTextProps={{ className: classes.helperText }}
                  name="cardPercentFee"
                  size="small"
                  label="Card percent fee"
                  variant="outlined"
                  placeholder="0"
                  type="number"
                  inputProps={{ step: '0.1' }}
                  required
                  margin="normal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.cardPercentFee}
                />
                <TextField
                  helperText={errors.cashFixedFee && touched.cashFixedFee && errors.cashFixedFee}
                  FormHelperTextProps={{ className: classes.helperText }}
                  name="cashFixedFee"
                  size="small"
                  label="Cash fixed fee"
                  variant="outlined"
                  placeholder="0"
                  type="number"
                  inputProps={{ step: '0.1' }}
                  required
                  margin="normal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.cashFixedFee}
                />
                <TextField
                  helperText={
                    errors.cashPercentFee && touched.cashPercentFee && errors.cashPercentFee
                  }
                  FormHelperTextProps={{ className: classes.helperText }}
                  name="cashPercentFee"
                  size="small"
                  label="Cash percent fee"
                  variant="outlined"
                  placeholder="0"
                  type="number"
                  inputProps={{ step: '0.1' }}
                  required
                  margin="normal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.cashPercentFee}
                />
                <TextField
                  helperText={errors.amexFixedFee && touched.amexFixedFee && errors.amexFixedFee}
                  FormHelperTextProps={{ className: classes.helperText }}
                  name="amexFixedFee"
                  size="small"
                  label="International Cards / Amex fixed fee"
                  variant="outlined"
                  placeholder="0"
                  type="number"
                  inputProps={{ step: '0.1' }}
                  required
                  margin="normal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.amexFixedFee}
                />
                <TextField
                  helperText={
                    errors.amexPercentFee && touched.amexPercentFee && errors.amexPercentFee
                  }
                  FormHelperTextProps={{ className: classes.helperText }}
                  name="amexPercentFee"
                  size="small"
                  label="International Cards / Amex percent fee"
                  variant="outlined"
                  placeholder="0"
                  type="number"
                  inputProps={{ step: '0.1' }}
                  required
                  margin="normal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.amexPercentFee}
                />
                <TextField
                  helperText={
                    errors.openBankingFixedFee &&
                    touched.openBankingFixedFee &&
                    errors.openBankingFixedFee
                  }
                  FormHelperTextProps={{ className: classes.helperText }}
                  name="openBankingFixedFee"
                  size="small"
                  label="OpenBanking fixed fee"
                  variant="outlined"
                  placeholder="0"
                  type="number"
                  inputProps={{ step: '0.1' }}
                  required
                  margin="normal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.openBankingFixedFee}
                />
                <TextField
                  helperText={
                    errors.openBankingPercentFee &&
                    touched.openBankingPercentFee &&
                    errors.openBankingPercentFee
                  }
                  FormHelperTextProps={{ className: classes.helperText }}
                  name="openBankingPercentFee"
                  size="small"
                  label="OpenBanking percent fee"
                  variant="outlined"
                  placeholder="0"
                  type="number"
                  inputProps={{ step: '0.1' }}
                  required
                  margin="normal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.openBankingPercentFee}
                />
              </Box>
              <Box>
                <Button
                  disabled={!isValid || !dirty}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Update
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PaymentFeesForm;
