import React, { useRef } from 'react';
import { Box, Dialog, Divider, IconButton, Typography, Grid, Button } from '@mui/material';
import { Formik, Form, FieldArray } from 'formik';
import { MdClose } from 'react-icons/md';
import DeliveryHoursDay from './DeliveryHoursDay';
import {
  WEEKDAYS,
  parseOpeningHoursforApi,
  parseOpeningHoursforUi,
} from '../../shared/utils/deliveryOpeningHours';

function getNextDay(day) {
  const dayIndex = WEEKDAYS.indexOf(day);
  return WEEKDAYS[(dayIndex + 1) % 7];
}

const DeliveryHoursDialog = ({
  open,
  onClose,
  openingHours,
  handleUpdateOpeningHours,
  hoursAreUpdating,
}) => {
  const formikRef = useRef();
  const formattedOpeningHours = parseOpeningHoursforUi({ openingHours, addEmptyArray: true });

  const handleCopyHours = (copyDayIndex) => {
    const currentOpeningHours = formikRef.current.values;
    const dayToCopy = currentOpeningHours[copyDayIndex];
    const newFormattedOpeningHours = currentOpeningHours.map((day, index) => {
      if (index === copyDayIndex) return day;

      const newDay = { ...day, openingHours: dayToCopy.openingHours, closed: dayToCopy.closed };

      newDay.openingHours = newDay.openingHours.map((segment) => {
        let newSegment = segment;
        if (newSegment.nextDay) {
          newSegment = { ...newSegment, nextDay: getNextDay(day.day) };
        }
        return newSegment;
      });

      return newDay;
    });
    formikRef.current.setValues(newFormattedOpeningHours);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <Box padding={2}>
        <Box display="flex" justifyContent="space-between" paddingY={2}>
          <Typography variant="h2">Set Delivery Opening Hours</Typography>
          <IconButton onClick={onClose} size="small">
            <MdClose />
          </IconButton>
        </Box>
        <Divider />
        <Box paddingY={2}>
          <Formik
            innerRef={formikRef}
            initialValues={formattedOpeningHours}
            onSubmit={(values) => {
              handleUpdateOpeningHours(parseOpeningHoursforApi(values));
            }}
          >
            {({ values, setFieldValue, isValid }) => (
              <Form>
                <Grid container direction="column" spacing={3}>
                  <FieldArray name="daysOfWeek">
                    {() =>
                      values.map((value, index) => (
                        <React.Fragment key={value.day}>
                          <DeliveryHoursDay
                            index={index}
                            values={value}
                            setFieldValue={setFieldValue}
                            handleCopyHours={handleCopyHours}
                            hoursAreUpdating={hoursAreUpdating}
                          />
                          <Divider />
                        </React.Fragment>
                      ))
                    }
                  </FieldArray>
                  <Box display="flex" justifyContent="flex-end" padding={2} paddingBottom={0}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!isValid || hoursAreUpdating}
                    >
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DeliveryHoursDialog;
