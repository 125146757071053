import React from 'react';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { Checkbox, Switch, TextField } from 'formik-mui';
import { sentenceCase } from 'change-case';
import { MdClose } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import BackArrow from '../../components/BackArrow';
import useEndpoint from '../../hooks/useEndpoint';
import { getBillingState } from '../../store/billing/selectors';
import { fetchBilling, updateSubscription } from '../../store/billing';
import UniversalSave from '../../components/UniversalSave';
import TotalCard from './TotalCard';
import CustomWarningBanner from '../../components/CustomWarningBanner';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    ...theme.customFonts.largeBold,
    marginBottom: '1rem',
  },
  planToggle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '10px',
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  gridContainer: {
    alignItems: 'center',
  },
  form: {
    // maxWidth: '755px',
    flexBasis: '647px',
    [theme.breakpoints.down('md')]: {
      flexBasis: 'auto',
    },
  },
  display: {
    flexBasis: '389px',
    height: 'fit-content', // or 'fit-content'
    [theme.breakpoints.down('md')]: {
      flexBasis: 'auto',
      marginTop: '24px',
    },
  },
  planType: {
    marginRight: '8px',
  },
  sectionTitle: {
    ...theme.customFonts.medium,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  fee: {
    ...theme.customFonts.medium,
    display: 'flex',
    justifyContent: 'center',
  },
  addDiscount: {
    color: theme.customPalette.trueBlue,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // marginRight: '16px',
    '&.MuiButton-text.MuiButton-text': {
      color: theme.customPalette.trueBlue,
    },
    // '& .MuiButton-label': {
    //   paddingLeft: theme.spacing(1),
    // },
  },
  feature: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  featureTitleSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  featureLabel: {
    ...theme.customFonts.small,
  },
  counter: {
    width: '67px',
    borderRadius: '8px',
    marginTop: '8px',
    marginRight: '8px',
    '& .MuiInputBase-input': {
      height: '12px',
    },
  },
  totalPriceBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  totalPriceLabel: {
    ...theme.customFonts.medium,
  },
  totalPrice: {
    ...theme.customFonts.xxLarge,
  },
  discountField: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '8px',
      width: '65px',
    },
  },
}));

const checkIfDisabled = (values, feature) => {
  const isUpsellsEnabled = values?.fixedCosts?.find((item) => item.label === 'Upsells')?.enabled;
  if (feature.label === 'Smart Upsells' && isUpsellsEnabled === false) {
    return true;
  }
  return false;
};

const updateLabel = (label) => {
  if (label === 'External Deposits') return 'Design my night';
  if (label === 'Disputes') return 'Customer disputes';

  return label;
};

const SubscriptionSchema = Yup.object().shape({
  active: Yup.boolean().required(),
  type: Yup.string().oneOf(['STANDARD', 'PRO']).required(),
  monthlyFee: Yup.number().required(),
  fixedCosts: Yup.array().of(
    Yup.object().shape({
      enabled: Yup.boolean().required(),
      discountedPrice: Yup.number().min(0).optional(),
    }),
  ),
  variableCosts: Yup.array().of(
    Yup.object().shape({
      enabled: Yup.boolean().required(),
      discountedPrice: Yup.number().min(0).optional(),
    }),
  ),
  hardware: Yup.array().of(
    Yup.object().shape({
      quantity: Yup.number().min(0).required(),
      discountedPrice: Yup.number().min(0).optional(),
    }),
  ),
  discount: Yup.number().min(0).optional(),
});

const PlanSettings = () => {
  const classes = useStyles();
  const { loading, data: plan } = useEndpoint(getBillingState, fetchBilling()) || {};
  const planTypes = ['STANDARD', 'PRO'];
  const initialValues = plan?.subscription;
  const dispatch = useDispatch();
  const isUpsellsEnabled = (values) => {
    if (values?.type === 'PRO') return true;
    return values?.fixedCosts?.find((item) => item.label === 'Upsells')?.enabled;
  };

  const handlePlanChange = async (setValues, option) => {
    if (option === 'STANDARD' && plan?.subscription?.type === 'PRO') {
      setValues(plan?.standardPlan);
    }
    if (option === 'STANDARD' && plan?.subscription?.type === 'STANDARD') {
      setValues(plan?.subscription);
    }
    if (option === 'PRO' && plan?.subscription?.type === 'STANDARD') {
      setValues(plan?.proPlan);
    }
    if (option === 'PRO' && plan?.subscription?.type === 'PRO') {
      setValues(plan?.subscription);
    }
  };

  const handleUncheckSmartUpsells = (values, setFieldValue) => {
    const smartUpsellsIndex = values?.fixedCosts?.findIndex(
      (item) => item.label === 'Smart Upsells',
    );
    setFieldValue(`fixedCosts.[${smartUpsellsIndex}].enabled`, false);
  };

  const handleSavePlan = async (values) => {
    dispatch(updateSubscription(values));
  };

  const handleDiscount = (value, setFieldValue, index, cost) => {
    const updatedValue = { ...value, discountedPrice: 0 };
    setFieldValue(`${cost}.[${index}]`, updatedValue);
  };

  const handleRemoveDiscount = (value, setFieldValue, index, cost) => {
    const updatedValue = { ...value };
    delete updatedValue.discountedPrice;
    setFieldValue(`${cost}.[${index}]`, updatedValue);
  };

  return (
    <>
      <PageHeader>
        <BackArrow text="Admin Area Settings" to="/admin" />
        <Typography className={classes.title}>Plan settings</Typography>
      </PageHeader>
      {plan?.subscription ? (
        <Page loading={loading}>
          {!plan?.subscription?.active && (
            <CustomWarningBanner
              title="This plan is currently deactivated"
              titleFont="smallBold"
              titleColor="greyDarker"
              iconColor="#FFB800"
              backgroundColor="warningYellow"
              bottomSpace
            />
          )}
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={SubscriptionSchema}
          >
            {({ values, isValid, errors, dirty, resetForm, setFieldValue, setValues }) => (
              <>
                <UniversalSave
                  isValid={isValid}
                  errors={errors}
                  dirty={dirty}
                  onSave={() => handleSavePlan(values)}
                  onDiscard={resetForm}
                />
                <Form>
                  <Box className={classes.box}>
                    <Card className={classes.form}>
                      <CardContent>
                        <Box className={classes.planToggle}>
                          <Field name="active" component={Switch} type="checkbox" color="primary" />
                          <Typography className={classes.sectionTitle}>
                            Plan {values?.plan?.active ? 'deactivated' : 'active'}
                          </Typography>
                        </Box>
                        <Typography className={classes.sectionTitle}>Plan type</Typography>
                        <Grid className={classes.gridContainer} container spacing={2}>
                          {values?.type && (
                            <Grid item xs={8}>
                              <Field
                                className={classes.planType}
                                name="type"
                                component={TextField}
                                fullWidth
                                select
                                variant="outlined"
                                size="small"
                              >
                                {planTypes.map((option) => (
                                  <MenuItem
                                    key={option}
                                    value={option}
                                    onClick={() => handlePlanChange(setValues, option)}
                                  >
                                    {sentenceCase(option)}
                                  </MenuItem>
                                ))}
                              </Field>
                            </Grid>
                          )}
                          <Grid item xs={2}>
                            <Typography className={classes.fee}>£{values?.monthlyFee}</Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <Divider
                        variant="fullWidth"
                        style={{ background: '#BCCCDC', marginTop: '16px' }}
                      />
                      <CardContent>
                        <Typography className={classes.sectionTitle}>
                          Features and services
                        </Typography>
                        <Grid className={classes.gridContainer} container spacing={1}>
                          {values?.fixedCosts?.map((feature, index) => (
                            <React.Fragment key={feature.label}>
                              <Grid item xs={8}>
                                <Box className={classes.featureTitleSection}>
                                  <Field
                                    name={`fixedCosts.${index}.enabled`}
                                    component={Checkbox}
                                    type="checkbox"
                                    color="primary"
                                    checked={feature.enabled}
                                    size="small"
                                    disabled={checkIfDisabled(values, feature, setFieldValue)}
                                    onChange={(e) => {
                                      if (feature.label === 'Upsells' && e.target.checked === false)
                                        handleUncheckSmartUpsells(values, setFieldValue);
                                      if (feature.discountedPrice && e.target.checked === false) {
                                        handleRemoveDiscount(
                                          feature,
                                          setFieldValue,
                                          index,
                                          'fixedCosts',
                                        );
                                      }
                                      setFieldValue(
                                        `fixedCosts.[${index}].enabled`,
                                        e.target.checked,
                                      );
                                    }}
                                  />
                                  <Typography
                                    className={classes.featureLabel}
                                    style={
                                      feature.label === 'Smart Upsells' && !isUpsellsEnabled(values)
                                        ? { color: '#00000042' }
                                        : {}
                                    }
                                  >
                                    {feature.label}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={1} md={2}>
                                <Typography
                                  className={classes.fee}
                                  style={{
                                    color: !feature.enabled ? '#00000042' : 'inherit',
                                    textDecoration: feature?.discountedPrice
                                      ? 'line-through'
                                      : 'none',
                                  }}
                                >
                                  £{feature.unitPrice}
                                </Typography>
                              </Grid>
                              <Grid item xs={3} md={2}>
                                {!('discountedPrice' in feature) && feature.enabled && (
                                  <Button
                                    className={classes.addDiscount}
                                    onClick={() => {
                                      handleDiscount(feature, setFieldValue, index, 'fixedCosts');
                                    }}
                                  >
                                    Discount
                                  </Button>
                                )}
                                {'discountedPrice' in feature && (
                                  <div className={classes.discountField}>
                                    <Field
                                      className={classes.counter}
                                      name={`fixedCosts.${index}.discountedPrice`}
                                      component={TextField}
                                      type="number"
                                      size="small"
                                      variant="outlined"
                                      InputProps={{ startAdornment: '£', inputProps: { min: '0' } }}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `fixedCosts.${index}.discountedPrice`,
                                          Number(e.target.value),
                                        );
                                      }}
                                    />
                                    <IconButton
                                      size="small"
                                      onClick={() =>
                                        handleRemoveDiscount(
                                          feature,
                                          setFieldValue,
                                          index,
                                          'fixedCosts',
                                        )
                                      }
                                    >
                                      <MdClose />
                                    </IconButton>
                                  </div>
                                )}
                              </Grid>
                            </React.Fragment>
                          ))}
                        </Grid>
                      </CardContent>
                      <Divider
                        variant="fullWidth"
                        style={{ background: '#BCCCDC', marginTop: '16px' }}
                      />
                      <CardContent>
                        <Typography className={classes.sectionTitle}>Devices</Typography>
                        <Grid className={classes.gridContainer} container spacing={1}>
                          {values?.hardware?.map((device, index) => (
                            <React.Fragment key={device.label}>
                              <Grid item xs={8}>
                                <Box className={classes.featureTitleSection}>
                                  <Field
                                    className={classes.counter}
                                    name={`hardware.${index}.quantity`}
                                    component={TextField}
                                    type="number"
                                    size="small"
                                    variant="outlined"
                                    inputProps={{ type: 'number', step: '1', min: '0' }}
                                    onChange={(e) => {
                                      handleRemoveDiscount(
                                        device,
                                        setFieldValue,
                                        index,
                                        'hardware',
                                      );
                                      setFieldValue(`hardware.${index}.quantity`, e.target.value);
                                    }}
                                  />
                                  <Typography className={classes.featureLabel}>
                                    {device.label}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={1} md={2}>
                                <Typography
                                  className={classes.fee}
                                  style={{
                                    color: device.quantity === 0 ? '#00000042' : 'inherit',
                                    textDecoration: device.discountedPrice
                                      ? 'line-through'
                                      : 'none',
                                  }}
                                >
                                  £{device.unitPrice}
                                </Typography>
                              </Grid>
                              <Grid item xs={3} md={2}>
                                {!('discountedPrice' in device) && device.quantity > 0 && (
                                  <Button
                                    className={classes.addDiscount}
                                    onClick={() => {
                                      handleDiscount(device, setFieldValue, index, 'hardware');
                                    }}
                                  >
                                    Discount
                                  </Button>
                                )}
                                {'discountedPrice' in device && (
                                  <div className={classes.discountField}>
                                    <Field
                                      className={classes.counter}
                                      name={`hardware.${index}.discountedPrice`}
                                      component={TextField}
                                      type="number"
                                      size="small"
                                      variant="outlined"
                                      InputProps={{
                                        startAdornment: '£',
                                        inputProps: { step: '1', min: '0' },
                                      }}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `hardware.${index}.discountedPrice`,
                                          Number(e.target.value),
                                        );
                                      }}
                                    />
                                    <IconButton
                                      size="small"
                                      onClick={() =>
                                        handleRemoveDiscount(
                                          device,
                                          setFieldValue,
                                          index,
                                          'hardware',
                                        )
                                      }
                                    >
                                      <MdClose />
                                    </IconButton>
                                  </div>
                                )}
                              </Grid>
                            </React.Fragment>
                          ))}
                        </Grid>
                      </CardContent>
                      <Divider
                        variant="fullWidth"
                        style={{ background: '#BCCCDC', marginTop: '16px' }}
                      />
                      <CardContent>
                        <Typography className={classes.sectionTitle}>Variable costs</Typography>
                        <Grid className={classes.gridContainer} container spacing={1}>
                          {values?.variableCosts?.map((cost, index) => (
                            <React.Fragment key={cost.label}>
                              <Grid item xs={8}>
                                <Box className={classes.featureTitleSection}>
                                  <Field
                                    name={`variableCosts.${index}.enabled`}
                                    component={Checkbox}
                                    type="checkbox"
                                    color="primary"
                                    checked={cost.enabled}
                                    size="small"
                                    disabled={checkIfDisabled(values, cost, setFieldValue)}
                                    onChange={(e) => {
                                      if (cost.discountedPrice && e.target.checked === false) {
                                        handleRemoveDiscount(
                                          cost,
                                          setFieldValue,
                                          index,
                                          'fixedCovariableCostssts',
                                        );
                                      }
                                      setFieldValue(
                                        `variableCosts.[${index}].enabled`,
                                        e.target.checked,
                                      );
                                    }}
                                  />
                                  <Typography className={classes.featureLabel}>
                                    {updateLabel(cost.label)}
                                    {/* {cost.label === 'External Deposits' ? 'Design my night' : cost.label} */}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={1} md={2}>
                                <Typography
                                  className={classes.fee}
                                  style={{
                                    textDecoration: cost.discountedPrice ? 'line-through' : 'none',
                                  }}
                                >
                                  {cost.label !== 'External Deposits' ? '£' : ''}
                                  {cost.unitPrice}
                                  {cost.label === 'External Deposits' ? '%' : ''}
                                </Typography>
                              </Grid>
                              <Grid item xs={3} md={2}>
                                {!('discountedPrice' in cost) && (
                                  <Button
                                    className={classes.addDiscount}
                                    onClick={() => {
                                      handleDiscount(cost, setFieldValue, index, 'variableCosts');
                                    }}
                                  >
                                    Discount
                                  </Button>
                                )}
                                {'discountedPrice' in cost && (
                                  <div className={classes.discountField}>
                                    <Field
                                      className={classes.counter}
                                      name={`variableCosts.${index}.discountedPrice`}
                                      component={TextField}
                                      InputProps={{
                                        startAdornment:
                                          cost.label === 'External Deposits' ? '' : '£',
                                        inputProps: { step: '1', min: '0' },
                                        endAdornment: cost.label === 'External Deposits' ? '%' : '',
                                      }}
                                      type="number"
                                      size="small"
                                      variant="outlined"
                                      onChange={(e) => {
                                        setFieldValue(
                                          `variableCosts.${index}.discountedPrice`,
                                          Number(e.target.value),
                                        );
                                      }}
                                    />
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        handleRemoveDiscount(
                                          cost,
                                          setFieldValue,
                                          index,
                                          'variableCosts',
                                        );
                                      }}
                                    >
                                      <MdClose />
                                    </IconButton>
                                  </div>
                                )}
                              </Grid>
                            </React.Fragment>
                          ))}
                        </Grid>
                      </CardContent>
                    </Card>
                    <TotalCard values={values} />
                  </Box>
                </Form>
              </>
            )}
          </Formik>
        </Page>
      ) : (
        <Page loading={loading}>
          <br />
          No subscription
        </Page>
      )}
    </>
  );
};

export default PlanSettings;
