import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
  fetchPageAsyncReducerHandler,
} from '../utils/async';
import { fetchTabs as fetchTabsApi, fetchTab as fetchTabApi } from '../../api/tabs';

export const fetchTabs = createAsyncAction('fetch-tabs', fetchTabsApi);
export const fetchTabsPage = createAsyncAction('fetch-tabs-page', fetchTabsApi);

export const fetchTab = createAsyncAction('fetch-tab', fetchTabApi);

const tabsSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    clearTabs(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchTabs),
    ...fetchPageAsyncReducerHandler(fetchTabsPage),
  },
});

const tabSlice = createSlice({
  name: 'tab',
  initialState,
  reducers: {
    clearTab(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchTab),
  },
});
export const { clearTabs } = tabsSlice.actions;
export const { clearTab } = tabSlice.actions;

export const { reducer: tabs } = tabsSlice;
export const { reducer: tab } = tabSlice;
