import React, { useEffect, useState } from 'react';
import { fetchStripeConnectAccount } from '../../../api/stripe';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import StripePaymentDetails from './StripePaymentDetails';

const StripeAccountContainer = ({ redirect }) => {
  const { showErrorNotification } = useNotifications();
  const [accountDetails, setAccountDetails] = useState(null);

  useEffect(() => {
    const getAccountDetails = async () => {
      try {
        const data = await fetchStripeConnectAccount();
        setAccountDetails(data);
      } catch (err) {
        showErrorNotification(err.response.data);
      }
    };

    getAccountDetails();
  }, [showErrorNotification]);

  if (!accountDetails) {
    return null;
  }

  return <StripePaymentDetails accountDetails={accountDetails} redirect={redirect} />;
};

export default StripeAccountContainer;
