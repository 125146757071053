import React, { useCallback, useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';
import { Button, Card, CardContent, Grid, LinearProgress, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Task from './Task';
import withVenue from '../../hoc/withVenue';
import { getIntroTasksState } from '../../store/introTasks/selectors';
import { clearTasks, fetchIntroTasks, updateIntroTasks } from '../../store/introTasks';
import WelcomeDialog from './WelcomeDialog';
import useHasChanged from '../../hooks/useHasChanged';
import KebabMenu from '../../components/KebabMenu';
import { isDeliveryOnly } from '../../store/venues/selectors';

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'Sen',
    fontWeight: 'bold',
    flexGrow: 1,
    marginBottom: '1.5rem',
  },
  progress: {
    marginTop: '0.2rem',
    marginBottom: '1rem',
    fontSize: '0.8rem',
  },
  icon: {
    marginLeft: 'auto',
    flexBasis: 0,
    paddingRight: 0,
  },
  remove: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
}));

const TasksCard = ({ isBrandup, openExternalLink, currentVenueId }) => {
  const hasVenueIdChanged = useHasChanged(currentVenueId);

  const classes = useStyles();
  const dispatch = useDispatch();

  const taskActions = {
    NONE: 'NONE',
    LINK_TO_VENUE: '/settings/business',
    LINK_TO_SETTINGS: '/settings',
    LINK_TO_PRODUCTS: '/items',
    LINK_TO_GUINNESS_PRODUCT: '/menu-items/1',
    LINK_TO_CORONA_PRODUCT: '/menu-items/15',
    LINK_TO_MENU: '/menus',
    LINK_TO_QR: '/qrcodes',
    OPEN_QR_ORDER: openExternalLink,
  };

  const [tasklist, setTasklist] = useState(null);
  const [isWelcomeOpen, setIsWelcomeOpen] = useState(false);
  const [removed, setRemoved] = useState(false);

  const { loading: tasksLoading, data: tasksData } = useSelector(getIntroTasksState);
  useEffect(() => {
    if (hasVenueIdChanged) {
      dispatch(fetchIntroTasks());
    }
  }, [hasVenueIdChanged, dispatch]);

  useEffect(() => {
    dispatch(fetchIntroTasks());
    return () => {
      dispatch(clearTasks());
    };
  }, [dispatch]);

  useEffect(() => {
    if (tasksLoading || !tasksData) return;
    const { tasks } = tasksData || {};
    const rawTasklist = [...(tasks || [])];
    const [welcomeTask] = rawTasklist.splice(
      rawTasklist.findIndex((item) => item.name === 'WELCOME'),
      1,
    );
    if (!welcomeTask) return;
    const { done: isWelcomeClosed, id } = welcomeTask || {};
    setIsWelcomeOpen(!(isWelcomeClosed ?? true));
    setTasklist(rawTasklist);
    if (!isWelcomeClosed && welcomeTask.handler === 'FE') dispatch(updateIntroTasks(id));
  }, [dispatch, tasksData, tasksLoading]);

  const progressCount = tasklist?.filter((task) => task.done).length;

  const progressTotal = tasklist?.length;
  const progress = 100 * (progressCount / tasklist?.length);

  const isComplete = progressCount === progressTotal;
  const setTaskCompleted = useCallback(
    (id) => {
      const task = tasklist.find((item) => item.id === id);
      if (task.handler === 'FE') dispatch(updateIntroTasks(id));
    },
    [dispatch, tasklist],
  );

  const handleRemoveSection = useCallback(() => {
    setRemoved(true);
  }, []);

  const closeWelcome = () => {
    setIsWelcomeOpen(false);
  };

  return !isDeliveryOnly() && !removed && !isComplete ? (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Grid container alignItems="flex-start">
            <Grid item xs={10}>
              <Typography className={classes.title} variant="h4">
                You can get started by completing these tasks
              </Typography>
            </Grid>
            <Grid item xs={2} className={classes.icon}>
              <KebabMenu
                items={[
                  {
                    name: 'Remove tasks section',
                    handler: () => {
                      handleRemoveSection();
                    },
                  },
                ]}
              />
            </Grid>
          </Grid>
          <LinearProgress variant="determinate" value={progress} />

          <Typography variant="body2" className={classes.progress}>
            {!isComplete ? `${progressCount} out of ${progressTotal} steps complete` : 'All done!'}
          </Typography>
          {isComplete && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleRemoveSection}
              className={classes.remove}
            >
              Remove tasks section
            </Button>
          )}
          {!isComplete && (
            <>
              {tasklist.map(({ id, title, description, done, label, action }) => (
                <Task
                  key={id}
                  completed={done}
                  title={title}
                  description={description === 'NONE' ? null : description}
                  buttonTitle={label === 'NONE' ? null : label}
                  buttonLink={taskActions[action]}
                  setItem={() => setTaskCompleted(id)}
                />
              ))}
            </>
          )}
        </CardContent>
      </Card>
      <WelcomeDialog open={isWelcomeOpen} onClose={closeWelcome} isBrandup={isBrandup} />
    </Grid>
  ) : (
    <></>
  );
};
export default withVenue(TasksCard);
