import React from 'react';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import { Button, Tooltip } from '@mui/material';
import { nanoid } from 'nanoid';
import moment from 'moment-timezone';
import { makeStyles } from '@mui/styles';
import { getCardPayoutsState } from '../../store/payouts-card/selectors';
import TableCellStatus, { tableCellStatus } from '../TableCellStatus';
import TableSkeletonLoader from '../TableSkeletonLoader';
import TablePaginationActions from '../TablePaginationActions';
import useRoles from '../../hooks/useRoles';

const showRepay = (status) => status === 'FAILED' || status === 'RETURNED';

const useStyles = makeStyles(() => ({
  body: {
    maxWidth: '1052px',
  },
}));

const CardPayoutsTable = ({ pagination, handleRetryPayout }) => {
  const classes = useStyles();
  const { page, handleChangePage, rowsPerPage } = pagination;
  const { loading, data: payouts } = useSelector(getCardPayoutsState);
  const { isSuperAdmin } = useRoles();
  const { docs } = payouts || {};
  const payoutsTotal = payouts?.total;
  const hasPayouts = !!payouts?.docs.length;

  if (!payouts) return null;

  const PayoutStatus = {
    COMPLETED: <TableCellStatus status={tableCellStatus.SUCCESS} statusText="Completed" />,
    IN_PROGRESS: <TableCellStatus status={tableCellStatus.IDLE} statusText="In progress" />,
    IN_TRANSIT: <TableCellStatus status={tableCellStatus.IDLE} statusText="In transit" />,
    NOT_STARTED: <TableCellStatus status={tableCellStatus.STALL} statusText="Not started" />,
    FAILED: <TableCellStatus status={tableCellStatus.FAIL} statusText="Failed" />,
    RETURNED: <TableCellStatus status={tableCellStatus.IDLE} statusText="Returned" />,
  };

  if (!payouts) return null;

  return (
    <TableContainer className={classes.body} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Report Day</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Charge</TableCell>
            {isSuperAdmin() && <TableCell> </TableCell>}
          </TableRow>
        </TableHead>
        {loading && <TableSkeletonLoader rows={10} cells={3} cellsSize={['small']} />}
        {!loading && hasPayouts && (
          <>
            <TableBody>
              {docs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((payout) => {
                const key = nanoid();
                const amount = payout.amount.toLocaleString();
                const reportAmount = payout.reportAmount.toLocaleString();
                const { debitId } = payout;
                const debit = payout.debit && payout.debit !== '0.00' ? `£${payout.debit}` : 'n/a';
                const date = moment(payout.date).local().format('DD/MM/YY');

                return (
                  <TableRow key={key}>
                    <TableCell>{date}</TableCell>
                    <TableCell>
                      <Tooltip
                        title={`Original Report Amount: ${reportAmount} `}
                        aria-label={`Original Report Amount: ${reportAmount} `}
                      >
                        <span>£{amount}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{PayoutStatus[payout.status]}</TableCell>
                    <TableCell>
                      {debitId ? (
                        <Tooltip title={`Debit: ${debitId} `} aria-label={`Debit: ${debitId}`}>
                          <span>{debit}</span>
                        </Tooltip>
                      ) : (
                        debit
                      )}
                    </TableCell>
                    {isSuperAdmin() && (
                      <TableCell>
                        {showRepay(payout.status) && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              handleRetryPayout({ day: payout.date });
                            }}
                          >
                            Repay
                          </Button>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </>
        )}
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              count={payoutsTotal}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default CardPayoutsTable;
