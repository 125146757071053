import React, { useState } from 'react';
import { Grid, IconButton, TextField, Popover, Box, Button } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { MdAdd, MdContentCopy, MdDelete, MdMoreVert } from 'react-icons/md';
import { generateTimeOptions } from '../../../../shared/utils/generateDeliveryTimeOptions';
import { formatTimeString } from '../../../../shared/utils/deliveryOpeningHours';

const getSelectedTime = (hour, minute, options) => {
  if (hour === null || hour === undefined || minute === null || minute === undefined) return null;
  return options.find((option) => option.time === formatTimeString(hour, minute));
};

const normaliseTime = (time) => {
  const [hour, minute] = time.split(':');
  const nextDay = hour < 5 || (hour === 0 && minute >= 15);
  return { hour: parseInt(hour, 10), minute: parseInt(minute, 10), nextDay };
};

const handleFieldValueChange = ({ setFieldValue, dayIndex, segmentIndex, value, isTo = false }) => {
  const normalisedTime = normaliseTime(value.time);
  setFieldValue(
    `[${dayIndex}].openingHours[${segmentIndex}].${isTo ? 'toHour' : 'fromHour'}`,
    normalisedTime.hour,
  );
  setFieldValue(
    `[${dayIndex}].openingHours[${segmentIndex}].${isTo ? 'toMinute' : 'fromMinute'}`,
    normalisedTime.minute,
  );
  setFieldValue(`[${dayIndex}].openingHours[${segmentIndex}].nextDay`, normalisedTime.nextDay);
};

const TimeSegmentControl = ({
  previousSegment,
  segmentHours,
  segmentIndex,
  dayIndex,
  setFieldValue,
  disabled = false,
  handleNewSegment,
  handleRemoveSegment,
  handleCopyHours,
}) => {
  const [anchorEl, setPopoverEl] = useState(null);
  const [openPopover, setOpenPopover] = useState(false);
  const { fromHour, fromMinute, toHour, toMinute } = segmentHours;

  const openTimeOptions = generateTimeOptions({
    currH: fromHour,
    currM: fromMinute,
    prevH: previousSegment?.toHour,
    prevM: previousSegment?.toMinute,
  });

  const closeTimeOptions = generateTimeOptions({
    currH: toHour,
    currM: toMinute,
    prevH: fromHour,
    prevM: fromMinute,
  });

  const selectedTime = {
    openSegment: getSelectedTime(fromHour, fromMinute, openTimeOptions),
    closeSegment: getSelectedTime(toHour, toMinute, closeTimeOptions),
  };

  const handleOpenPopover = (event) => {
    setPopoverEl(event.currentTarget);
    setOpenPopover(!openPopover);
  };

  return (
    <Grid container item xs={12} spacing={2} key={segmentIndex} style={{ marginBottom: 8 }}>
      <Grid item xs={5}>
        <Autocomplete
          options={openTimeOptions}
          getOptionLabel={(option) => option.time + (option.isNextDay ? ' (next day)' : '')}
          value={selectedTime.openSegment}
          onChange={(_event, value) => {
            if (!value) return;
            handleFieldValueChange({ setFieldValue, dayIndex, segmentIndex, value, isTo: false });
          }}
          getOptionDisabled={(_option, index) => index === 1}
          renderInput={(params) => (
            <TextField {...params} label="Open" variant="outlined" key="Open" />
          )}
          fullWidth
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          options={closeTimeOptions}
          getOptionLabel={(option) => option.time + (option.isNextDay ? ' (next day)' : '')}
          value={selectedTime.closeSegment}
          onChange={(_event, value) => {
            if (!value) return;
            handleFieldValueChange({ setFieldValue, dayIndex, segmentIndex, value, isTo: true });
          }}
          getOptionDisabled={(_option, index) => index === 1}
          renderInput={(params) => (
            <TextField {...params} label="Close" variant="outlined" key="Close" />
          )}
          fullWidth
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={1} container justifyContent="flex-start" alignItems="center">
        {segmentIndex === 0 ? (
          <>
            <IconButton
              onClick={(event) => handleOpenPopover(event)}
              size="small"
              disabled={disabled}
              id={`${dayIndex}-popover`}
            >
              <MdMoreVert />
            </IconButton>
            <Popover
              id={`${dayIndex}-popover`}
              open={openPopover}
              anchorEl={anchorEl}
              onClose={() => {
                setOpenPopover(false);
                setPopoverEl(null);
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box padding={1} display="flex" flexDirection="column">
                <Button
                  onClick={() => {
                    handleNewSegment();
                    setOpenPopover(false);
                    setPopoverEl(null);
                  }}
                  size="small"
                >
                  <MdAdd /> Add hours
                </Button>
                <Button
                  onClick={() => {
                    handleCopyHours(dayIndex);
                    setOpenPopover(false);
                    setPopoverEl(null);
                  }}
                >
                  <MdContentCopy /> Copy hours
                </Button>
              </Box>
            </Popover>
          </>
        ) : (
          <IconButton onClick={handleRemoveSegment} size="small" disabled={disabled}>
            <MdDelete />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default TimeSegmentControl;
