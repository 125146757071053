import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { Typography, Button, Box, Grid } from '@mui/material';
import { Buffer } from 'buffer';

import { makeStyles } from '@mui/styles';
import Page from '../../components/Page';
import CardPayoutsTable from '../../components/CardPayoutsTable';
import ExternalPayoutsTable from '../../components/ExternalPayoutsTable';
import ESPicker from '../../components/ESPicker';
import ExternalPayoutsDialogue from '../../components/ExternalPayoutsTable/ExternalPayoutsDialogue';

import { getCardPayoutsState } from '../../store/payouts-card/selectors';
import { getVenueState } from '../../store/venues/selectors';
import {
  clearPayouts,
  fetchCardPayouts,
  fetchCardPayoutsPage,
  retryPayout,
} from '../../store/payouts-card';
import {
  clearExternalPayouts,
  fetchExternalPayouts,
  fetchExternalPayoutsPage,
} from '../../store/payouts-external';

import withVenue from '../../hoc/withVenue';
import usePagination from '../../hooks/usePagination';
import useESPicker from '../../hooks/useESPicker';

import { getIdToken } from '../../cognito/sessionTokens';

import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import toUTC from '../../shared/utils/toUTC';
import { getExternalPayoutsState } from '../../store/payouts-external/selectors';
import shouldLoad from '../../shared/utils/shouldLoad';
import TabContainer from '../../components/TabContainer';
import PageHeader from '../../components/PageHeader';

const useStyles = makeStyles(() => ({
  header: {
    marginBottom: 4,
  },
}));

const PayoutsSection = () => {
  const classes = useStyles();
  const [currentPayout, setCurrentPayout] = useState(null);
  const [isPayoutsDialogueOpen, setIsPayoutsDialogueOpen] = useState(false);
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { data: venue } = useSelector(getVenueState);
  const cardPayoutsState = useSelector(getCardPayoutsState);
  const externalPayoutsState = useSelector(getExternalPayoutsState);
  const {
    loading: cardPayoutsLoading,
    data: cardPayouts,
    error: cardPayoutsError,
  } = cardPayoutsState;
  const {
    loading: externalPayoutsLoading,
    data: externalPayouts,
    error: externalPayoutsError,
  } = externalPayoutsState;
  const idToken = getIdToken();
  const dispatch = useDispatch();
  const [isExternalTab, setIsExternalTab] = useState(false);

  const cardPayoutsPagination = usePagination(10);
  const externalPayoutsPagination = usePagination(10);
  const esPicker = useESPicker({ useOrder: false, useSearch: false, useReportingType: false });
  const { selectedDates } = esPicker;
  const {
    first: cardPayoutFirst,
    requestNewPage: cardPayoutRequestNewPage,
    rowsPerPage: cardPayoutRowsPerPage,
    resetPagination: cardPayoutResetPagination,
  } = cardPayoutsPagination;
  const {
    first: externalPayoutFirst,
    requestNewPage: externalPayoutRequestNewPage,
    rowsPerPage: externalPayoutRowsPerPage,
    resetPagination: externalPayoutResetPagination,
  } = externalPayoutsPagination;
  const isPayoutsLoading =
    cardPayoutsLoading && !cardPayouts && externalPayoutsLoading && !externalPayouts;
  const isPayoutsError = cardPayoutsError || externalPayoutsError;

  const handleFetchPayoutsCardPage = useCallback(() => {
    dispatch(
      fetchCardPayoutsPage({
        from: cardPayoutFirst,
        size: cardPayoutRowsPerPage,
        gte: toUTC(selectedDates.from),
        lte: toUTC(selectedDates.to),
      }),
    );
  }, [dispatch, cardPayoutFirst, cardPayoutRowsPerPage, selectedDates]);

  const handleFetchPayoutsExternalPage = useCallback(() => {
    dispatch(
      fetchExternalPayoutsPage({
        from: externalPayoutFirst,
        size: externalPayoutRowsPerPage,
        gte: toUTC(selectedDates.from),
        lte: toUTC(selectedDates.to),
      }),
    );
  }, [dispatch, externalPayoutFirst, externalPayoutRowsPerPage, selectedDates]);

  const getCardPayoutsCardData = useCallback(() => {
    cardPayoutResetPagination();
    dispatch(
      fetchCardPayouts({
        first: 0,
        size: cardPayoutRowsPerPage,
        gte: toUTC(selectedDates.from),
        lte: toUTC(selectedDates.to),
      }),
    );
  }, [dispatch, cardPayoutResetPagination, cardPayoutRowsPerPage, selectedDates]);

  const getExternalPayoutsCardData = useCallback(() => {
    externalPayoutResetPagination();
    dispatch(
      fetchExternalPayouts({
        first: 0,
        size: externalPayoutRowsPerPage,
        gte: toUTC(selectedDates.from),
        lte: toUTC(selectedDates.to),
      }),
    );
  }, [dispatch, externalPayoutResetPagination, externalPayoutRowsPerPage, selectedDates]);

  const handleAddTransaction = () => {
    setCurrentPayout(null);
    setIsPayoutsDialogueOpen(true);
  };

  useEffect(() => {
    if (shouldLoad(cardPayoutsState)) getCardPayoutsCardData();
    if (shouldLoad(externalPayoutsState)) getExternalPayoutsCardData();
  }, [cardPayoutsState, externalPayoutsState, getCardPayoutsCardData, getExternalPayoutsCardData]);

  useEffect(() => {
    getCardPayoutsCardData();
    getExternalPayoutsCardData();
  }, [selectedDates, getCardPayoutsCardData, getExternalPayoutsCardData]);

  useEffect(() => {
    if (cardPayoutRequestNewPage) {
      handleFetchPayoutsCardPage();
    }
  }, [cardPayoutRequestNewPage, handleFetchPayoutsCardPage]);

  useEffect(() => {
    if (externalPayoutRequestNewPage) {
      handleFetchPayoutsExternalPage();
    }
  }, [externalPayoutRequestNewPage, handleFetchPayoutsExternalPage]);

  const handleRetryPayout = async (values) => {
    try {
      await dispatch(retryPayout(values));
      await dispatch(
        fetchCardPayouts({
          first: 0,
          size: cardPayoutRowsPerPage,
          gte: toUTC(selectedDates.from),
          lte: toUTC(selectedDates.to),
        }),
      );
      showSuccessNotification('Retried payout');
    } catch (err) {
      showErrorNotification(getErrorMessage(err));
    }
  };

  const handleOpenPayoutEditDialogue = (payout) => {
    setCurrentPayout(payout);
    setIsPayoutsDialogueOpen(true);
  };

  const handlePayoutsDownload = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URI}/venues/${venue.venueId}/banking-slips/export`;
      const { data: response } = await axios.get(url, {
        headers: {
          Authorization: idToken,
        },
      });
      const decoded = Buffer.from(response, 'base64');
      const fileBlob = new Blob([decoded]);

      showSuccessNotification('Banking slips export downloaded');

      return fileDownload(fileBlob, `banking-slips-export.xlsx`);
    } catch (error) {
      showErrorNotification('There was an issue downloading your Banking slips');

      return false;
    }
  };

  const handleSubmitPayout = async () => {
    // TODO: interim solution to cope with ES sync.
    const maxTimeout = 5000;
    const minTimeout = 3000;
    const randomisedTimeout = Math.floor(Math.random() * (maxTimeout - minTimeout)) + minTimeout;

    await new Promise((resolve) => setTimeout(resolve, randomisedTimeout));

    getExternalPayoutsCardData();
  };

  const tabChange = (tabTitle) => {
    setIsExternalTab(tabTitle === 'External');
  };

  return (
    <>
      <PageHeader>
        <div className={classes.header}>
          <Typography noWrap variant="h2" component="h1">
            Payouts
          </Typography>
          <ESPicker esPicker={esPicker} />
        </div>
      </PageHeader>
      <Page loading={isPayoutsLoading} error={isPayoutsError}>
        <Box component="header" mb={4}>
          {isExternalTab && (
            <Grid container direction="row" justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button variant="contained" color="primary" onClick={handleAddTransaction}>
                  Add Transaction
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" color="primary" onClick={handlePayoutsDownload}>
                  Export
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
        <TabContainer titles={['Card', 'External']} onChange={tabChange}>
          <CardPayoutsTable
            pagination={cardPayoutsPagination}
            handleRetryPayout={handleRetryPayout}
          />
          <ExternalPayoutsTable
            pagination={externalPayoutsPagination}
            handleOpenPayoutEditDialogue={handleOpenPayoutEditDialogue}
          />
        </TabContainer>
        <ExternalPayoutsDialogue
          payout={currentPayout}
          isOpen={isPayoutsDialogueOpen}
          setIsOpen={setIsPayoutsDialogueOpen}
          onSubmit={handleSubmitPayout}
        />
      </Page>
    </>
  );
};

export default withVenue(PayoutsSection, null, [clearPayouts, clearExternalPayouts]);
