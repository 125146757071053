import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { Switch } from 'formik-mui';

import {
  Box,
  Typography,
  Card,
  IconButton,
  Button,
  Chip,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { MdDelete } from 'react-icons/md';

import { pick } from 'lodash';
import { makeStyles } from '@mui/styles';
import { useHistory, useParams } from 'react-router';
import OrderableTable from '../../components/OrderableTable';
import UniversalSave from '../../components/UniversalSave';
import TableButton from '../../components/TableButton/index';
import {
  createIngredient,
  fetchIngredients,
  updateIngredient,
  updateIngredientsAvailability,
  deleteIngredient,
} from '../../store/ingredients';
import { getIngredientsState } from '../../store/ingredients/selectors';

import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import shouldLoad from '../../shared/utils/shouldLoad';
import CustomDialog from '../../components/CustomDialog';
import IngredientForm from '../../components/IngredientForm';
import useRoles from '../../hooks/useRoles';
import PageHeader from '../../components/PageHeader';
import { filterDataItems } from '../../shared/utils/filterData';
import useSearch from '../../hooks/useSearch';
import isDesktop from '../../shared/utils/isDesktop';

const useStyles = makeStyles((theme) => ({
  buttonClass: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: '10px',
  },
  searchInput: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  tableControlsBox: {
    display: 'flex',
    alignItems: 'center',
    margin: `${theme.spacing(2)} 0`,
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
  searchAndButtonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
  paragraph: {
    paddingTop: '20px',
  },
  button: {
    textWrap: 'nowrap',
    paddingLeft: '10px',
    marginLeft: 'auto',
    [theme.breakpoints.up('xl')]: {
      marginLeft: 'unset',
    },
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  readonlyChip: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginTop: theme.spacing(1),
    },
  },
}));

const FormObserver = ({ setFieldValue, setValueData }) => {
  const { values, dirty } = useFormikContext();
  useEffect(() => {
    setValueData(values);
  }, [values, dirty, setFieldValue, setValueData]);
  return null;
};

const findArrayIndex = (valueData, row) => {
  if (valueData && row) {
    const index = valueData.findIndex((obj) => obj.ingredientId === `${row.ingredientId}`);
    return index;
  }
  return null;
};

const Ingredients = () => {
  const sessionStorageFilters = JSON.parse(sessionStorage.getItem('ingredientsFilter'));
  const classes = useStyles();
  const dispatch = useDispatch();
  const ingredientsState = useSelector(getIngredientsState);
  const history = useHistory();
  const { itemId: ingredientParamId } = useParams() || {};
  const { loading, data, error } = ingredientsState;

  const [typeCheckboxState, setTypeCheckboxState] = useState({
    DRINK: true,
    FOOD: true,
  });
  const [availabilityCheckboxState, setAvailabilityCheckboxState] = useState({
    TRUE: true,
    FALSE: true,
  });
  const initialFilters = useMemo(
    () => [
      { types: Object.keys(typeCheckboxState), available: Object.keys(availabilityCheckboxState) },
    ],
    [typeCheckboxState, availabilityCheckboxState],
  );
  const [filters, setFilters] = useState(sessionStorageFilters || []);
  const [valueData, setValueData] = useState(data);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [ingredientData, setIngredientData] = useState(null);
  const [formAction, setFormAction] = useState('');
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { isRoleAtLeastManager } = useRoles();
  const searchKeys = useMemo(() => ['ingredientName', 'label'], []);
  const threshold = 0.2;

  const { searchResults, searchError, handleSearch, filteredItems, setFilteredItems } = useSearch(
    data,
    searchKeys,
    threshold,
    valueData,
    fetchIngredients,
  );

  const handleAvailableSwitch = useCallback(
    async (values) => {
      try {
        await dispatch(updateIngredientsAvailability({ values }));
        showSuccessNotification('Ingredient availability has been updated successfully');
      } catch (localError) {
        showErrorNotification(getErrorMessage(localError));
      }
    },
    [dispatch, showErrorNotification, showSuccessNotification],
  );

  const handleEdit = useCallback(
    (ingredient) => {
      // When calling this function, a check is first made to see if the ingredient is an id, not an object
      if (ingredientParamId && data) {
        const ingrToUpdate = data.find((obj) => obj.ingredientId === ingredientParamId);
        setFormAction('update');
        setIngredientData(ingrToUpdate);
        setIsDialogOpen(true);
        return;
      }
      setFormAction('update');
      setIngredientData(ingredient);
      setIsDialogOpen(true);
    },
    [data, ingredientParamId],
  );

  const handleDelete = useCallback(
    async (ingredientId) => {
      try {
        await dispatch(deleteIngredient(ingredientId));
        await dispatch(fetchIngredients());
        showSuccessNotification('Ingredient has been deleted successfully');
      } catch (err) {
        showErrorNotification(getErrorMessage(err));
      }
    },
    [dispatch, showErrorNotification, showSuccessNotification],
  );

  const valueFormatter = useCallback(
    ({ value, valueName, row }) => {
      switch (valueName) {
        case 'ingredientName':
          return (
            <Box className={classes.labelContainer}>
              {row.readonly ? (
                <>
                  <Typography sx={{ mr: 1 }} variant="subtitle">
                    {value}
                  </Typography>
                  <Chip label="Read Only" size="small" className={classes.readonlyChip} />
                </>
              ) : (
                <TableButton
                  onClick={() => {
                    handleEdit(row);
                  }}
                >
                  {row.ingredientName}
                </TableButton>
              )}
            </Box>
          );
        case 'delete':
          return (
            <IconButton
              edge="end"
              size="small"
              onClick={() => handleDelete(row.ingredientId)}
              disabled={row?.readonly}
            >
              <MdDelete />
            </IconButton>
          );
        case 'available':
          if (valueData) {
            const index = findArrayIndex(valueData, row);
            const isChecked = valueData[index]?.available || false;
            return (
              <Field
                name={`[${index}].available`}
                component={Switch}
                checked={isChecked}
                value={!isChecked}
                color="primary"
                type="checkbox"
              />
            );
          }
          return null;
        default:
          return value;
      }
    },
    [classes.labelContainer, classes.readonlyChip, handleDelete, handleEdit, valueData],
  );

  const newData = useCallback(() => {
    const pickedData = [];
    if (filteredItems) {
      filteredItems.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item = {
          ...item,
          label: item.label || item.ingredientName,
          delete: 'delete',
        };
        pickedData.push(
          pick(item, [
            'ingredientName',
            'label',
            'available',
            'type',
            'ingredientId',
            'delete',
            'isAlcoholic',
            'readonly',
          ]),
        );
      });
    }
    return pickedData;
  }, [filteredItems]);

  const handleOnSubmit = async (values) => {
    if (formAction === 'create') {
      try {
        await dispatch(createIngredient(values));
        await dispatch(fetchIngredients());
        showSuccessNotification('Ingredient has been added successfully');
        setIsDialogOpen(false);
      } catch (e) {
        showErrorNotification(getErrorMessage(e));
        setIsDialogOpen(false);
      }
    }

    if (formAction === 'update') {
      try {
        const { ingredientId } = ingredientData;
        await dispatch(updateIngredient({ ingredientId, values }));
        if (ingredientParamId) {
          history.goBack();
          await dispatch(fetchIngredients());
          showSuccessNotification('Ingredient has been updated successfully');
          return;
        }
        await dispatch(fetchIngredients());
        showSuccessNotification('Ingredient has been updated successfully');
        setIsDialogOpen(false);
      } catch (newError) {
        showErrorNotification(getErrorMessage(newError));
      }
    }
  };

  const handleOpenDialog = () => {
    setIngredientData(null);
    setFormAction('create');
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    if (ingredientParamId) {
      history.goBack();
    }
  };

  const dialogTitle = useMemo(() => {
    if (formAction === 'update') {
      return 'Update ingredient';
    }
    return 'Create new ingredient';
  }, [formAction]);

  const handleFilterChange = (_e, filterName) => {
    const { name, checked } = _e.target;
    let filterKeys;
    if (filterName === 'types') {
      const newTypeState = { ...typeCheckboxState, [name]: checked };
      setTypeCheckboxState(newTypeState);
      filterKeys = Object.keys(newTypeState).filter((typeKey) => newTypeState[typeKey]);
    }

    if (filterName === 'available') {
      const newAvailabilityState = { ...availabilityCheckboxState, [name]: checked };
      setAvailabilityCheckboxState(newAvailabilityState);
      filterKeys = Object.keys(newAvailabilityState).filter(
        (availabilityKey) => newAvailabilityState[availabilityKey],
      );
    }

    const existingFilterData = sessionStorageFilters ? sessionStorageFilters[0] : initialFilters[0];

    const updatedFilter = [
      {
        ...existingFilterData,
        [filterName]: filterKeys,
      },
    ];

    setFilters(updatedFilter);
    sessionStorage.setItem('ingredientsFilter', JSON.stringify(updatedFilter));
    setFilteredItems(filterDataItems(updatedFilter, data));
    dispatch(fetchIngredients());
  };

  const searchFieldAndButton = () => (
    <Grid
      className={classes.searchAndButtonWrapper}
      container
      item
      xs={12}
      sm={12}
      md={6}
      lg={4}
      xl={2}
      spacing={2}
    >
      <Grid item xs={12} sm={12} md={10} lg={12} className={classes.box}>
        <Box>
          <TextField
            className={classes.searchInput}
            label="Search Ingredients"
            variant="outlined"
            size="small"
            helperText={searchError}
            onChange={handleSearch}
          />
        </Box>
        <Box className={classes.button}>
          <Button
            variant="contained"
            color="primary"
            disabled={!isRoleAtLeastManager()}
            onClick={handleOpenDialog}
          >
            New Ingredient
          </Button>
        </Box>
        <Box>
          <aside>
            <CustomDialog
              isDialogOpen={isDialogOpen}
              handleCloseDialog={handleCloseDialog}
              title={dialogTitle}
            >
              <IngredientForm
                formAction={formAction}
                ingredientData={ingredientData}
                onSubmit={handleOnSubmit}
                onCancel={handleCloseDialog}
              />
            </CustomDialog>
          </aside>
        </Box>
      </Grid>
    </Grid>
  );

  useEffect(() => {
    if (shouldLoad(ingredientsState)) dispatch(fetchIngredients());
    setFilteredItems(filterDataItems(filters, searchResults || data));
  }, [data, dispatch, filters, ingredientsState, searchResults, setFilteredItems]);

  // To check if param for ingredient id is present in URL
  useEffect(() => {
    if (ingredientParamId) {
      handleEdit(ingredientParamId);
    }
  }, [handleEdit, ingredientParamId]);

  return (
    <>
      <PageHeader fullWidth>
        <Grid container className={classes.tableControlsBox} direction="row">
          <Grid container item spacing={2} xs={12} sm={12} md={12} direction="row">
            {!isDesktop && searchFieldAndButton()}

            {data && data.length > 0 && filters && (
              <Grid container spacing={2} item xs={12} sm={12} md={6} lg={8} xl={10}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={typeCheckboxState.DRINK}
                        onChange={(_e) => handleFilterChange(_e, 'types')}
                        inputProps={{ 'aria-label': 'drink' }}
                        id="drink-type-checkbox"
                        name="DRINK"
                      />
                    }
                    label="DRINK"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={typeCheckboxState.FOOD}
                        onChange={(_e) => handleFilterChange(_e, 'types')}
                        inputProps={{ 'aria-label': 'food' }}
                        id="food-type-checkbox"
                        name="FOOD"
                      />
                    }
                    label="FOOD"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={availabilityCheckboxState.TRUE}
                        onChange={(_e) => handleFilterChange(_e, 'available')}
                        inputProps={{ 'aria-label': 'available' }}
                        id="available-checkbox"
                        name="TRUE"
                      />
                    }
                    label="AVAILABLE"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={availabilityCheckboxState.FALSE}
                        onChange={(_e) => handleFilterChange(_e, 'available')}
                        inputProps={{ 'aria-label': 'not-available' }}
                        id="not-available-checkbox"
                        name="FALSE"
                      />
                    }
                    label="NOT AVAILABLE"
                  />
                </Grid>
              </Grid>
            )}

            {isDesktop && searchFieldAndButton()}
          </Grid>
          <Typography paragraph className={classes.paragraph}>
            Ingredients appear as options on products (e.g. mixers for spirits, flavours for ice
            cream)
          </Typography>
        </Grid>
      </PageHeader>
      <Page loading={loading} error={error} fullWidth>
        {filteredItems && filteredItems.length === 0 && (
          <Box style={{ padding: 14 }}>
            <Typography align="center" variant="h2" color="textSecondary">
              No ingredients found
            </Typography>
          </Box>
        )}
        {data && (
          <>
            {filteredItems && filteredItems.length > 0 && (
              <Card>
                <Formik initialValues={[...newData()]}>
                  {({ setFieldValue, values, dirty, resetForm, errors, isValid }) => (
                    <>
                      <UniversalSave
                        isValid={isValid}
                        errors={errors}
                        dirty={dirty}
                        onSave={() => handleAvailableSwitch(values)}
                        onDiscard={resetForm}
                      />
                      <Form>
                        <FormObserver setFieldValue={setFieldValue} setValueData={setValueData} />
                        <OrderableTable
                          tableData={[...newData()]}
                          titles={['INTERNAL NAME', 'EXTERNAL NAME', 'AVAILABLE', 'TYPE', '']}
                          keys={['ingredientId']}
                          excludeFields={['ingredientId', 'isAlcoholic', 'readonly']}
                          disableColumnTitles={['']}
                          values={values}
                          valueFormatter={valueFormatter}
                        />
                      </Form>
                    </>
                  )}
                </Formik>
              </Card>
            )}
          </>
        )}
      </Page>
    </>
  );
};

export default withVenue(Ingredients);
