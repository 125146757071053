import React, { useMemo, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Field, Form, Formik } from 'formik';
import { Switch } from 'formik-mui';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MdMoreHoriz } from 'react-icons/md';
import UniversalSave from '../UniversalSave';
import {
  clearUpsellGroupInitiatingItems,
  fetchUpsellGroups,
  updateUpsellGroup,
} from '../../store/upsellGroups';
import CustomUpsellOptions from './CustomUpsellOptions';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import isItemUnavailable from '../../shared/utils/isItemUnavailable';
import useItemsOnMenu from '../../hooks/useItemsOnMenu';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '8px',
    marginTop: '8px',
    marginBottom: '64px',
  },

  customUpsellsBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  customUpsellTitle: {
    ...theme.customFonts.small,
    marginBottom: '8px',
  },

  customUpsellText: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
    textAlign: 'center',
    marginTop: '32px',
    marginBottom: '32px',
  },

  customUpsellGroup: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
  },
  upsellGroupName: {
    color: theme.customPalette.trueBlue,
    textDecoration: 'underline',
  },
  upsellGroupSuggestedItems: {
    backgroundColor: theme.customPalette.greyLightest,
    borderRadius: '4px',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '0.5rem',
    padding: '4px',
    display: 'flex',
  },
  upsellGroupSuggestedItemText: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
  },
  moreButton: {
    border: `1px solid ${theme.customPalette.grey}`,
    borderRadius: '8px',
    padding: '4px',
    marginRight: '16px',
    color: theme.customPalette.midnight,
  },
  unavailableIcon: {
    width: '16px',
    height: '16px',
    marginLeft: '16px',
  },
}));

const CustomUpsellGroups = ({ menuItems, upsellGroups }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [formAction, setFormAction] = useState();
  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const { checkIfItemIsOnMenu } = useItemsOnMenu();

  const customUpsells = useMemo(
    () =>
      upsellGroups &&
      upsellGroups.filter(
        (upsellGroup) => upsellGroup.upsellGroupId !== '2' && upsellGroup.upsellGroupId !== '3',
      ),
    [upsellGroups],
  );

  const handleOpenDialog = (index) => {
    setIsDialogOpen(true);
    setSelectedIndex(index);
  };

  const handleCloseDialog = () => {
    setSelectedIndex(-1);
    setIsDialogOpen(false);
  };

  const handleOpenMenu = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleUpdateCustomUpsellGroup = async (upsellGroupId, data) => {
    try {
      await dispatch(
        updateUpsellGroup({
          upsellGroupId,
          data,
        }),
      );
      showSuccessNotification(`${data.name} renamed successfully`);
      handleCloseDialog();
      dispatch(fetchUpsellGroups());
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const handleUpdateCustomUpsellGroupAvailability = async (values) => {
    try {
      Promise.all(
        values.map(async (upsellGroup) => {
          const { upsellGroupId } = upsellGroup;
          const update = {
            enabled: upsellGroup.enabled,
            type: upsellGroup.type,
            name: upsellGroup.name,
            suggestedItemIds: upsellGroup.suggestedItemIds,
          };
          const data = { ...update };
          await dispatch(updateUpsellGroup({ upsellGroupId, data }));
        }),
      ).then(() => {
        dispatch(fetchUpsellGroups());
      });
      showSuccessNotification('Upsell group availability updated successfully');
      handleCloseDialog();
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <Card className={classes.card}>
      <Formik initialValues={customUpsells} enableReinitialize>
        {({ values, resetForm, isValid, errors, dirty }) => (
          <>
            <UniversalSave
              isValid={isValid}
              errors={errors}
              dirty={dirty}
              onSave={() => handleUpdateCustomUpsellGroupAvailability(values)}
              onDiscard={resetForm}
            />
            <Form>
              <CardContent>
                <Typography className={classes.customUpsellTitle}>Upsell</Typography>
              </CardContent>
              <Divider variant="fullWidth" style={{ background: '#F0F4F8' }} />
              {upsellGroups && upsellGroups.length <= 2 && (
                <Typography className={classes.customUpsellText}>0 Upsells created</Typography>
              )}
              {customUpsells?.map((upsellGroup, index) => (
                <div key={upsellGroup.upsellGroupId}>
                  <Box className={classes.customUpsellsBox}>
                    <div className={classes.customUpsellGroup}>
                      <Link
                        className={classes.upsellGroupName}
                        to={`/upsells/item-upsells/upsellGroup/${upsellGroup.upsellGroupId}`}
                        onClick={() => {
                          dispatch(clearUpsellGroupInitiatingItems());
                        }}
                      >
                        {upsellGroup.name}
                      </Link>
                      {upsellGroup.suggestedItemIds.map((itemId) => {
                        const menuItem = menuItems?.find((item) => item.itemId === itemId);
                        if (checkIfItemIsOnMenu(itemId)) {
                          if (isItemUnavailable(menuItem)) {
                            return (
                              <div
                                className={classes.upsellGroupSuggestedItems}
                                key={`${itemId}-${upsellGroup.upsellGroupId}`}
                              >
                                <Typography
                                  className={classes.upsellGroupSuggestedItemText}
                                  style={{
                                    textDecoration: 'line-through',
                                  }}
                                >
                                  {menuItem?.itemName}
                                </Typography>
                                <img
                                  className={classes.unavailableIcon}
                                  src="/img/visibility/unavailable.svg"
                                  alt="Unavailable"
                                  title="Item is unavailable"
                                />
                              </div>
                            );
                          }
                          return (
                            <div
                              className={classes.upsellGroupSuggestedItems}
                              key={`${itemId}-${upsellGroup.upsellGroupId}`}
                            >
                              <Typography className={classes.upsellGroupSuggestedItemText}>
                                {menuItem?.itemName}
                              </Typography>
                            </div>
                          );
                        }
                        return (
                          <div
                            className={classes.upsellGroupSuggestedItems}
                            key={`${itemId}-${upsellGroup.upsellGroupId}`}
                          >
                            <Typography
                              className={classes.upsellGroupSuggestedItemText}
                              style={{
                                textDecoration: 'line-through',
                              }}
                            >
                              {menuItem?.itemName}
                            </Typography>
                            <img
                              className={classes.unavailableIcon}
                              src="/img/visibility/not-on-menu.svg"
                              alt="Not on menu"
                              title="Item is not on an active menu"
                            />
                          </div>
                        );
                      })}
                    </div>

                    <div>
                      {values && values.length > 0 && (
                        <Field
                          name={`[${index}].enabled`}
                          component={Switch}
                          type="checkbox"
                          color="primary"
                          checked={values[index].enabled}
                        />
                      )}
                      <IconButton
                        aria-label="settings"
                        onClick={(event) => handleOpenMenu(event, index)}
                        className={classes.moreButton}
                        size="large"
                      >
                        <MdMoreHoriz />
                      </IconButton>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(selectedIndex === index && anchorEl)}
                        onClose={handleCloseMenu}
                      >
                        <MenuItem
                          onClick={() => {
                            handleCloseMenu();
                            setFormAction('rename');
                            handleOpenDialog(index);
                          }}
                        >
                          Rename
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleCloseMenu();
                            setFormAction('delete');
                            handleOpenDialog(index);
                          }}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                      <CustomUpsellOptions
                        formAction={formAction}
                        isDialogOpen={Boolean(selectedIndex === index && isDialogOpen)}
                        handleCloseDialog={handleCloseDialog}
                        name={upsellGroup.name}
                        upsellGroup={upsellGroup}
                        handleUpdateCustomUpsellGroup={handleUpdateCustomUpsellGroup}
                      />
                    </div>
                  </Box>
                  {index !== customUpsells.length - 1 && (
                    <Divider
                      variant="fullWidth"
                      style={{ background: '#F0F4F8', marginTop: '16px', padding: 0 }}
                    />
                  )}
                </div>
              ))}
            </Form>
          </>
        )}
      </Formik>
    </Card>
  );
};

export default CustomUpsellGroups;
