import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import CustomWarningBanner from '../../components/CustomWarningBanner';
import BackArrow from '../../components/BackArrow';
import WithVenue from '../../hoc/withVenue';
import Page from '../../components/Page';
import SmartUpsellBanner from './img/SmartUpsellBanner.png';
import useEndpoint from '../../hooks/useEndpoint';
import { getMenuItemsState } from '../../store/menuItems/selectors';
import { fetchMenuItems } from '../../store/menuItems';
import {
  getSmartUpsellGroupInitiatingItemsState,
  getUpsellGroupsState,
} from '../../store/upsellGroups/selectors';
import {
  clearUpsellGroup,
  clearUpsellGroups,
  fetchUpsellGroups,
  fetchSmartUpsellGroupInitiatingItems,
  updateSmartUpsellGroup,
  clearSmartUpsellGroupInitiatingItems,
} from '../../store/upsellGroups';
import CustomUpsellGroups from '../../components/CustomUpsellGroups';
import SmartUpsellGroups from '../../components/SmartUpsellGroups';
import { clearVenueMenuItems } from '../../store/venueMenuItems';
import AddGroupItems from '../../components/AddGroupItems';
import { getErrorMessage } from '../../shared/utils/errors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getVenuesSettingsState } from '../../store/venues/selectors';
import { fetchVenueSettings } from '../../store/venues';

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.customFonts.largeBold,
    marginBottom: '24px',
  },
  itemBackground: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.customPalette.greyLightest,
    flexGrow: 1,
    borderRadius: '4px',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '2.5rem',
  },
  createButton: {
    marginBottom: '1rem',
  },
  warningBanner: {
    marginBottom: '16px',
  },
  warningContent: {
    color: theme.customPalette.greyDarker,
    ...theme.customFonts.small,
  },
}));

const ItemUpsells = ({ redirect }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const { data: settingsUpsells } = useEndpoint(getVenuesSettingsState, fetchVenueSettings()) || {};
  const isItemUpsellEnabled = !!settingsUpsells?.find(
    (setting) => setting.settingName === 'UPSELLS' && setting.value.item,
  );

  const { loading: menuItemsLoading, data: menuItems } =
    useEndpoint(getMenuItemsState, fetchMenuItems()) || {};
  const {
    loading: upsellGroupLoading,
    data: upsellGroups,
    error: upsellGroupsError,
  } = useEndpoint(getUpsellGroupsState, fetchUpsellGroups()) || {};

  const { redispatch, data: initiatingItems } =
    useEndpoint(
      getSmartUpsellGroupInitiatingItemsState,
      fetchSmartUpsellGroupInitiatingItems({ upsellGroupId: '2' }),
    ) || {};

  const handleEdit = () => {
    setIsDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const initiatingItemIds = initiatingItems?.map((item) => item.itemId);

  const handleOnSave = async (values) => {
    const ids = values.filter((value) => value.checked === true).map((value) => value.itemId);
    try {
      await dispatch(
        updateSmartUpsellGroup({
          upsellGroupId: '2',
          data: {
            type: 'SMART_UPSELLS',
            initiatingItems: ids,
          },
        }),
      );
      handleCloseDialog();
      redispatch();
      showSuccessNotification('Successfully updated smart upsell group');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <>
      <PageHeader>
        <BackArrow redirect={redirect} text="Upsells" />
        <Typography className={classes.title}>Item upsells</Typography>
      </PageHeader>
      {settingsUpsells && !isItemUpsellEnabled && (
        <div className={classes.warningBanner}>
          <CustomWarningBanner
            backgroundColor="#FFF7E3"
            iconColor="#FFB800"
            title="Item upsells are disabled in your venue settings"
            titleFont="smallBold"
            titleColor="greyDarker"
          >
            <Typography className={classes.warningContent}>
              You can enable item upsells in your venue settings{' '}
              <Link to="/settings/venue/upsells">here</Link>.
            </Typography>
          </CustomWarningBanner>
        </div>
      )}
      <Page loading={upsellGroupLoading || menuItemsLoading} error={upsellGroupsError}>
        <SmartUpsellGroups SmartUpsellBanner={SmartUpsellBanner} handleEdit={handleEdit} />
        {menuItems && (
          <AddGroupItems
            menuItems={menuItems}
            isDialogOpen={isDialogOpen}
            handleCloseDialog={handleCloseDialog}
            checkedItems={initiatingItemIds}
            handleOnSave={handleOnSave}
          />
        )}
        <Button
          className={classes.createButton}
          variant="contained"
          color="primary"
          onClick={() => history.push('/upsells/item-upsells/create-upsell')}
        >
          Create upsell
        </Button>
        {upsellGroups?.length !== 0 && menuItems?.length !== 0 && (
          <CustomUpsellGroups upsellGroups={upsellGroups} menuItems={menuItems} />
        )}
      </Page>
    </>
  );
};

export default WithVenue(ItemUpsells, '/upsells', [
  clearUpsellGroup,
  clearUpsellGroups,
  clearVenueMenuItems,
  clearSmartUpsellGroupInitiatingItems,
]);
