import React from 'react';

import { makeStyles } from '@mui/styles';
import { Button, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Circle from './circle.svg';
import CircleTick from './circle-tick.svg';

const useStyles = makeStyles(() => ({
  subtitle: {
    fontFamily: 'Sen',
    fontWeight: 'bold',
    marginTop: '0',
  },
  description: {
    marginTop: '0',
  },
  task: {
    marginTop: '1rem',
  },
}));

const Task = ({ completed = false, title, description, buttonTitle, buttonLink, setItem }) => {
  const classes = useStyles();
  const isLinkaFunction = typeof buttonLink === 'function';

  const handleClick = () => {
    if (isLinkaFunction) {
      buttonLink();
    }
    setItem();
  };
  return (
    <>
      <Grid container className={classes.task}>
        <Grid item xs={1}>
          {completed ? (
            <img src={CircleTick} alt="Circle ticked icon" />
          ) : (
            <img src={Circle} alt="Circle icon" />
          )}
        </Grid>
        <Grid item xs={11}>
          <Typography className={classes.subtitle} variant="h4">
            {title}
          </Typography>
          {description && <p className={classes.description}>{description}</p>}
          {buttonTitle && isLinkaFunction && (
            <Button
              variant={completed ? 'outlined' : 'contained'}
              color="primary"
              onClick={handleClick}
            >
              {buttonTitle}
            </Button>
          )}
          {buttonTitle && !isLinkaFunction && (
            <Button
              variant={completed ? 'outlined' : 'contained'}
              color="primary"
              component={Link}
              to={buttonLink || ''}
              onClick={handleClick}
            >
              {buttonTitle}
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Task;
