import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';
import {
  IconButton,
  ListItemText,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Tooltip,
  ListItemButton,
  Box,
} from '@mui/material';

import { MdDelete } from 'react-icons/md';

import { useNotifications } from '../../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../../shared/utils/errors';

import { fetchBrandsMenus } from '../../../../store/brands/brandsMenus';
import { getBrandsMenuItem } from '../../../../store/brands/brandsMenuItems/selectors';
import {
  deleteBuilderMenuGroupItem,
  updateBuilderMenuItems,
} from '../../../../store/brands/builderMenu/builderMenuItems';

import useRoles from '../../../../hooks/useRoles';
import CustomDialog from '../../../CustomDialog';
import MenuItemDialog from '../../../../pages/MenuItems/MenuItemDialog';

const useStyles = makeStyles((theme) => ({
  menuItemContainer: {
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    backgroundColor: 'white',
    paddingLeft: '40px',
  },
}));

const formatPrice = (price) =>
  `£${(typeof price === 'string' ? parseInt(price, 10) : price).toFixed(2)}`;

const MenuGroupItem = ({ groupId, itemId, itemIndex, isReadonly }) => {
  const classes = useStyles();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();
  const { isRoleAtLeastManager } = useRoles();
  const menuItem = useSelector((state) => getBrandsMenuItem(state, itemId));
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isMenuItemDialogOpen, setIsMenuItemDialogOpen] = useState(false);

  if (!menuItem) return null;
  const { itemName, itemPrices, hidden, readonly, brandName, label } = menuItem;

  const handleDelete = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    setIsDialogOpen(false);
    try {
      dispatch(deleteBuilderMenuGroupItem({ groupId, itemIndex }));
      await dispatch(updateBuilderMenuItems());
      await dispatch(fetchBrandsMenus());
      showSuccessNotification('Menu Item has been removed');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <>
      <Box className={classes.menuItemContainer}>
        <ListItemButton className={classes.button} onClick={() => setIsMenuItemDialogOpen(true)}>
          <ListItemText
            primary={
              <>
                <span style={{ marginRight: '0.5rem' }}>
                  {itemName} | {brandName}
                </span>
              </>
            }
            secondary={
              <>
                {label && (
                  <>
                    <Typography variant="caption">{label}</Typography> <br />
                  </>
                )}
                <Typography variant="button">
                  {!hidden &&
                    itemPrices.length &&
                    itemPrices.map((price) => formatPrice(price)).join(', ')}
                </Typography>
              </>
            }
          />
        </ListItemButton>
        <Box>
          <Tooltip
            title={`Remove ${itemName} - ${brandName} from menu`}
            aria-label={`Remove ${itemName} - ${brandName} from menu`}
          >
            {!readonly && isRoleAtLeastManager() ? (
              <IconButton edge="end" onClick={handleDelete} disabled={isReadonly} size="large">
                <MdDelete style={{ fontSize: 20 }} />
              </IconButton>
            ) : (
              <></>
            )}
          </Tooltip>
        </Box>
      </Box>
      <CustomDialog
        title="Are you sure?"
        isDialogOpen={isDialogOpen}
        handleCloseDialog={handleCloseDialog}
      >
        <DialogContent dividers>
          <Typography gutterBottom>
            Are you sure you want to remove {itemName} - {brandName} from the menu?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" autoFocus onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleConfirmDelete} color="primary">
            Remove
          </Button>
        </DialogActions>
      </CustomDialog>
      <MenuItemDialog
        handleOpen={() => setIsMenuItemDialogOpen(!isMenuItemDialogOpen)}
        isOpen={isMenuItemDialogOpen}
        itemId={itemId}
        isBrandsBuilder
      />
    </>
  );
};

MenuGroupItem.propTypes = {
  groupId: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  itemIndex: PropTypes.number.isRequired,
};

export default MenuGroupItem;
