import { get, patch } from './httpRequest';

export const fetchIntroTasks = async () => {
  const response = await get('/introTasks');
  return response.data;
};

export const updateIntroTask = async (id) => {
  const response = await patch(`/introTasks/${id}`);
  return response.data;
};
