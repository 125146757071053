import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSession } from './store/session';
import AuthenticatedApp from './routes/AuthenticatedApp';
import NotAuthenticatedApp from './routes/NotAuthenticatedApp';
import PageError from './components/PageError';
import PageErrorHandler from './components/PageErrorHandler';
// import useLoadPlacesScript from './shared/utils/useLoadPlacesScript';
import LogoUpCo from './components/LogoUpCo';
import yupMethods from './config/yupMethods';

const Root = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.app.session);
  const { isAuthenticated } = useSelector((state) => state.app.authentication);
  // const { isLoaded } = useLoadPlacesScript();

  useEffect(() => {
    yupMethods();
    dispatch(fetchSession());
  }, [dispatch]);

  if (loading) {
    return <LogoUpCo />;
  }

  if (error) {
    return <PageError error={error} />;
  }

  return (
    <PageErrorHandler>
      {!isAuthenticated && <NotAuthenticatedApp />}
      {isAuthenticated && <AuthenticatedApp />}
    </PageErrorHandler>
  );
};

export default Root;
