import React from 'react';
import PropTypes from 'prop-types';

import { Card, CardActions, CardContent, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CustomDialog from '../CustomDialog';

const useStyles = makeStyles(() => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const ResetPasswordDialog = ({
  handleCloseDialog,
  handleDialogOpen,
  submitDialog,
  email,
  temporaryPassword,
}) => {
  const classes = useStyles();

  return (
    <CustomDialog
      title="Are you sure?"
      isDialogOpen={handleDialogOpen}
      handleCloseDialog={handleCloseDialog}
    >
      <Card className={classes.card}>
        <CardContent>
          Email: {email}
          <br />
          Temporary Password: {temporaryPassword}
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={() => submitDialog(email, temporaryPassword)}
            variant="contained"
            color="primary"
          >
            Force Password Reset
          </Button>
        </CardActions>
      </Card>
    </CustomDialog>
  );
};

ResetPasswordDialog.propTypes = {
  handleCloseDialog: PropTypes.func.isRequired,
  handleDialogOpen: PropTypes.bool.isRequired,
  submitDialog: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  temporaryPassword: PropTypes.string.isRequired,
};

export default ResetPasswordDialog;
