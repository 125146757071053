import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { getVenueFeesState } from '../store/venueFees/selectors';
import { getBrandsState } from '../store/brands/brands/selectors';
import { convertPenceToPounds } from '../shared/utils/moneyConversion';

export const feeEnums = {
  AMEXINTERNATIONALFIXED: 10,
  AMEXINTERNATIONALPERCENTFEE: 1.7,
  CARDFIXED: 10,
  CARDPERCENTFEE: 1.7,
  CASHANDEXTERNALFIXED: 10,
  CASHANDEXTERNALPERCENTFEE: 1.7,
  INHOUSEBRANDDEFAULTFEEDRINK: 6,
  INHOUSEBRANDDEFAULTFEEFOOD: 8,
  INHOUSEVENUEFEEACTIVE: false,
  INHOUSEVENUEFEEDRINK: 6,
  INHOUSEVENUEFEEFOOD: 8,
  DELIVERYFEESDEFAULTFEEDELIVEROO: 36,
  DELIVERYFEESDEFAULTFEEUBEREATS: 17,
  DELIVERYFEESDEFAULTFEEJUSTEAT: 36,
  DELIVERYPICKUPFEESDEFAULTFEEDELIVEROO: 36,
  DELIVERYPICKUPFEESDEFAULTFEEUBEREATS: 17,
  DELIVERYPICKUPFEESDEFAULTFEEJUSTEAT: 36,
  FRANCHISE_MARKETING_ITEM_FEE: 0,
  FRANCHISE_MARKETING_ALL_PRODUCTS: false,
};

const useVenueFees = () => {
  const venueFeesState = useSelector(getVenueFeesState);
  const { loading: venueFeesLoading, data: venueFeesData, error: venueFeesError } = venueFeesState;
  const brandsState = useSelector(getBrandsState);
  const { loading: brandsLoading, data: brands, error: brandsError } = brandsState;

  const {
    AMEXINTERNATIONALFIXED,
    AMEXINTERNATIONALPERCENTFEE,
    CARDFIXED,
    CARDPERCENTFEE,
    CASHANDEXTERNALFIXED,
    CASHANDEXTERNALPERCENTFEE,
    INHOUSEBRANDDEFAULTFEEDRINK,
    INHOUSEBRANDDEFAULTFEEFOOD,
    INHOUSEVENUEFEEACTIVE,
    INHOUSEVENUEFEEDRINK,
    INHOUSEVENUEFEEFOOD,
    DELIVERYFEESDEFAULTFEEDELIVEROO,
    DELIVERYFEESDEFAULTFEEUBEREATS,
    DELIVERYFEESDEFAULTFEEJUSTEAT,
    DELIVERYPICKUPFEESDEFAULTFEEDELIVEROO,
    DELIVERYPICKUPFEESDEFAULTFEEUBEREATS,
    DELIVERYPICKUPFEESDEFAULTFEEJUSTEAT,
    FRANCHISE_MARKETING_ITEM_FEE,
    FRANCHISE_MARKETING_ALL_PRODUCTS,
  } = feeEnums;

  const {
    transactionsFees = {},
    inhouseSessionsVenuesFees = {},
    inhouseSessionsBrandsFees = {},
    deliveryFees = {},
    deliveryPickupFees = {},
    franchiseMarketingFee = {},
  } = venueFeesData || {};

  //   transactionsFees
  const {
    amexInternational: {
      fixed: amexInternationalFixed = AMEXINTERNATIONALFIXED,
      percentFee: amexInternationalPercentFee = AMEXINTERNATIONALPERCENTFEE,
    } = {},
    card: { fixed: cardFixed = CARDFIXED, percentFee: cardPercentFee = CARDPERCENTFEE } = {},
    cashAndExternal: {
      fixed: cashAndExternalFixed = CASHANDEXTERNALFIXED,
      percentFee: cashAndExternalPercentFee = CASHANDEXTERNALPERCENTFEE,
    } = {},
  } = transactionsFees;

  //   inHouseSessionsVenuesFees
  const {
    active: inhouseVenueFeeActive = INHOUSEVENUEFEEACTIVE,
    drink: inhouseVenueFeeDrink = INHOUSEVENUEFEEDRINK,
    food: inhouseVenueFeeFood = INHOUSEVENUEFEEFOOD,
  } = inhouseSessionsVenuesFees;

  // inhouseSessionsBrandsFees
  const {
    defaultFee: {
      drink: inhouseBrandDefaultFeeDrink = INHOUSEBRANDDEFAULTFEEDRINK,
      food: inhouseBrandDefaultFeeFood = INHOUSEBRANDDEFAULTFEEFOOD,
    } = {},
  } = inhouseSessionsBrandsFees;

  // deliveryFees
  const {
    defaultFee: {
      deliveroo: deliveryFeesDefaultFeeDeliveroo = DELIVERYFEESDEFAULTFEEDELIVEROO,
      ubereats: deliveryFeesDefaultFeeUberEats = DELIVERYFEESDEFAULTFEEUBEREATS,
      justeat: deliveryFeesDefaultFeeJustEat = DELIVERYFEESDEFAULTFEEJUSTEAT,
    } = {},
  } = deliveryFees;

  // deliveryPickupFees
  const {
    defaultFee: {
      deliveroo: deliveryPickupFeesDefaultFeeDeliveroo = DELIVERYPICKUPFEESDEFAULTFEEDELIVEROO,
      ubereats: deliveryPickupFeesDefaultFeeUberEats = DELIVERYPICKUPFEESDEFAULTFEEUBEREATS,
      justeat: deliveryPickupFeesDefaultFeeJustEat = DELIVERYPICKUPFEESDEFAULTFEEJUSTEAT,
    } = {},
  } = deliveryPickupFees;

  const {
    defaultFee: franchiseMarketingItemFee = FRANCHISE_MARKETING_ITEM_FEE,
    allProducts: franchiseMarketingAllProducts = FRANCHISE_MARKETING_ALL_PRODUCTS,
  } = franchiseMarketingFee;

  const initialFees =
    brands &&
    brands.map((brand) => {
      const { prefix } = brand;

      const { drink = INHOUSEBRANDDEFAULTFEEDRINK, food = INHOUSEBRANDDEFAULTFEEFOOD } =
        inhouseSessionsBrandsFees[prefix] || {};

      const {
        deliveroo: deliveryFeesDeliveroo = DELIVERYFEESDEFAULTFEEDELIVEROO,
        ubereats: deliveryFeesUberEats = DELIVERYFEESDEFAULTFEEUBEREATS,
        justeat: deliveryFeesJustEat = DELIVERYFEESDEFAULTFEEJUSTEAT,
      } = deliveryFees[prefix] || {};

      const {
        deliveroo: deliveryPickupFeesDeliveroo = DELIVERYPICKUPFEESDEFAULTFEEDELIVEROO,
        ubereats: deliveryPickupFeesUberEats = DELIVERYPICKUPFEESDEFAULTFEEUBEREATS,
        justeat: deliveryPickupFeesJustEat = DELIVERYPICKUPFEESDEFAULTFEEJUSTEAT,
      } = deliveryPickupFees[prefix] || {};

      return {
        // transactionFees
        transactionsCardPercent: cardPercentFee,
        transactionsCardFixed: convertPenceToPounds(cardFixed),
        transactionsAmexInternationalPercent: amexInternationalPercentFee,
        transactionsAmexInternationalFixed: convertPenceToPounds(amexInternationalFixed),
        transactionsCashAndExternalPercent: cashAndExternalPercentFee,
        transactionsCashAndExternalFixed: convertPenceToPounds(cashAndExternalFixed),

        // inHouseSessionsVenuesFees
        inhouseVenueFeeActive,
        inhouseVenueFeeFood,
        inhouseVenueFeeDrink,

        // inhouseSessionsBrandsFees
        inhouseBrandDefaultFood: inhouseBrandDefaultFeeFood,
        inhouseBrandDefaultDrink: inhouseBrandDefaultFeeDrink,
        [`inhouseBrand${prefix}Drink`]: drink,
        [`inhouseBrand${prefix}Food`]: food,

        // deliveryFees
        deliveryFeesDefaultDeliveroo: deliveryFeesDefaultFeeDeliveroo,
        deliveryFeesDefaultJustEat: deliveryFeesDefaultFeeJustEat,
        deliveryFeesDefaultUberEats: deliveryFeesDefaultFeeUberEats,
        [`deliveryFees${prefix}Deliveroo`]: deliveryFeesDeliveroo,
        [`deliveryFees${prefix}UberEats`]: deliveryFeesUberEats,
        [`deliveryFees${prefix}JustEat`]: deliveryFeesJustEat,

        // deliveryPickupFees
        deliveryPickupFeesDefaultDeliveroo: deliveryPickupFeesDefaultFeeDeliveroo,
        deliveryPickupFeesDefaultJustEat: deliveryPickupFeesDefaultFeeJustEat,
        deliveryPickupFeesDefaultUberEats: deliveryPickupFeesDefaultFeeUberEats,
        [`deliveryPickupFees${prefix}Deliveroo`]: deliveryPickupFeesDeliveroo,
        [`deliveryPickupFees${prefix}UberEats`]: deliveryPickupFeesUberEats,
        [`deliveryPickupFees${prefix}JustEat`]: deliveryPickupFeesJustEat,

        // franchiseMarketingFee
        franchiseMarketingItemFee,
        franchiseMarketingAllProducts,
      };
    });

  const schemaFees =
    brands &&
    brands.map((brand) => {
      const { prefix } = brand;
      return {
        // transactionFees
        transactionsCardPercent: Yup.number()
          .min(0)
          .max(100, 'Fees must be a percentage')
          .required('Please enter a value'),
        transactionsCardFixed: Yup.number().min(0).required('Please enter a value'),
        transactionsAmexInternationalPercent: Yup.number()
          .min(0)
          .max(100, 'Fees must be a percentage')
          .required('Please enter a value'),
        transactionsAmexInternationalFixed: Yup.number().min(0).required('Please enter a value'),
        transactionsCashAndExternalPercent: Yup.number()
          .min(0)
          .max(100, 'Fees must be a percentage')
          .required('Please enter a value'),
        transactionsCashAndExternalFixed: Yup.number().min(0).required('Please enter a value'),

        // inHouseSessionsVenuesFees
        inhouseVenueFeeActive: Yup.boolean().required(),
        inhouseVenueFeeFood: Yup.number()
          .min(0)
          .max(100, 'Fees must be a percentage')
          .required('Please enter a value'),
        inhouseVenueFeeDrink: Yup.number()
          .min(0)
          .max(100, 'Fees must be a percentage')
          .required('Please enter a value'),

        // inhouseSessionsBrandsFees
        inhouseBrandDefaultFood: Yup.number()
          .min(0)
          .max(100, 'Fees must be a percentage')
          .required('Please enter a value'),
        inhouseBrandDefaultDrink: Yup.number()
          .min(0)
          .max(100, 'Fees must be a percentage')
          .required('Please enter a value'),
        [`inhouseBrand${prefix}Drink`]: Yup.number()
          .min(0)
          .max(100, 'Fees must be a percentage')
          .required('Please enter a value'),
        [`inhouseBrand${prefix}Food`]: Yup.number()
          .min(0)
          .max(100, 'Fees must be a percentage')
          .required('Please enter a value'),

        // deliveryFees
        deliveryFeesDefaultDeliveroo: Yup.number()
          .min(0)
          .max(100, 'Fees must be a percentage')
          .required('Please enter a value'),
        deliveryFeesDefaultJustEat: Yup.number()
          .min(0)
          .max(100, 'Fees must be a percentage')
          .required('Please enter a value'),
        deliveryFeesDefaultUberEats: Yup.number()
          .min(0)
          .max(100, 'Fees must be a percentage')
          .required('Please enter a value'),
        [`deliveryFees${prefix}Deliveroo`]: Yup.number()
          .min(0)
          .max(100, 'Fees must be a percentage')
          .required('Please enter a value'),
        [`deliveryFees${prefix}JustEat`]: Yup.number()
          .min(0)
          .max(100, 'Fees must be a percentage')
          .required('Please enter a value'),
        [`deliveryFees${prefix}UberEats`]: Yup.number()
          .min(0)
          .max(100, 'Fees must be a percentage')
          .required('Please enter a value'),

        // deliveryPickupFees
        deliveryPickupFeesDefaultDeliveroo: Yup.number()
          .min(0)
          .max(100, 'Fees must be a percentage')
          .required('Please enter a value'),
        deliveryPickupFeesDefaultJustEat: Yup.number()
          .min(0)
          .max(100, 'Fees must be a percentage')
          .required('Please enter a value'),
        deliveryPickupFeesDefaultUberEats: Yup.number()
          .min(0)
          .max(100, 'Fees must be a percentage')
          .required('Please enter a value'),
        [`deliveryPickupFees${prefix}Deliveroo`]: Yup.number()
          .min(0)
          .max(100, 'Fees must be a percentage')
          .required('Please enter a value'),
        [`deliveryPickupFees${prefix}JustEat`]: Yup.number()
          .min(0)
          .max(100, 'Fees must be a percentage')
          .required('Please enter a value'),
        [`deliveryPickupFees${prefix}UberEats`]: Yup.number()
          .min(0)
          .max(100, 'Fees must be a percentage')
          .required('Please enter a value'),

        // franchiseMarketingFee
        franchiseMarketingItemFee: Yup.number()
          .min(0)
          .max(100, 'Fees must be a percentage')
          .required('Please enter a value'),
        franchiseMarketingAllProducts: Yup.boolean().required(),
      };
    });

  return {
    brandsLoading,
    brandsError,
    venueFeesState,
    venueFeesLoading,
    venueFeesError,
    brandsState,
    initialFees,
    schemaFees,
  };
};

export default useVenueFees;
