import React from 'react';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { Box, Button } from '@mui/material';
import { TextField } from 'formik-mui';
import { fetchVenue, updateVenue } from '../../store/venues';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';

const ChangeCustomerIdForm = () => {
  const dispatch = useDispatch();
  const { showErrorNotification, showSuccessNotification } = useNotifications();

  const customerIdSchema = Yup.object().shape({
    // validate stripeCustomerId as a string that matches 'cust_' followed by 16 characters
    stripeCustomerId: Yup.string().trim().required().matches(/^cus_/, 'must start with "cus_"'),
  });

  const handleOnSubmit = async (values, formikBag) => {
    const { stripeCustomerId } = values;
    try {
      await dispatch(updateVenue({ stripeCustomerId }));
      if (formikBag) {
        dispatch(fetchVenue());
        showSuccessNotification('Stripe customer ID has been edited successfully');
      }
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
  };

  return (
    <>
      <Formik
        initialValues={{ stripeCustomerId: '' }}
        validationSchema={customerIdSchema}
        onSubmit={handleOnSubmit}
      >
        {({ errors, isValid }) => (
          <Form>
            <Box display="flex" justifyContent="flex-end" alignItems="flex-start">
              <Box mr={2} m={-2}>
                <Field
                  component={TextField}
                  helperText={errors.stripeCustomerId}
                  name="stripeCustomerId"
                  size="small"
                  label="Stripe customer ID"
                  variant="outlined"
                  placeholder="cust_XXXXXXXXXXXXXX"
                  required
                  margin="normal"
                />
              </Box>
              <Box>
                <Button disabled={!isValid} variant="contained" color="primary" type="submit">
                  Assign stripe customer ID
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ChangeCustomerIdForm;
