import { createSlice } from '@reduxjs/toolkit';

import {
  createAsyncAction,
  asyncInitialState as initialState,
  createAsyncReducerHandlers,
} from '../../utils/async';
import {
  fetchBrandsVariationStatusApi,
  pushBrandsVenuesAssociationsApi,
  publishBrandsVenuesAssociationsMenuApi,
  removeBrandsVenuesAssociationApi,
} from '../../../api/brands/brandsVenueAssociations';

export const fetchBrandsVenuesAssociations = createAsyncAction(
  'fetch-brands-variation-status',
  fetchBrandsVariationStatusApi,
);

export const pushBrandsVenuesAssociations = createAsyncAction(
  'push-brands-variation-status',
  pushBrandsVenuesAssociationsApi,
);

export const publishBrandsVenuesAssociationsMenu = createAsyncAction(
  'publish-brands-variations-menu',
  publishBrandsVenuesAssociationsMenuApi,
);

export const removeBrandsVenuesAssociation = createAsyncAction(
  'remove-brands-venues-association',
  removeBrandsVenuesAssociationApi,
);

const brandsVenueAssociationsSlice = createSlice({
  name: 'brands-venue-associations',
  initialState,
  reducers: {
    clearBrandsVenuesAssociations(state) {
      delete state.data;
      delete state.error;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchBrandsVenuesAssociations),
    ...createAsyncReducerHandlers(pushBrandsVenuesAssociations),
    ...createAsyncReducerHandlers(publishBrandsVenuesAssociationsMenu),
    ...createAsyncReducerHandlers(removeBrandsVenuesAssociation),
  },
});

export const { clearBrandsVenuesAssociations } = brandsVenueAssociationsSlice.actions;
export const { reducer: brandsVenueAssociations } = brandsVenueAssociationsSlice;
