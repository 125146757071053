import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import UpgradeImageServedup from './welcome-servedup.png';
import UpgradeImageBrandup from './welcome-brandup.png';
import CustomDialog from '../../components/CustomDialog';

const useStyles = makeStyles(() => ({
  dialog: {
    padding: '2rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  button: {
    marginTop: '1rem',
  },
  img: {
    width: '100%',
  },
}));

const WelcomeDialog = ({ onClose, open, isBrandup }) => {
  const classes = useStyles();
  return (
    <CustomDialog isDialogOpen={open} handleCloseDialog={onClose}>
      <div className={classes.dialog}>
        <img
          src={isBrandup ? UpgradeImageBrandup : UpgradeImageServedup}
          alt="Locked"
          className={classes.img}
        />
        <h2>Welcome to Sessions Serve</h2>
        {isBrandup ? (
          <p>
            BrandUp connects you to the worlds best brands, allowing you to earn additional revenue
            through weekly rewards and offers.
          </p>
        ) : (
          <p>Manage all your devices &amp; grow your business with insights and offers</p>
        )}
        <div>
          <Button variant="contained" color="primary" onClick={onClose} className={classes.button}>
            Get started
          </Button>
        </div>
      </div>
    </CustomDialog>
  );
};
export default WelcomeDialog;
