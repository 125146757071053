import React from 'react';
import { Button } from '@mui/material';
import { Form, Formik } from 'formik';
import withSettings from '../../hoc/withSettings';

const ToggleMiscItems = ({ setting, handleSubmitMiscItemsToggle }) => {
  const initialValues = setting;

  return (
    <>
      <Formik initialValues={initialValues}>
        {({ values }) => (
          <Form>
            <Button
              variant="contained"
              color="primary"
              component="label"
              onClick={() => handleSubmitMiscItemsToggle(values)}
            >
              Toggle {setting.value === true ? 'Off' : 'On'}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default withSettings(ToggleMiscItems, 'ADHOC_ITEMS_ENABLED');
