import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';

import {
  fetchBilling as fetchBillingApi,
  fetchDirectDebitMandateUrl as fetchDirectDebitMandateUrlApi,
  fetchInvoices as fetchInvoicesApi,
  updateSubscription as updateSubscriptionApi,
} from '../../api/billing';

export const fetchBilling = createAsyncAction('fetch-billing', fetchBillingApi);
export const fetchDirectDebitMandateUrl = createAsyncAction(
  'fetch-direct-debit-mandate-url',
  fetchDirectDebitMandateUrlApi,
);
export const fetchInvoices = createAsyncAction('fetch-invoices', fetchInvoicesApi);
export const updateSubscription = createAsyncAction('update-subscription', updateSubscriptionApi);

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    clearBilling(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchBilling),
    ...createAsyncReducerHandlers(updateSubscription),
  },
});

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    clearInvoice(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchInvoices),
  },
});

const directDebitMandateSlice = createSlice({
  name: 'direct-debit-mandate',
  initialState,
  reducers: {
    clearDirectDebitMandate(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchDirectDebitMandateUrl),
  },
});

export const { clearBilling } = billingSlice.actions;
export const { clearInvoice } = invoiceSlice.actions;
export const { clearDirectDebitMandate } = directDebitMandateSlice.actions;

export const { reducer: billing } = billingSlice;
export const { reducer: invoice } = invoiceSlice;
export const { reducer: directDebitMandate } = directDebitMandateSlice;
