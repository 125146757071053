export const filterTableData = (brandsFilter, data) => {
  if (!data) return null;
  if (!brandsFilter || brandsFilter.length === 0) {
    return data;
  }
  const brandsToDisplay = brandsFilter.map((brand) => brand.brandId);
  const filtered = data.filter((item) => brandsToDisplay.includes(item.brandId));
  return filtered;
};

/**
/**
 * Filters data based on brand and item filters.
 * @param {Array} itemsFilter - Array of item filter objects containing item properties.
 * @param {Array} data - Array of data objects to be filtered.
 * @returns {Array|null} Filtered data based on the brand and item filters, or the original data 
 * if no filters are applied.
 */
export const filterDataItems = (itemsFilter, dataState) => {
  if (!dataState) return null;
  if (!itemsFilter || itemsFilter.length === 0) {
    return dataState;
  }
  // Object mapping itemsFilter keys to the corresponding keys in dataState objects
  const filtersConfig = {
    types: 'type',
    categories: 'category',
    available: 'available',
    itemPrices: 'itemPrices',
  };

  const filters = itemsFilter[0];

  const filtered = dataState.filter((dataItem) => {
    const minPrice = dataItem.itemPrices && Math.min(...dataItem.itemPrices);
    const inPriceRange = filters.itemPrices >= minPrice;

    if (dataItem.itemPrices && !inPriceRange) {
      return false;
    }

    return Object.keys(filters).every((filterKey) => {
      if (filterKey === 'itemPrices') {
        return true;
      }

      if (filterKey === 'available') {
        const availableFilters = filters[filterKey].map((value) => value.toLowerCase() === 'true');
        return availableFilters.includes(dataItem[filtersConfig[filterKey]]);
      }
      return filters[filterKey].includes(dataItem[filtersConfig[filterKey]]);
    });
  });

  return filtered;
};

/**
 * Filters initialData based on the searchTargetValue using Fuse.js.
 * @param {string} searchTargetValue - The search term to filter the data.
 * @param {Array} initialData - Array of data objects to be filtered.
 * @param {Object} Fuse - The Fuse.js instance to perform the search.
 * @param {Array} searchKeys - Array of keys to be searched within each data object.
 * @param {number} threshold - The threshold value for the Fuse.js search algorithm.
 * @returns {Array} Filtered data based on the search term or the original data if no search term is provided.
 */
export const filterSearchTerm = (searchTargetValue, initialData, Fuse, searchKeys, threshold) => {
  if (searchTargetValue && initialData) {
    const fuse = new Fuse(initialData, {
      threshold,
      keys: searchKeys,
    });
    const result = fuse.search(searchTargetValue);
    const filtered = result.map((item) => item.item);

    return filtered;
  }

  return initialData;
};
