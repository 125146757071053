import React, { useState } from 'react';
import { TableCell, TableRow, IconButton } from '@mui/material';
import { Delete, DirectionsBike, ShoppingBasket, Storefront } from '@mui/icons-material';

import VenueActions from './VenueActions';
import TableLink from '../../../../TableLink';
import DeleteAssociation from './DeleteAssociation';
import { getOtterPublishStatus, getOtterPushStatus, getVariationAssociationStatus } from './Status';

const VenueTableRow = ({
  venue,
  handleVenueAssociation,
  handleRemoveAssociation,
  brandId,
  handleVenueMenuLink,
}) => {
  const [showDeleteAssociationDialog, setShowDeleteAssociationDialog] = useState(false);
  const {
    venueId,
    venueName,
    isPickupEnabled,
    isDeliveryEnabled,
    kioskEnabled,
    variation,
    isVersionUpToDate,
    otterPublishData,
    otterPublishStatus,
    otterPushStatus,
    otterStoreId,
    menuName,
    deliveryPlatforms,
    isLatestVersionPushed,
    otterPushedAt,
    variationId,
  } = venue;

  const venueAssociationValues = {
    venueId,
    otterStoreId,
    otterPickupEnabled: isPickupEnabled,
    otterDeliveryEnabled: isDeliveryEnabled,
    kioskEnabled,
  };

  return (
    <>
      <TableRow key={venueId}>
        <TableCell align="left">
          <TableLink underline="always" onClick={() => handleVenueMenuLink(venueId, menuName)}>
            {venueName}
          </TableLink>
        </TableCell>
        <TableCell align="right">
          {isPickupEnabled && <ShoppingBasket />}
          {isDeliveryEnabled && <DirectionsBike />}
          {kioskEnabled && <Storefront />}
        </TableCell>
        <TableCell align="right">
          {variation} {getVariationAssociationStatus({ isVersionUpToDate })}
        </TableCell>
        <TableCell align="right">
          {getOtterPushStatus({ otterPushedAt, otterPushStatus, isLatestVersionPushed })}
        </TableCell>
        <TableCell align="right">
          {getOtterPublishStatus(otterPublishData, otterPublishStatus, 'Deliveroo')}
        </TableCell>
        <TableCell align="right">
          {getOtterPublishStatus(otterPublishData, otterPublishStatus, 'Uber Eats')}
        </TableCell>
        <TableCell align="right">
          {getOtterPublishStatus(otterPublishData, otterPublishStatus, 'Just Eat')}
        </TableCell>
        <TableCell align="right">
          <VenueActions
            venueAssociationValues={venueAssociationValues}
            menuName={menuName}
            isVersionUpToDate={isVersionUpToDate}
            handleVenueAssociation={handleVenueAssociation}
            deliveryPlatforms={deliveryPlatforms}
            brandId={brandId}
          />
          <IconButton
            size="small"
            style={{ marginLeft: '10px' }}
            onClick={() => setShowDeleteAssociationDialog(true)}
          >
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
      <DeleteAssociation
        venueId={venueId}
        variationId={variationId}
        venueName={venueName}
        showDialog={showDeleteAssociationDialog}
        handleCloseDialog={() => setShowDeleteAssociationDialog(false)}
        handleRemoveAssociation={handleRemoveAssociation}
      />
    </>
  );
};

export default VenueTableRow;
